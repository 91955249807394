import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { CircleDecoration, CustomerExperienceContent, Heading, IconBlock, List, PicBlock, Text } from '../../components';
import { Black, Hero, White } from '../../sections';
import { heroData, serviceLine, offeringDescription, upliftingExperience, visitorExperience } from '../../utils/customerExperience';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const CustomerExperience = () => {
  const customerExpRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Service line section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="service-line__section"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="service-line__section"] .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="service-line__section"] .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from('[class*="service-line__section"] [class*="customer-exp__icons"]', {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 0.7);
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Explore stories section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['customer-exp__offering-desc']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['offering-desc__pic']} `, {
          x: '20vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['customer-exp__offering-desc']} .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['customer-exp__offering-desc']} .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6);
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.offering-uplifting-visitor__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Uplifting section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.customer-exp__uplifting`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.customer-exp__uplifting .${styles['customer-exp__border']} `, {
          width: 0,
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.customer-exp__uplifting .uplifting__heading`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.customer-exp__uplifting .uplifting__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6);
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Uplifting section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['customer-exp__visitor']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['customer-exp__visitor']} .${styles['customer-exp__border']} `, {
          width: 0,
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['customer-exp__visitor']} .visitor__heading`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['customer-exp__visitor']} .visitor__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6);
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Content block
        document.querySelectorAll(`[class*="customer-exp__content-block"]`).forEach((item, index) => {
          let stl2 = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top+=15% bottom',
              end: 'bottom top',
            }
          });
    
          stl2.from(item.querySelector(`[class*="customer-experience-content__middle"]`), {
            y: '-10vh',
            opacity: 0,
            ease: 'power2',
            duration: 2
          }, 0)
          .from(item.querySelector(`[class*="customer-experience-content__left"]`), {
            x: '-10vh',
            opacity: 0,
            ease: 'power2',
            duration: 2
          }, 0.5)
          .from(item.querySelector(`[class*="customer-experience-content__right"]`), {
            x: '10vh',
            opacity: 0,
            ease: 'power2',
            duration: 2
          }, 0.5);
        });
      })
    }, customerExpRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className="overflow-hidden" ref={customerExpRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
          padding="bottom-md"
        >
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text 
              maxWidth="70"
              size="25-35"
            >
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      {serviceLine && (
        <div className="service-line__section">
          <White>
            {serviceLine?.title && (
              <Heading
                color="black"
                decoration={{
                  color: "purple-100"
                }}
              >
                {serviceLine.title}
              </Heading>
            )}
            {serviceLine?.intro && (
              <Text 
                color="black"
                size="25-30"
                maxWidth="75"
              >
                {serviceLine.intro}
              </Text>
            )}
            {serviceLine?.items && (
              <div className={styles['customer-exp__icons']}>
                {serviceLine.items.map(({ icon, title, text }, index) => (
                  <IconBlock
                    iconSize="md2"
                    icon={icon}
                    gap="60"
                    key={index}
                  >
                    {title && (
                      <Heading
                        Tag="h3"
                        color="purple-100"
                        spacing="10"
                      >
                        {title}
                      </Heading>
                    )}
                    {text && (
                      <Text color="black">
                        {text}
                      </Text>
                    )}
                  </IconBlock>
                ))}
              </div>
            )}
          </White>
        </div>
      )}

      {(offeringDescription && upliftingExperience && visitorExperience) && (
        <div className="offering-uplifting-visitor__section">
          <Black noLayout>
            {offeringDescription && (
              <div className={`${styles['customer-exp__offering-desc']} ${styles['customer-exp__black']}`}>
                {offeringDescription?.pic && (
                  <div className={styles['offering-desc__pic']}>
                    <PicBlock
                      image={offeringDescription.pic}
                      gradient="purple"
                      type="romb-right-lg"
                    />
                  </div>
                )}
                <div className="layout">
                  <div className={styles['offering-desc__content']}>
                    {offeringDescription?.title && (
                      <Heading decoration>
                        {offeringDescription.title}
                      </Heading>
                    )}
                    {offeringDescription?.intro && (
                      <Text 
                        size="25-30"
                        maxWidth="70"
                      >
                        {offeringDescription.intro}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            )}

            {upliftingExperience && (
              <div className={`customer-exp__uplifting ${styles['customer-exp__black']}`}>
                <div className="layout">
                  <i className={styles['customer-exp__border']}></i>
                  {upliftingExperience?.title && (
                    <div className="uplifting__heading">
                      <Heading 
                        align="center"
                        decoration
                      >
                        {upliftingExperience.title}
                      </Heading>
                    </div>
                  )}
                  {upliftingExperience?.intro && (
                    <div className="uplifting__text">
                      <Text
                        align="center"
                        size="25-30"
                        maxWidth="65"
                      >
                        {upliftingExperience.intro}
                      </Text>
                    </div>
                  )}
                </div>
                {upliftingExperience?.left && upliftingExperience?.middle && upliftingExperience?.right && (
                  <div className={styles['customer-exp__content-block']}>
                    <CustomerExperienceContent
                      left={
                        <>
                          {upliftingExperience.left?.title && (
                            <Heading Tag="h3">
                              {upliftingExperience.left.title}
                            </Heading>
                          )}
                          {upliftingExperience.left?.list && (
                            <List 
                              fontSize="20-25"
                              items={upliftingExperience.left.list} 
                            />
                          )}
                          {upliftingExperience.left?.iconsList && (
                            <div className={styles['customer-exp__content-icons']}>
                              {upliftingExperience.left.iconsList.map(({ icon, iconSize, title }, index) => (
                                <IconBlock
                                  icon={icon}
                                  circleSize="sm"
                                  circleColor="orange"
                                  iconColor="black"
                                  iconSize={iconSize || 'md'}
                                  key={index}
                                >
                                  <Text size="25-30">
                                    {title}
                                  </Text>
                                </IconBlock>
                              ))}
                            </div>
                          )}
                        </>
                      }
                      right={
                        <>
                          {upliftingExperience.right?.title && (
                            <Heading Tag="h3">
                              {upliftingExperience.right.title}
                            </Heading>
                          )}
                          {upliftingExperience.right?.text && (
                            <Text highlightColor="orange">
                              {upliftingExperience.right.text}
                            </Text>
                          )}
                        </>
                      }
                    >
                      {upliftingExperience.middle?.main && (
                        <div className={styles['customer-exp__middle-main-block']}>
                          {upliftingExperience.middle.main?.title && (
                            <Heading
                              Tag="h3"
                              align="center"
                            >
                              {upliftingExperience.middle.main.title}
                            </Heading>
                          )}
                          {upliftingExperience.middle.main?.text && (
                            <Text align="center">
                              {upliftingExperience.middle.main.text}
                            </Text>
                          )}
                        </div>
                      )}
                      {upliftingExperience.middle?.other && (
                        <div className={styles['customer-exp__middle-other-block']}>
                          {upliftingExperience.middle.other.map(({ title, text }, index) => (
                            <div
                              className={styles['middle-other-block__item']}
                              key={index}
                            >
                              {title && (
                                <Heading
                                  Tag="h4"
                                  align="center"
                                  color="orange"
                                  view="h5"
                                >
                                  {title}
                                </Heading>
                              )}
                              {text && (
                                <Text align="center">
                                  {text}
                                </Text>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </CustomerExperienceContent>
                  </div>
                )}
              </div>
            )}

            {visitorExperience && (
              <div className={`${styles['customer-exp__visitor']} ${styles['customer-exp__black']}`}>
                <div className="layout">
                  <i className={styles['customer-exp__border']}></i>
                  {visitorExperience?.title && (
                    <div className="visitor__heading">
                      <Heading 
                        align="center"
                        decoration
                      >
                        {visitorExperience.title}
                      </Heading>
                    </div>
                  )}
                  {visitorExperience?.intro && (
                    <div className="visitor__text">
                      <Text
                        align="center"
                        size="25-30"
                        maxWidth="65"
                      >
                        {visitorExperience.intro}
                      </Text>
                    </div>
                  )}
                </div>
                {visitorExperience?.left && visitorExperience?.middle && visitorExperience?.right && (
                  <div className={styles['customer-exp__content-block']}>
                    <CustomerExperienceContent
                      left={
                        <>
                          {visitorExperience.left?.title && (
                            <Heading Tag="h3">
                              {visitorExperience.left.title}
                            </Heading>
                          )}
                          {visitorExperience.left?.text && (
                            <Text>
                              {visitorExperience.left.text}
                            </Text>
                          )}
                          {visitorExperience.left?.iconsList && (
                            <div className={styles['customer-exp__content-icons']}>
                              {visitorExperience.left.iconsList.map(({ icon, iconSize, title }, index) => (
                                <IconBlock
                                  icon={icon}
                                  circleSize="sm"
                                  circleColor="orange"
                                  iconColor="black"
                                  iconSize={iconSize || 'md'}
                                  key={index}
                                >
                                  <Text size="25-30">
                                    {title}
                                  </Text>
                                </IconBlock>
                              ))}
                            </div>
                          )}
                        </>
                      }
                      right={
                        <>
                          {visitorExperience.right?.title && (
                            <Heading Tag="h3">
                              {visitorExperience.right.title}
                            </Heading>
                          )}
                          {visitorExperience.right?.text && (
                            <Text highlightColor="orange">
                              {visitorExperience.right.text}
                            </Text>
                          )}
                          {visitorExperience.right?.list && (
                            <List 
                              fontSize="20-25"
                              items={visitorExperience.right.list} 
                            />
                          )}
                        </>
                      }
                    >
                      {visitorExperience.middle?.main && (
                        <div className={styles['customer-exp__middle-main-block']}>
                          {visitorExperience.middle.main?.title && (
                            <Heading
                              Tag="h3"
                              align="center"
                            >
                              {visitorExperience.middle.main.title}
                            </Heading>
                          )}
                          {visitorExperience.middle.main?.text && (
                            <Text align="center">
                              {visitorExperience.middle.main.text}
                            </Text>
                          )}
                        </div>
                      )}
                      {visitorExperience.middle?.other && (
                        <div className={styles['customer-exp__middle-other-block']}>
                          {visitorExperience.middle.other.map(({ title, text }, index) => (
                            <div
                              className={styles['middle-other-block__item']}
                              key={index}
                            >
                              {title && (
                                <Heading
                                  Tag="h4"
                                  align="center"
                                  color="orange"
                                  view="h5"
                                >
                                  {title}
                                </Heading>
                              )}
                              {text && (
                                <Text align="center">
                                  {text}
                                </Text>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </CustomerExperienceContent>
                  </div>
                )}

                <CircleDecoration 
                  color="rgba(96, 119, 218, 0.3)"
                  height="90vh"
                  position={{
                    bottom: '15vh',
                    right: '-11vw'
                  }}
                  rotate="18"
                  width="27vw"
                />
              </div>
            )}

            <CircleDecoration 
              color="rgba(96, 119, 218, 0.3)"
              height="90vh"
              position={{
                top: '100vh',
                left: '16vw'
              }}
              rotate="18"
              width="27vw"
            />
          </Black>
        </div>
      )}
    </div>
  )
}

export default CustomerExperience