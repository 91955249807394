import React, { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { MeetOurPeopleItem, Modal } from '../';

import { teamModal } from '../../utils/teamModal';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

const CarouselMeetPeople = ({ slides }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);

  return (
    <>
      <div className={styles['meet-people__carousel']}>
        <Swiper
          modules={[Navigation]}
          navigation
          slidesPerView={1}
          spaceBetween={90}
        >
          {slides.map((slide, i) => (
            <SwiperSlide className={styles['meet-people__slide']} key={i}>
              <MeetOurPeopleItem 
                data={slide}
                setActiveModal={setActiveModal}
                setModalOpen={setModalOpen}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {slides.map(({ pic, modalId }, i) => (
        <React.Fragment key={i}>
          {modalOpen && activeModal === modalId && (
            <Modal 
              data={teamModal[modalId]} 
              pic={pic} 
              open={modalOpen} 
              setModalOpen={setModalOpen}
              backUrl="/careers"
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default CarouselMeetPeople