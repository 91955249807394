import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Heading, PicBlock, Text, Icon, CircleDecoration } from '../../components';

import { ourLeadership } from '../../utils/aboutUs';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const LeadershipDetail = () => {
  const { leadershipId } = useParams();
  const [currentLeader, setCurrentLeader] = useState(null);
  const leadershipDetailRef = useRef();

  useEffect(() => {
    const filteredLeaders = ourLeadership.filter(item => item?.id && item.id === leadershipId);
    const newLeader = filteredLeaders[0]?.leader;
    setCurrentLeader(newLeader);
  }, [leadershipId]);

  useLayoutEffect(() => {
    let mm = gsap.matchMedia(),
        breakPoint = 769;

    mm.add({
      isDesktop: `(min-width: ${breakPoint}px)`,
      isMobile: `(max-width: ${breakPoint - 1}px)`,
    }, (context) => {
      let { isDesktop } = context.conditions;

      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles.leadership__page}`,
            start: isDesktop ? 'center bottom' : 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles.leadership__left}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 0)
        .from(`.${styles['leadership__place-and-pos']}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .5)
        .from(`[class*="leadership__right"] .heading-component`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .7)
        .from(`.${styles.leadership__intro}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .9)
        .from(`.${styles.leadership__desc}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.2)
        .from(`.${styles.leadership__panel}`, {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        document.querySelectorAll('[class*="leadership__page"] [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0 + index / 2);
        });
      }, 100)
    })
  }, []);

  return (
    <div ref={leadershipDetailRef}>
      {currentLeader && (
        <div className={styles.leadership__page}>
          <div className={styles.leadership__left}>
            <PicBlock 
              image={currentLeader?.pic}
              decoration="orange"
              type="3"
              customClass={styles.leadership__pic}
            />
          </div>
          <div className={styles.leadership__right}>
            <div className={styles['leadership__place-and-pos']}>
              <div className={styles.leadership__position}>
                <Text
                  color="purple-100"
                  size="22-25"
                  transform="uppercase"
                >
                  {currentLeader?.position}
                </Text>
              </div>
              <div className={styles.leadership__place}>
                <Text
                  color="white"
                  size="22-25"
                  transform="uppercase"
                >
                  {currentLeader?.place}
                </Text>
              </div>
            </div>
            <Heading
              Tag="h1"
              decoration={{
                color: 'purple-100',
                width: '25px'
              }}
              spacing="25"
            >
              {currentLeader?.name}
            </Heading>
            <div className={styles.leadership__intro}>
              <Text
                color="white"
                size="22-25"
              >
                {currentLeader?.description}
              </Text>
            </div>
            <div className={styles.leadership__desc}>
              <Text color="white">
                {currentLeader?.fullDesc}
              </Text>
            </div>
          </div>
          <div className={styles.leadership__panel}>
            <Button
              btnType="link-icon"
              color="white-orange"
              href="/about-us"
              icon={<Icon icon="angle--left" />}
              iconPosition="left"
            >
              Back to about us
            </Button>
          </div>

          <CircleDecoration 
            height="60vh"
            position={{
              top: '-16vh',
              left: '-1vw'
            }}
            width="26vw"
          />
          <CircleDecoration 
            height="83vh"
            position={{
              top: '5vh',
              left: '-15vw'
            }}
            rotate="21"
            width="26vw"
          />
          <CircleDecoration 
            height="60vh"
            position={{
              top: '8vh',
              right: '-9vw'
            }}
            width="26vw"
          />
          <CircleDecoration 
            height="100vh"
            position={{
              top: '18vh',
              right: '8vw'
            }}
            rotate="-22"
            width="26vw"
          />
        </div>  
      )}
    </div>
  )
}

export default LeadershipDetail