import { TypesOfProject } from '../../components';

import styles from './index.module.scss';

import Decoration from '../../assets/svg/pic-decoration-6.svg';


const TypesOfProjectSection = ({ data }) => {
  return (
    <div className={styles['types-of-project__section']}>
      <div className={styles['types-of-project__decor']}>
        <img className={styles['types-of-project__top-decor']} src={Decoration} alt="" />
        <img className={styles['types-of-project__bottom-decor']} src={Decoration} alt="" />
      </div>
      <TypesOfProject data={data} />
    </div>
  )
}

export default TypesOfProjectSection