import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import { Navigation, Pagination, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading, PicBlock, Text } from '../';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const CarouselLeadership = ({ slides }) => {
  const swiperNavPrev = useRef(null);
  const swiperNavNext = useRef(null);
  const swiperPagination = useRef(null);
  const carouselRef = useRef();
  
  useLayoutEffect(() => {
    // Global insights section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['leadership__carousel']}`,
            start: 'center bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['leadership__carousel']} .${styles['leadership__main-content']}`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['leadership__carousel']} .${styles['leadership__pic']}`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from(`.${styles['leadership__carousel']} .${styles['leadership__content']}`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8)
        .from(`.${styles['leadership__carousel']} [class*="leadership__nav-item"][class*="--prev"]`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from(`.${styles['leadership__carousel']} [class*="leadership__nav-item"]:not([class*="--prev"])`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from(`.${styles['leadership__carousel']} [class*="leadership__pagination-wrap"]`, {
          y: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1);
      })
    }, carouselRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles['leadership__carousel']} ref={carouselRef}>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          prevEl: swiperNavPrev.current,
          nextEl: swiperNavNext.current,
        }}
        pagination={{
          el: swiperPagination.current,
          clickable: true,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = swiperNavPrev.current;
            swiper.params.navigation.nextEl = swiperNavNext.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();

            swiper.params.pagination.el = swiperPagination.current;
            swiper.pagination.destroy();
            swiper.pagination.init();
            swiper.pagination.render();
            swiper.pagination.update();
          });
        }}
      >
        {slides.map(({id, title, intro, leader}, i) => (
          <SwiperSlide className={styles['leadership__slide']} key={id}>
            <div className={styles['leadership__main-content']}>
              <Heading
                Tag="h2"
                color="black"
                decoration={{
                  color: 'purple-100'
                }}
              >
                {title}
              </Heading>
              <Text color="black">
                {intro}
              </Text>
            </div>
            <Link to={`/leadership/${id}`} className={styles['leadership__pic']}>
              <PicBlock 
                image={leader.pic}
                decoration="purple"
                gradient="purple"
                icon="angle--right"
                type="2"
              />
            </Link>
            <Link to={`/leadership/${id}`} className={styles['leadership__content']}>
              <div className={styles['leadership__content-top']}>
                <Text
                  color="purple-100"
                  size="30-35"
                >
                  {leader.position}
                </Text>
                <Text color="black">
                  {leader.place}
                </Text>
              </div>
              <Heading
                Tag="h3"
                color="black"
                decoration={{
                  color: 'purple-100',
                  width: '25px'
                }}
                spacing="20"
              >
                {leader.name}
              </Heading>
              <Text
                color="black"
                size="22-25"
              >
                {leader.description}
              </Text>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.leadership__nav}>
        <button 
          className={`${styles['leadership__nav-item']} ${styles['--prev']}`} 
          ref={swiperNavPrev} 
        />
        <button 
          className={`${styles['leadership__nav-item']} ${styles['--next'] ? styles['--next'] : ''}`} 
          ref={swiperNavNext} 
        />
      </div>
      <div className={styles['leadership__pagination-wrap']}>
        <div 
          className={styles.leadership__pagination} 
          ref={swiperPagination}
        ></div>
      </div>
    </div>
  )
}

export default CarouselLeadership