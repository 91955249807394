import { Text } from '../';

import styles from './index.module.scss';

const AdditionalAddress = ({ items }) => {
  return (
    <div className={styles['additional-addresses__block']}>
      {items && items.map(({ place, address }, index) => (
        <div key={index} className={styles['additional-addresses__item']}>
          {place && (
            <Text
              color="orange"
            >
              {place}
            </Text>
          )}
          {address && (
            <Text
              size="25-35"
            >
              {address}
            </Text>
          )}
        </div>
      ))}
    </div>
  )
}

export default AdditionalAddress