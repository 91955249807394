import HeroBg from '../assets/img/bg-customer-experience.png';
import PicOffering from '../assets/img/pic-offering-description.png';

// DATA: CUSTOMER EXPERIENCE

// Hero
export const heroData = {
  bg: HeroBg,
  title: 'Customer Experience',
  text: <>
    <p>When done right, customer experience (CX) drives substantial impact for businesses by getting an impact flywheel spinning. In fact, 73 percent of senior executives recognize CX as a top organization priority by 2023.</p>
  </>
};

// Service line
export const serviceLine = {
  title: 'Service line Description',
  intro: <>
    <p>We support your CX transformation journey by following our signature three-step approach:</p>
  </>,
  items: [
    {
      icon: 'clock',
      title: 'Build aspiration and purpose.',
      text: 'We ensure that you have a clear CX aspiration and purpose, including links to values and a road map.'
    },
    {
      icon: 'care',
      title: 'Transform the business.',
      text: 'We build your capabilities in (re)designing and innovating journeys, channels, services, and business models. We do this by taking disruptive approaches to reimagine journeys and drawing on expert research on the drivers of delight (beyond satisfaction).'
    },
    {
      icon: 'radio',
      title: 'Enable the transformation.',
      text: 'We support you in implementing critical enablers to drive CX transformational change—for example, through setup technology, data and analytics, CX performance management, operating models, and more.'
    }
  ]
};

// Offering Description
export const offeringDescription = {
  pic: PicOffering,
  title: 'Offering Description',
  intro: <>
    <p>We deliver impact for our clients and enable them to create distinctive customer and visitor experiences. We offer end-to-end CX/VX transformation management leveraging our distinctive set of tools and solutions, including Journey Pulse, Future Studios, Aspiration workshops, CX maturity assessment, and Delight to Value.</p>
  </>
};

// Uplifting experience
export const upliftingExperience = {
  title: 'Uplifting the experience of more than ten million visitors',
  intro: <>
    <p>We designed and activated the regional destination management office (DMO) of a key region in the KSA to improve the CX of millions of visitors across priority visitor hubs.</p>
  </>,
  left: {
    title: 'Context',
    list: [
      'The client is responsible for developing a key region in KSA (one of the most visited cities in the world).',
      'The client aims to position the region as a leading tourist destination, in line with the National Tourism Strategy.',
    ],
    iconsList: [
      {
        icon: 'people',
        iconSize: 'xs',
        title: '~1.4 million residents'
      },
      {
        icon: 'map',
        title: '150,000 km2 geographic area'
      },
      {
        icon: 'calendar',
        title: '~10 million visitors per year'
      }
    ]
  },
  middle: {
    main: {
      title: 'Approach',
      text: 'We supported the client in setting up and activating the region’s DMO to achieve the destination’s aspirations.'
    },
    other: [
      {
        title: 'DMO blueprint.',
        text: 'We detailed the office’s key functions and processes and designed the operating model for seamless interaction with external and internal stakeholders.'
      },
      {
        title: 'Future labs.',
        text: 'We kicked off the concept curation process with world-renowned futurists to develop innovative concepts to activate key visitor hubs.'
      },
      {
        title: 'Visitor experience standards.',
        text: 'We developed a comprehensive activation plan based on field audit visits to define VX uplift interventions across key sites.'
      }
    ]
  },
  right: {
    title: 'Client impact',
    text: <>
      <p><strong>DMO blueprint aligned and approved</strong> by the Tourism Authority and the region’s principality</p>
      <p><strong>Six creative concepts</strong> fast-tracked to implementation</p>
      <p><strong>All six functions of</strong> DMO are fully functioning with <strong>30-plus projects being monitored30-plus projects being monitored</strong></p>
    </>
  }
}

// Uplifting experience
export const visitorExperience = {
  title: 'Creating a visitor experience unit for a national tourism body',
  intro: <>
    <p>We helped a national tourism body build a visitor experience (VX) unit to support the tourism ecosystem in achieving nationwide impact.</p>
  </>,
  left: {
    title: 'Client context',
    text: <>
      <p>KSA has placed visitor experience at the core of the transformation, aiming to build a pioneering central capability. </p>
      <p>Our client’s ambition is to be at the global forefront in tourism experience, insights, and analytics. This complements the ambitious transformational plans of Vision 2030, which plans to transform Saudi to a major tourism destination. By 2030, KSA hopes to achieve the following:</p>
    </>,
    iconsList: [
      {
        icon: 'people',
        iconSize: 'xs',
        title: '80+ million overnight visitors'
      },
      {
        icon: 'db',
        title: '15% GDP contribution and 2 million jobs created'
      },
      {
        icon: 'web-spikelets',
        title: '2 million jobs created'
      }
    ]
  },
  middle: {
    main: {
      title: 'Approach',
      text: 'Implement four outstanding transformational vehicles:'
    },
    other: [
      {
        title: '1. VX insights platform',
        text: 'creating full transparency and providing focus on experience performance and readiness in the tourism sector'
      },
      {
        title: '2. Design',
        text: 'helping to create delightful moments by designing unique experiences alongside ecosystem partners'
      },
      {
        title: '3. Delight-to-value model',
        text: 'pioneering approach that incorporates the emotions of true delight in addition to mere satisfaction'
      },
      {
        title: '4. Hafawah',
        text: 'activating a cultural preservation strategy based on three overarching values and 15 behaviors by training about 10,000 frontline workers by end of 2021'
      }
    ]
  },
  right: {
    title: 'Expected impact',
    text: <>
      <p>Based on early results, we expect the client’s NPS to increase by 15–20 points by 2030</p>
    </>,
    list: [
      'This increase should result in about 10 percent more promoters who recommend a leisure visit',
      'Based on recommendations per promotor and conversion rates of recommendations, this would result in about an additional SAR 10 billion by 2030',
    ]
  }
}