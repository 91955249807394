import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading, IconBlock, List } from '../';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

const Carousel = ({ slides }) => {
  return (
    <div className={styles['basic-carousel']}>
      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          769: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          1025: {
            slidesPerView: 2,
            spaceBetween: 95
          }
        }}
      >
        {slides.map(({icon, iconSize, title, list}, i) => (
          <SwiperSlide className={styles['basic-carousel__slide']} key={i}>
            <IconBlock
              circleSize="xl"
              icon={icon}
              iconSize={iconSize}
              margin="mb-50"
            >
              <Heading
                Tag="h3"
                color="orange"
                view="h4"
              >
                {title}
              </Heading>
            </IconBlock>
            <div className={styles['basic-carousel__content']}>
              {list && (
                <List 
                  padding="pl-55" 
                  items={list} 
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel