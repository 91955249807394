import { CarouselJoinUs } from '../../components';

import styles from './index.module.scss';

const JoinUs = ({ data }) => {
  return (
    <div className={styles['join-us__section']}>
      <CarouselJoinUs slides={data} />
    </div>
  )
}

export default JoinUs