import { Icon } from '../';

import styles from './index.module.scss';

const ContactIcons = ({ items }) => {
  return (
    <div className={styles['contact-icons__list']}>
      {items && items.map(({type, label, icon, value}, index) => (
        <div key={index} className={`${styles['contact-icons__item']} ${styles['type-'+icon] ? styles['type-'+icon] : ''}`}>
          <div className={styles['contact-icons__icon']}>
            <Icon icon={icon} />
          </div>
          <div className={styles['contact-icons__label']}>{label}</div>
          <div className={styles['contact-icons__value']}>
            {(type === 'tel' || type === 'mailto') && (
              <a href={`${type}:${value}`}>
                {value}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ContactIcons