import styles from './index.module.scss';

const Heading = ({ Tag = 'h2', highlightColor = 'orange', color = 'white', decoration, align, customClass, spacing, view, maxWidth, transform, letterSpacing, children }) => {
  return (
    <Tag
      className={`heading-component ${color} ${align ? styles['align-'+align] : ''} highlight-${highlightColor} ${decoration ? (decoration?.position ? styles['decorated-'+decoration.position] : styles.decorated) : ''} ${spacing ? styles['spacing-'+spacing] : ''} ${view ? styles[view] : ''} ${maxWidth ? 'max-w-'+maxWidth : ''} ${transform ? styles['transform-'+transform] : ''} ${letterSpacing ? styles['letter-spacing-'+letterSpacing] : ''} ${customClass ? customClass : ''}`}
      style={{
        textAlign: align ? align : ''
      }}
    >
      <span>{children}</span>
      {decoration && (
        <i 
          className={`${styles.decoration} ${decoration?.position ? styles[`decoration-${decoration.position}`] : ''} ${align ? styles[`decoration-align-${align}`] : ''} bg-${decoration?.color ? decoration.color : 'orange'}`}
          style={{
            width: decoration?.width ? decoration.width : '40px'
          }}
        ></i>
      )}
    </Tag>
  )
}

export default Heading