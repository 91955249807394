import { Link } from 'react-router-dom';

import styles from './index.module.scss';

const Button = ({ type, icon, iconPosition, href, btnType, color, size = 'm', customClass, onClick, full, children }) => {
  const btnClasses = `${styles.btn} ${styles['btn-'+size]} ${btnType ? styles['btn-'+btnType] : ''} ${color ? styles['btn-'+color] : ''} ${iconPosition ? styles['icon-position-'+iconPosition] : ''} ${full ? styles.full : ''} ${customClass ? customClass : ''}`;
  const Tag = type === 'link' ? 'a' : 'button';

  return (
    <>
      {type ? (
        <Tag 
          href={href}
          className={btnClasses}
          onClick={(e) => onClick && onClick(e)}
        >
          {children && (
            <div>{children}</div>
          )}
          {icon && (
            <div className={styles['btn-icon']}>{icon}</div>
          )}
        </Tag>
      ) : (
        <Link 
          to={href}
          className={btnClasses}
          onClick={(e) => onClick && onClick(e)}
        >
          <div>{children}</div>
          {icon && (
            <div className={styles['btn-icon']}>{icon}</div>
          )}
        </Link>
      )}
    </>
  )
}

export default Button