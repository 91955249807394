import { useState } from 'react'

import styles from './index.module.scss';

const TextField = ({ name, placeholder, type }) => {
  const [fieldValue, setFieldValue] = useState('');

  const onChange = (e) => {
    setFieldValue(e.target.value);
  }

  return (
    <div className={styles['text-field__component']}>
      {type === 'multiline-text' ? (
        <textarea 
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={fieldValue}
        />
      ) : (
        <input 
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={fieldValue} 
        />
      )}
    </div>
  )
}

export default TextField