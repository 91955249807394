import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Heading, Text, CarouselFullWidth, CircleDecoration } from '../../components';
import { Black, Hero, White } from '../../sections';

import { careers } from '../../utils/careersDetails';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const CareerDetail = () => {
  const { careerId } = useParams();
  const [currentCareer, setCurrentCareer] = useState(null);
  const careerDetailRef = useRef();

  useEffect(() => {
    const filteredCareers = careers.filter(item => item?.id && item.id === careerId);
    const newCareer = filteredCareers[0];
    setCurrentCareer(newCareer);
  }, [careerId]);

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] [class*="hero__logo"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1);
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Job overview section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.job-overview__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.job-overview__section .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.job-overview__section .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3);
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.career-black__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Quals section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.career-quals__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.career-quals__section .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.career-quals__section [class*="career-detail__carousel"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3);
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Quals section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.work-with__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.work-with__section [class*="career-detail__border"]', {
          width: 0,
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.work-with__section .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from('.work-with__section .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6)
        .from('.work-with__section [class*="career-detail__image"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 2
        }, 0.9);
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Quals section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.you-will-do__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.you-will-do__section .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.you-will-do__section [class*="career-detail__carousel"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from('.you-will-do__section [class*="career-detail__button"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6);
      }, 100)
    }, careerDetailRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={careerDetailRef}>
      {currentCareer && (
        <>
          {currentCareer?.hero && (
            <Hero
              bg={currentCareer.hero?.bg}
              shadow
            >
              {currentCareer.hero?.logo && (
                <img 
                  className="hero__logo"
                  src={currentCareer.hero.logo} 
                  alt="Elixir" 
                />
              )}
              {currentCareer.hero?.title && (
                <Heading
                  Tag="h1"
                  spacing="40"
                  decoration={{
                    width: '50px'
                  }}
                >
                  {currentCareer.hero.title}
                </Heading>
              )}
            </Hero>
          )}

          {currentCareer?.overview && (
            <div className="job-overview__section">
              <White>
                {currentCareer.overview?.title && (
                  <Heading
                    align="center"
                    color="black"
                    decoration={{
                      color: 'purple-100'
                    }}
                    spacing="30"
                    view="h3-2"
                  >
                    {currentCareer.overview.title}
                  </Heading>
                )}
                {currentCareer.overview?.text && (
                  <Text 
                    align="center"
                    color="black"
                    maxWidth="50"
                  >
                    {currentCareer.overview.text}
                  </Text>
                )}
              </White>
            </div>
          )}

          {(currentCareer?.quals || currentCareer?.workWith || currentCareer?.youWillDo) && (
            <div className="career-black__section">
              <Black 
                noLayout
                overflow="hidden"
              >
                {currentCareer?.quals && (
                  <div className={`career-quals__section ${styles['career-detail__black-block']}`}>
                    <div className="layout">
                      {currentCareer.quals?.title && (
                        <Heading
                          align="center"
                          decoration
                          spacing="30"
                          view="h3-2"
                        >
                          {currentCareer.quals.title}
                        </Heading>
                      )}
                      {currentCareer.quals?.items && (
                        <div className={styles['career-detail__carousel']}>
                          <CarouselFullWidth items={currentCareer.quals.items} />
                        </div>
                      )}
                    </div>
                    <CircleDecoration 
                      height="82vh"
                      position={{
                        top: '-28vh',
                        right: '8%'
                      }}
                      rotate="-21"
                      width="26%"
                    />
                  </div>
                )}

                {currentCareer?.workWith && (
                  <div className={`work-with__section ${styles['career-detail__black-block']} ${styles['--border']}`}>
                    <div className="layout">
                      <div className={styles['career-detail__border']}></div>
                      {currentCareer.workWith?.title && (
                        <Heading
                          align="center"
                          decoration
                          spacing="30"
                          view="h3-2"
                        >
                          {currentCareer.workWith.title}
                        </Heading>
                      )}
                      {currentCareer.workWith?.content && (
                        <Text 
                          align="center"
                          maxWidth="60"
                        >
                          {currentCareer.workWith.content}
                        </Text>
                      )}
                    </div>
                    <div className={styles['career-detail__image']}>
                      <img src={currentCareer.workWith?.pic} alt="" />
                    </div>
                    <CircleDecoration
                      height="82vh"
                      position={{
                        top: '61vh',
                        left: '-19%'
                      }}
                      rotate="-21"
                      width="26%"
                    />
                  </div>
                )}

                {currentCareer?.youWillDo && (
                  <div className={`you-will-do__section ${styles['career-detail__black-block']}`}>
                    <div className="layout">
                      {currentCareer.youWillDo?.title && (
                        <Heading
                          align="center"
                          decoration
                          spacing="30"
                          view="h3-2"
                        >
                          {currentCareer.youWillDo.title}
                        </Heading>
                      )}
                      {currentCareer.youWillDo?.items && (
                        <div className={styles['career-detail__carousel']}>
                          <CarouselFullWidth items={currentCareer.youWillDo.items} />
                        </div>
                      )}
                      {currentCareer.youWillDo?.button && (
                        <div className={styles['career-detail__button']}>
                          <Button
                            type="button"
                            color="orange"
                            size="m2"
                          >
                            {currentCareer.youWillDo.button?.title}
                          </Button>
                        </div>
                      )}
                    </div>

                    <CircleDecoration 
                      color="rgba(96, 119, 218, 0.3)"
                      height="82vh"
                      position={{
                        bottom: '-5vh',
                        right: '14%'
                      }}
                      rotate="21"
                      width="26%"
                    />
                  </div>
                )}
              </Black>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CareerDetail