import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, CircleDecoration, ContentBlock, Grid, HeaderDecorated, Heading, IconBlock, ImpactItem, List, PicAndContent, PicBlock, Text } from '../../components';
import { Black, Hero, White } from '../../sections';
import { heroData, transformationDelivery, servicesCols, estabilishingVivion, buildingOrgs, overview, approach, impact, censusProgram, censusImpact } from '../../utils/deliveryExcellence';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const DeliveryExcellence = () => {
  const deliveryExcellenceRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Transformation delivery section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.transformation-delivery__section',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.transformation-delivery__section [class*="transformation-delivery__inner"] > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        document.querySelectorAll('.transformation-delivery__section [class*="content__block"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0.5 + index / 2);
        });

        document.querySelectorAll('.transformation-delivery__section [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0.5 + index / 2);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Services section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles.services__section}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll(`.${styles.services__col}`).forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1
          }, 0 + index / 2);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.establishing-building__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Establishing section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['establishing-vivion__section']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['establishing-vivion__section']} [class*="establishing-vivion__pic"]`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['establishing-vivion__section']} .establishing__sub-title .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles['establishing-vivion__section']} .establishing__title .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6)
        .from(`.${styles['establishing-vivion__section']} .establishing__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="establishing-vivion__grid"]`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll('[class*="establishing-vivion__grid"] > div').forEach((item, index) => {
          stl2.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0 + index / 2);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Building section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['building-orgs__section']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['building-orgs__section']} .building__sub-title .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['building-orgs__section']} .building__title .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles['building-orgs__section']} .building__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="building-orgs__grid"]`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll('[class*="building-orgs__grid"] > div').forEach((item, index) => {
          stl2.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0 + index / 2);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Building section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.overview__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.overview__section .overview__sub-title .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.overview__section .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.overview__section .overview__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6);
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.approach-impact-census__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Approach and impact section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.approach-impact-census__section [class*="list-decor__section"]').forEach((item, index) => {
          let stlDecor = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top+=15% bottom',
              end: 'bottom top',
            }
          });

          stlDecor.from(item.querySelector('[class*="header-decorated__block"]'), {
            y: '-10vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 0)
          .from(item.querySelector('.text-component'), {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, .4)
          .from(item.querySelector('.list-component'), {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 2
          }, .8);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Census section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="census-program__inner"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        stl.from('[class*="census-program__inner"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="census-program__inner"] .census-program__sub-title', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .4)
        .from('[class*="census-program__inner"] .census-program__title', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .5)
        .from('[class*="census-program__inner"] .census-program__text', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .8);

        document.querySelectorAll('[class*="census-program__pics"] [class*="pic-and-content__block"]').forEach((item) => {
          let stlPic = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top+=15% bottom',
              end: 'bottom top',
            }
          });

          if(item.getAttribute('class').indexOf('pic--right') !== -1){
            stlPic.from(item, {
              opacity: 0,
              ease: 'power2',
              duration: 1.5
            }, 0)
            .from(item.querySelector('[class*="pic-and-content__pic"]'), {
              x: '10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 0.5)
            .from(item.querySelector('[class*="pic-and-content__content"]'), {
              x: '-10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 1);
          } else {
            stlPic.from(item, {
              opacity: 0,
              ease: 'power2',
              duration: 1.5
            }, 0)
            .from(item.querySelector('[class*="pic-and-content__pic"]'), {
              x: '-10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 0.5)
            .from(item.querySelector('[class*="pic-and-content__content"]'), {
              x: '10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 1);
          }
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Impact section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="census-impact__section"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        stl.from('[class*="census-impact__section"] .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="census-impact__section"] .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, .3);

        document.querySelectorAll('[class*="census-impact__list"] [class*="impact__item"]').forEach((item, index) => {
          stl.from(item, {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 0.5 + index / 4);
        });
      })
    }, deliveryExcellenceRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={deliveryExcellenceRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
          padding="bottom-xs"
        >
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text size="25-35">
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      {transformationDelivery && (
        <div className="transformation-delivery__section">
          <Black
            noLayout
            padding="lg"
            overflow="hidden"
          >
            <div className={styles['transformation-delivery__block']}>
              <div className="layout">
                <div className={styles['transformation-delivery__inner']}>
                  {transformationDelivery?.title && (
                    <Heading
                      Tag="h2"
                      view="h3-2"
                      customClass={styles['transformation-delivery__title']}
                    >
                      {transformationDelivery.title}
                    </Heading>
                  )}
                  {transformationDelivery?.cols && (
                    <Grid cols="2" gap="sm">
                      {transformationDelivery.cols.map(({ title, content }, index) => (
                        <ContentBlock key={index}>
                          {title && (
                            <Heading
                              Tag="h3"
                              view="h4"
                            >
                              {title}
                            </Heading>
                          )}
                          {content && (
                            <Text>
                              {content}
                            </Text>
                          )}
                        </ContentBlock>
                      ))}
                    </Grid>
                  )}
                </div>
              </div>
            </div>

            <CircleDecoration 
              height="55vh"
              position={{
                top: '0vh',
                right: '17vw'
              }}
              width="27vw"
            />
            <CircleDecoration 
              height="55vh"
              position={{
                top: '21vh',
                right: '0px'
              }}
              width="27vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                bottom: '-14vh',
                right: '0px'
              }}
              width="26vw"
            />
          </Black>
        </div>
      )}

      <div className="overflow-hidden">
        {servicesCols && (
          <White padding="none">
            <div className={styles.services__section}>
              <Grid gap="md2">
                {servicesCols.map(({ title, content, link, button }, index) => (
                  <div className={styles.services__col} key={index}>
                    {title && (
                      <Heading
                        Tag="h2"
                        color="black"
                        view="h3-2"
                        spacing="30"
                      >
                        {title}
                      </Heading>
                    )}
                    {content && (
                      <Text color="black">
                        {content}
                      </Text>
                    )}
                    {link && button && (
                      <Button 
                        href={link}
                        color="purple"
                        size="m2"
                      >
                        {button}
                      </Button>
                    )}
                  </div>
                ))}
              </Grid>
            </div>
          </White>
        )}

        {(estabilishingVivion || buildingOrgs) && (
          <div className="establishing-building__section">
            <Black noLayout>
              {estabilishingVivion && (
                <div className={styles['establishing-vivion__section']}>
                  <div className="layout">
                    {estabilishingVivion?.pic && (
                      <div className={styles['establishing-vivion__pic']}>
                        <PicBlock
                          image={estabilishingVivion.pic}
                          gradient="purple"
                          type="romb"
                        />
                      </div>
                    )}
                    <div className={styles['establishing-vivion__content']}>
                      {estabilishingVivion?.subTitle && (
                        <div className="establishing__sub-title">
                          <Text
                            color="orange"
                            size="22-25"
                            letterSpacing="0.5em"
                            transform="uppercase"
                            customClass="mb-0-3"
                          >
                            {estabilishingVivion.subTitle}
                          </Text>
                        </div>
                      )}
                      {estabilishingVivion?.title && (
                        <div className="establishing__title">
                          <Heading decoration>
                            {estabilishingVivion.title}
                          </Heading>
                        </div>
                      )}
                      {estabilishingVivion?.intro && (
                        <div className="establishing__text">
                          <Text
                            maxWidth="60"
                            size="25-35"
                          >
                            {estabilishingVivion.intro}
                          </Text>
                        </div>
                      )}
                      {estabilishingVivion?.cols && (
                        <Grid cols="2" gap="md3" customClass={styles['establishing-vivion__grid']}>
                          {estabilishingVivion.cols.map(({ icon, title, list }, index) => (
                            <div key={index} className={styles['establishing-vivion__col']}>
                              <IconBlock
                                circleColor="grey-500-02"
                                circleSize="xl"
                                icon={icon}
                                iconSize="md2"
                                margin="mb-50"
                              >
                                <Heading
                                  Tag="h3"
                                  color="orange"
                                  spacing="0"
                                  view="h4"
                                >
                                  {title}
                                </Heading>
                              </IconBlock>
                              <List 
                                items={list}
                                gap="md2"
                              />
                            </div>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </div>
                  <CircleDecoration 
                    color="rgba(96, 119, 218, 0.3)"
                    height="82vh"
                    position={{
                      top: '12vh',
                      left: '-19vw'
                    }}
                    rotate="21"
                    width="26vw"
                  />
                  <CircleDecoration 
                    color="rgba(96, 119, 218, 0.3)"
                    height="90vh"
                    position={{
                      top: '52vh',
                      right: '40px'
                    }}
                    width="26vw"
                  />
                </div>
              )}

              {buildingOrgs && (
                <div className={styles['building-orgs__section']}>
                  <div className="layout">
                    <div className={styles['building-orgs__inner']}>
                      {buildingOrgs?.subTitle && (
                        <div className="building__sub-title">
                          <Text
                            color="orange"
                            size="22-25"
                            letterSpacing="0.5em"
                            transform="uppercase"
                            customClass="mb-0-3"
                          >
                            {buildingOrgs.subTitle}
                          </Text>
                        </div>
                      )}
                      {buildingOrgs?.title && (
                        <div className="building__title">
                          <Heading decoration>
                            {buildingOrgs.title}
                          </Heading>
                        </div>
                      )}
                      {buildingOrgs?.intro && (
                        <div className="building__text">
                          <Text
                            maxWidth="50"
                            size="25-35"
                          >
                            {buildingOrgs.intro}
                          </Text>
                        </div>
                      )}
                      {buildingOrgs?.cols && (
                        <Grid cols="2" gap="md3" customClass={styles['building-orgs__grid']}>
                          {buildingOrgs.cols.map(({ icon, title, list }, index) => (
                            <div key={index} className={styles['building-orgs__col']}>
                              <IconBlock
                                circleColor="grey-500-02"
                                circleSize="xl"
                                icon={icon}
                                iconSize="md2"
                                margin="mb-50"
                              >
                                <Heading
                                  Tag="h3"
                                  color="orange"
                                  spacing="0"
                                  view="h4"
                                >
                                  {title}
                                </Heading>
                              </IconBlock>
                              <List 
                                items={list}
                                gap="md2"
                              />
                            </div>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </div>
                  <CircleDecoration 
                    height="67vh"
                    position={{
                      bottom: '10vh',
                      left: '5vw'
                    }}
                    rotate="50"
                    width="31vw"
                  />
                  <CircleDecoration 
                    height="67vh"
                    position={{
                      bottom: '1.5vh',
                      right: '14vw'
                    }}
                    rotate="50"
                    width="31vw"
                  />
                  <CircleDecoration 
                    height="83vh"
                    position={{
                      bottom: '-62vh',
                      left: '0vw'
                    }}
                    rotate="21"
                    width="26vw"
                  />
                </div>
              )}
            </Black>
          </div>
        )}
      </div>

      {overview && (
        <div className="overview__section">
          <White>
            {overview?.subTitle && (
              <div className="overview__sub-title">
                <Text
                  align="center"
                  color="black"
                  letterSpacing="0.5em"
                  transform="uppercase"
                  customClass="mb-0-3"
                >
                  {overview.subTitle}
                </Text>
              </div>
            )}
            {overview?.title && (
              <Heading 
                align="center"
                color="black"
                decoration
              >
                {overview.title}
              </Heading>
            )}
            {overview?.intro && (
              <div className="overview__text">
                <Text
                  align="center"
                  color="black"
                  maxWidth="60"
                >
                  {overview.intro}
                </Text>
              </div>
            )}
          </White>
        </div>
      )}

      {(approach || impact || censusProgram || censusImpact) && (
        <div className="approach-impact-census__section">
          <Black
            overflow="hidden"
            noLayout
          >
            {approach && ( 
              <div className={styles['list-decor__section']}>
                <div className="layout">
                  {approach?.title && (
                    <HeaderDecorated 
                      title={approach.title}
                      icon={approach.icon}
                    />
                  )}
                  {approach?.intro && (
                    <Text
                      align="center"
                      size="25-30"
                      maxWidth="50"
                      customClass={styles['list-decor__text']}
                    >
                      {approach.intro}
                    </Text>
                  )}
                  {approach?.list && (
                    <List 
                      gap="lg"
                      items={approach.list}
                      type="line"
                      fontSize="20-25"
                    />
                  )}
                </div>

                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '-60vh',
                    left: '0vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '-35vh',
                    left: '-4vw'
                  }}
                  rotate="-35"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '15vh',
                    right: '-1vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '28vh',
                    right: '5vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
              </div>
            )}
            
            {impact && (
              <div className={styles['list-decor__section']}>
                <div className="layout">
                  {impact?.title && (
                    <HeaderDecorated 
                      title={impact.title}
                      icon={impact.icon}
                    />
                  )}
                  {impact?.intro && (
                    <Text
                      align="center"
                      size="25-30"
                      maxWidth="50"
                      customClass={styles['list-decor__text']}
                    >
                      {impact.intro}
                    </Text>
                  )}
                  {impact?.list && (
                    <List 
                      items={impact.list}
                      gap="lg"
                      type="line"
                      fontSize="20-25"
                    />
                  )}
                </div>
              </div>
            )}

            {censusProgram && (
              <div className={styles['census-program__section']}>
                <div className="layout">
                  <div className={styles['census-program__inner']}>
                    {censusProgram?.subTitle && (
                      <div className="census-program__sub-title">
                        <Text
                          color="orange"
                          size="22-25"
                          letterSpacing="0.5em"
                          customClass="mb-0-3"
                          transform="uppercase"
                        >
                          {censusProgram.subTitle}
                        </Text>
                      </div>
                    )}
                    {censusProgram?.title && (
                      <div className="census-program__title">
                        <Heading 
                          color="white"
                          decoration
                        >
                          {censusProgram.title}
                        </Heading>
                      </div>
                    )}
                    {censusProgram?.intro && (
                      <div className="census-program__text">
                        <Text
                          color="white"
                          maxWidth="60"
                          size="25-30"
                        >
                          {censusProgram.intro}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
                {censusProgram?.items && (
                  <div className={styles['census-program__pics']}>
                    {censusProgram.items.map(({ pic, title, text, list }, index) => (
                      <PicAndContent
                        pic={pic}
                        key={index}
                        picRight={!((index + 1) % 2)}
                        type="with-decoration"
                      >
                        {title && (
                          <Heading
                            Tag="h3"
                            color="orange"
                            view="h3-2"
                          >
                            {title}
                          </Heading>
                        )}
                        {title && (
                          <Text
                            size="25-30"
                            customClass="mb-2"
                          >
                            {text}
                          </Text>
                        )}
                        <List 
                          fontSize="22-25"
                          items={list}
                        />
                      </PicAndContent>
                    ))}
                  </div>
                )}

                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '130vh',
                    left: '0vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '159vh',
                    left: '-4vw'
                  }}
                  rotate="-35"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '210vh',
                    right: '-1vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
                <CircleDecoration 
                  height="82vh"
                  position={{
                    top: '222vh',
                    right: '5vw'
                  }}
                  rotate="21"
                  width="26vw"
                />
              </div>
            )}

            {censusImpact && (
              <div className={styles['census-impact__section']}>
                <div className="layout">
                  {censusImpact?.title && (
                    <Heading 
                      align="center"
                      color="white"
                      decoration
                    >
                      {censusImpact.title}
                    </Heading>
                  )}
                  {censusImpact?.intro && (
                    <Text
                      align="center"
                      color="white"
                      maxWidth="65"
                      size="25-30"
                    >
                      {censusImpact.intro}
                    </Text>
                  )}
                  {censusImpact?.items && (
                    <div className={styles['census-impact__list']}>
                      {censusImpact.items.map(({ icon, title, text }, index) => (
                        <ImpactItem
                          icon={icon}
                          key={index}
                        >
                          {title && (
                            <Text
                              align="center"
                              color="orange"
                              customClass="mb-0-3"
                            >
                              {title}
                            </Text>
                          )}
                          {text && (
                            <Text align="center">
                              {text}
                            </Text>
                          )}
                        </ImpactItem>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Black>
        </div>
      )}
    </div>
  )
}

export default DeliveryExcellence