import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, Heading, Text } from '../../components';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const TypesOfProject = ({ data }) => {
  const swiperPagination = useRef(null);
  const typesOfProjectRef = useRef();

  useLayoutEffect(() => {
    // Our success stories section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['types-of-project__carousel-wrapper']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['types-of-project__carousel-wrapper']}`, {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['types-of-project__pagination']}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['types-of-project__slide']} .heading-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.7)
        .from(`.${styles['types-of-project__slide']} .text-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from(`.${styles['types-of-project__slide']} .button-wrapper`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.3);
      })
    }, typesOfProjectRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles['types-of-project__carousel-wrapper']} ref={typesOfProjectRef}>
      <div className="layout">
        <div 
          className={styles['types-of-project__pagination']} 
          ref={swiperPagination}
        />
        <div className={styles['types-of-project__carousel']}>
          <Swiper
            modules={[Pagination]}
            pagination={{
              el: swiperPagination.current,
              clickable: true,
              renderBullet: (index, className) => (
                `<span class="${className}">${index + 1 > 9 ? index : '0'+(index+1)}.</span>`
              ),
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.pagination.el = swiperPagination.current;
                swiper.pagination.destroy();
                swiper.pagination.init();
                swiper.pagination.render();
                swiper.pagination.update();
              });
            }}
          >
            {data.map(({id, title, intro, button}) => (
              <SwiperSlide className={styles['types-of-project__slide']} key={id}>
                <Heading
                  decoration={{
                    width: '50px'
                  }}
                  view="h2-2"
                >
                  {title}
                </Heading>
                <Text
                  size="25-35"
                  maxWidth="60"
                >
                  {intro}
                </Text>
                <div className="button-wrapper">
                  <Button
                    size="m2"
                    type="link"
                    href={button?.href}
                    color="orange"
                  >
                    {button?.text}
                  </Button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default TypesOfProject