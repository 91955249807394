import { ContactList, Icon, Text } from '../../components';

import styles from './index.module.scss';

const ContactUsBlock = ({ data: { mailAddress, address, contactList } }) => {
  return (
    <div className={styles['contact-us__block']}>
      <div className={styles['contact-us__header']}>
        <div className={styles['contact-us__icon']}>
          <Icon icon="marker" />
        </div>
        {(mailAddress || address) && (
          <div className={styles['contact-us__header-cnt']}>
            {mailAddress && (
              <Text
                color="orange"
              >
                {mailAddress}
              </Text>
            )}
            {address && (
              <Text
                size="30-35"
              >
                {address}
              </Text>
            )}
          </div>
        )}
      </div>
      {contactList && (
        <div className={styles['contact-us__list']}>
          <ContactList 
            items={contactList}
          />
        </div>
      )}
    </div>
  )
}

export default ContactUsBlock