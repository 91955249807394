import { useState } from 'react';

import { Heading, PicBlock, Text, Modal } from '../';

import { teamModal } from '../../utils/teamModal';

import styles from './index.module.scss';

const TeamItem = ({ type, data: { link, pic, title, text, modalId }, customStyle = {} }) => {
  const Tag = link === 'modal' ? 'a' : 'div';
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div 
      className={`${styles['team__item-wrap']} ${type ? styles['type-'+type] : ''}`}
      style={{...customStyle}}
    >
      <Tag 
        href={link === 'modal' ? `#${modalId}` : link} 
        className={styles.team__item}
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        {type === 'inline' ? (
          <>
            <PicBlock 
              customClass={styles.team__pic}
              image={pic}
              decoration="orange"
              type="small"
            />
            <div className={styles['team__item-content']}>
              <Heading
                Tag="h3"
                color="orange"
                decoration={{
                  color: 'orange',
                  width: '25px',
                  position: 'top'
                }}
                view="h5"
              >
                {title}
              </Heading>
              <Text
                size="22-25"
              >
                {text}
              </Text>
            </div>
          </>
        ) : (
          <>
            <PicBlock 
              customClass={styles.team__pic}
              image={pic}
              decoration="purple"
              gradient="purple"
              icon="angle--right"
            />
            <Heading
              Tag="h3"
              decoration={{
                color: 'purple-100',
                width: '25px'
              }}
              view="h3-2"
            >
              {title}
            </Heading>
            <Text
              size="22-25"
            >
              {text}
            </Text>
          </>
        )}
      </Tag>
      {link === 'modal' && modalOpen && (
        <Modal 
          backUrl="/about-us"
          data={teamModal[modalId]} 
          pic={pic} 
          open={modalOpen} 
          setModalOpen={setModalOpen} 
        />
      )}
    </div>
  )
}

export default TeamItem