import HeroBg from '../assets/img/bg-about-us.png';
import LogoText from '../assets/svg/logo-text.svg';
import PicLeverageInsights1 from '../assets/img/pic-leverage-insights-1.png';
import PicLeverageInsights2 from '../assets/img/pic-leverage-insights-2.jpg';
import PicLeverageInsights3 from '../assets/img/pic-leverage-insights-3.png';
import PicTeamMember from '../assets/img/pic-person.png';
import PicLeadership3 from '../assets/img/pic-leadership-3.png';

import PicHamzeh from '../assets/img/pic-hamzeh.png';
import PicRobert from '../assets/img/pic-robert.jpeg';
import PicImraan from '../assets/img/pic-imraan.jpeg';
import PicSalman from '../assets/img/pic-salman.jpeg';
import PicAbdullah from '../assets/img/pic-abdullah.png';
import PicAyham from '../assets/img/pic-ayham.png';
import PicAhmad from '../assets/img/pic-ahmad.png';
import PicMosab from '../assets/img/pic-mosab.png';
import PicTalal from '../assets/img/pic-talal.png';
import PicHinna from '../assets/img/pic-hinna.jpg';

// DATA: ABOUT US

// Hero
export const heroData = {
  bg: HeroBg,
  logo: LogoText,
  title: 'About Us',
  text: <>
    <p>We leverage global insights to solve local problems.</p>
  </>
};

// Leverage global insights
export const leverageGlobalInsights = {
  title: 'We leverage global insights to solve local problems.',
  slides: [
    {
      title: 'Our Impact',
      text: <>
        <p>Our unique defining pillars create a distinctive “glocal” perspective that can have a lasting social impact for the people of Saudi Arabia and the region as a whole.</p>
      </>
    },
    {
      title: 'Our people',
      text: <>
        <p>We prepare our team members to become the leaders of tomorrow through training, development, mentorship, and upskilling programs.</p>
      </>
    },
    {
      title: 'Your transformation',
      text: <>
        <p>As the Kingdom undergoes an ambitious socioeconomic transformation, we empower our clients to create sustainable change.</p>
      </>
    }
  ],
  pics: [
    PicLeverageInsights1, PicLeverageInsights2, PicLeverageInsights3
  ]
};

// Our history
export const ourHistory = {
  title: <>Our <strong>History</strong></>,
  intro: <>
    <p>Since our founding in 2005, we have dedicated ourselves to transforming organizations across Saudi Arabia through real-time solutions.</p>
  </>,
  history: [
    {
      year: '2005',
      title: 'A new venture',
      text: <>
        <p>Sami Zuhaiby and Hani Khoja leave successful positions at Procter & Gamble to launch a management consultancy devoted to helping solve Saudi Arabia’s major socioeconomic problems.</p>
      </>
    },
    {
      year: '2011',
      title: 'Expanding into the public sector',
      text: <>
        <p>Elixir is invited to engage on its first public-sector project with one of the first transformational ministries to lead labor programs. Since the launch of Vision 2030, Elixir has helped establish a variety of government entities and supported key government processes.</p>
      </>
    },
    {
      year: '2017',
      title: 'Joining forces for a global perspective',
      text: <>
        <p>Elixir is acquired by McKinsey & Company, a global management consulting firm committed to helping organizations accelerate sustainable and inclusive growth. We continue to serve clients both independently and in joint engagements with McKinsey.</p>
      </>
    }
    ,{
      year: '2023',
      title: 'Building toward a bright future',
      text: <>
        <p>In partnership with clients across the private and public sectors, we are turning intentions into actions and working toward lasting social change for Saudi Arabians and for the region as a whole.</p>
      </>
    }
  ]
};

// Our Team
export const ourTeam = {
  title: 'Lorem Ipsum is simply dummy',
  intro: <>
    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using</p>
  </>,
  members: [
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-ahmed'
    },
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-dalia'
    },
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-faisal'
    },
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-ahmed-2'
    },
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-faisal'
    },
    {
      link: 'modal',
      pic: PicTeamMember,
      title: 'Lorem Ipsum is',
      text: <>
        <p>It is a long established fact that a reader will be distracted by the readable content of a</p>
      </>,
      modalId: 'meet-ahmed'
    }
  ]
};

// Our Values
export const ourValues = {
  title: 'Our Values',
  intro: <>
    <p>In alignment with the values and mission of McKinsey, our values inform both our long-term strategies and the way we serve our clients.</p>
  </>,
  slides: [
    {
      icon: 'handshake',
      iconSize: 'md',
      title: 'Adhere to the highest professional standards',
      list: [
        'put client interests ahead of our firm’s', 'maintain high standards and conditions for client service', 
        'observe high ethical standards', 'preserve client confidences', 'maintain an independent perspective', 
        'manage client and firm resources cost-effectively'
      ]
    },
    {
      icon: 'web-spikelets',
      iconSize: 'md2',
      title: 'Improve our clients’ performance significantly',
      list: [
        'follow the top-management approach', 'pursue holistic impact', 'use our global network to deliver the best of our firm to all clients', 
        'bring innovations in management practice to clients', 'build client capabilities to sustain improvement', 
        'build enduring relationships based on trust'
      ]
    },
    {
      icon: 'scales',
      iconSize: 'lg',
      title: 'Create an unrivaled environment for exceptional people',
      list: [
        'be nonhierarchical and inclusive', 'sustain a caring meritocracy', 'develop one another through apprenticeship and mentoring', 
        'uphold the obligations to engage and dissent', 'embrace diverse perspectives with curiosity and respect', 'govern ourselves as a “one firm” partnership'
      ]
    }
  ]
};

// Our Leadership
export const ourLeadership = [{
    id: 'robert-e-lewis',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'A long-standing leader in McKinsey’s Operations Practice, the global leader of McKinsey Implementation, and chairman of Elixir',
      fullDesc: <>
        <p>Robert partners with clients to produce rapid, significant, and sustainable results in costs, productivity, quality, and other metrics. For more than two decades, he has helped the leadership of industrial companies transform their performance by driving capital productivity; boosting operational efficiency; changing operating cultures, from the CEO to the front line; and setting strategic directions. A former chemical engineer, Robert focuses on realizing operational improvements and impact in complex global organizations.</p>
        <p>Robert has particular expertise in understanding the value and hidden opportunities of industrial companies. Taking a broad view of the interplay among strategy, operations, and capital planning, he ensures that his clients develop the conditions for long-term success, including an aligned leadership team, integrated initiatives to deliver value, the operational infrastructure to ensure disciplined execution, and a systematic program to build leadership and functional skills.</p>
        <p>Before joining McKinsey, Robert worked in Product Development and Plant Engineering in Procter & Gamble’s manufacturing organization. He currently serves on the board of the Colburn School of Music in Los Angeles and served on the Dean’s Advisory for the UC Irvine’s Graduate School of Business, where he also is a guest lecturer.</p>
      </>,
      name: 'Robert E. Lewis',
      pic: PicRobert,
      place: 'Southern California',
      position: 'Senior Partner'
    }
  },
  {
    id: 'dr-imraan-munshi',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Leads McKinsey’s Implementation Practice in the Middle East and globally for the SHaPE Practice',
      fullDesc: <>
        <p>Imraan has more than 16 years of consulting experience across the Middle East, Turkey, Africa, and Europe, directing transformational projects in a range of sectors, including in healthcare and economic development. Imraan has experience throughout the healthcare value chain, focusing on pharmaceutical and medical devices, regulators, and providers.</p>
        <p>Examples of Imraan’s work include leading the development of a pharmaceutical-manufacturing strategy for a country in the Gulf, the transformation of a national procurement company, and the establishment a national food-and-drug regulator. He also led the transformation of an emergency medical-services provider in the region.</p>
        <p>Imraan has developed entry strategies for multinational pharmaceutical and medical-devices organizations across the Middle East and North Africa, and has helped shape growth strategies for leading regional pharmaceutical companies. He supported the establishment of a healthcare-systems group for a major pharmaceutical organization and designed a disease-based approach to healthcare reforms for another pharmaceutical institution.</p>
        <p>Imraan also led McKinsey’s acquisition of Elixir. He is a fellow of the Royal College of Surgeons in the United Kingdom and has a fellowship in ophthalmology from the Colleges of Medicine of South Africa.</p>
      </>,
      name: 'Dr. Imraan Munshi',
      pic: PicImraan,
      place: 'Dubai',
      position: 'Senior Partner'
    }
  },
  {
    id: 'waqar-mian-mahmood',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Designs and delivers transformation projects and makes lasting improvements in operations by building client capabilities',
      fullDesc: <>
        <p>With more than 14 years of hands-on operational experience, Waqar is passionate about designing and implementing world-class operations in both private and public sectors. He works from the frontline all the way to the executive level to drive process and mindset changes and to ensure sustained impact. Since joining McKinsey, Waqar has led several transformation efforts across different industries and topics and build strong client capabilities to ensure sustained impact.</p>
        <p>Examples of Waqar’s work include the following:</p>
        <ul>
          <li>optimizing operations of a multibillion-dollar insurance company to create additional capacity to absorb one and a half times growth at no additional cost</li>
          <li>helping a logistics company to rapidly reduce cost across a multi-site network in less than six months through a hands-on field and forum approach</li>
          <li>implementing lean principles at a top-tier utility company to improve its customer service operations</li>
          <li>helping a large B2B consumer goods company to reduce purchasing spend across multiple categories through learning and applying design-to-value techniques and supplier negotiations</li>
        </ul>
        <p>Prior to joining McKinsey, Waqar had experience in managing large operational sites and deploying continuous improvement initiatives as part of production and project management.</p>
      </>,
      name: 'Waqar Mian Mahmood',
      pic: PicHinna,
      place: 'Dubai',
      position: 'Expert Associate Partner'
    }
  },
  {
    id: 'hinna-khan',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Leads strategy and operations for Elixir in the Middle East.',
      fullDesc: <>
        <p>Hinna has experience in leading strategy and operations for various McKinsey practices across the Middle East, Turkey, Africa, and Russia. She has established and grown new capabilities for McKinsey’s Implementation and People & Organizational Performance Practices, among others. She has also helped shape growth strategies and product and knowledge development for McKinsey in the region.</p>
        <p>Prior to this role, Hinna worked with organizations across the Middle East to develop corporate transformation and sales force productivity turnaround, education reform strategy, negotiation strategies, and growth strategies.</p>
      </>,
      name: 'Hinna Khan',
      pic: PicHinna,
      place: 'Dubai',
      position: 'Director, Strategy & Operations, Elixir'
    }
  },
  {
    id: 'salman-younas',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Drives customer-oriented solutions to complex socioeconomic problems',
      fullDesc: <>
        <p>With more than 15 years of consulting experience, Salman has focused on BOT of several new government entities and units driving Saudi Arabia’s transformation and Vision 2030. His implementation work has entailed designing customer-oriented solutions and aligning multiple stakeholders to solve for complex socioeconomic challenges including government spend efficiency, job creation, healthcare transformation, and human resources development.</p>
        <p>Salman’s recent projects include the following:</p>
        <ul>
          <li>New ministerial unit: Developed methodology for job creation estimation, engaged more than 30 governmental entities, and reviewed more than 900 initiatives to understand their job creation impact.</li>
          <li>National health sector transformation strategy: Detailed terms of references for nine strategic pillars, set operating model for strategy detailing with relevant government entities, and led capability building program for agile organization of about 100 employees.</li>
          <li>Vision Strategy Management Office: Developed vision delivery manual for VRPs, VROs, and other government entities to effectively navigate requirements for strategy development, KPIs definition, initiative detailing, and budgeting.</li>
          <li>New government entity with spend efficiency mandate: Led strategy refresh exercise with CEO and leadership; built operating models and designed processes for three business functions and two support functions; and supported recruitment and organization buildup.</li>
        </ul>
      </>,
      name: 'Salman Younas',
      pic: PicSalman,
      place: 'Elixir Riyadh',
      position: 'Senior Vice President'
    }
  },
  {
    id: 'abdulla-ajam',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Supports the transformation of Saudi Arabia’s public sector',
      fullDesc: <>
        <p>Abdullah has more than 17 years of experience in city and municipal management, Hajj and Umrah, fast-moving consumer goods, manufacturing, and other sectors. Over the past five years, Abdulla has supported the transformation of Saudi Arabia’s public sector by designing the transformation of cities, building up strategy realization offices, supporting strategic planning for several entities, designing and executing transformational programs, and accelerating delivery for governmental initiatives.</p>
        <p>Examples of Abdulla’s work include the following:</p>
        <ul>
          <li>led transformation efforts for main city municipalities from strategy design to driving implementation</li>
          <li>led design and activation of the city destination management office, which orchestrates tourism and cultural hubs in one of the top touristic global cities</li>
          <li>worked on the design and implementation of several transformation workshops that supported government entities in preparing their strategies and aligning their execution plans with others</li>
          <li>developed an executive excellence model to help the center of government to direct its focus toward strategic goals</li>
        </ul>
        <p>Certified as a supply chain professional by APICS Association USA, Abdulla also holds PMP certification from the Project Management Institute.</p>
      </>,
      name: 'Abdulla Ajam',
      pic: PicAbdullah,
      place: 'Elixir Jeddah',
      position: 'Executive Vice President'
    }
  },
  {
    id: 'ayham-al-hamwi',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Leads large engagements to help clients diagnose challenges and ensure impact ',
      fullDesc: <>
        <p>Ayham is a seasoned consultant and a go-to-person for end-to-end organizational transformations and large-scale transformations delivery. He has an established track record in leading large engagements to help clients diagnose challenges, identify the best path to implementation, and use the right delivery engine to ensure impact realization.</p>
        <p>He has ten years of hands-on and leadership experience serving clients in the Vision 2030 ecosystem and Saudi public sector across a variety of business sectors and functions. A certified KPI professional, Ayham leads the firm’s work in strategy implementation as part of the Organizational Excellence Practice.</p>
        <p>Examples of Ayham’s work include the following:</p>
        <ul>
          <li>leading multiple cascading exercises to ensure smooth transitioning from strategy development to exaction across multiple large entities</li>
          <li>setting up and activating several offices withing public sector to uplift the visitor experience cross multiple key visitor hubs</li>
          <li>helping multiple public entities uplifting the organizational health culture to ensure successful organizational transformations</li>
        </ul>
      </>,
      name: 'Ayham Al-Hamwi',
      pic: PicAyham,
      place: 'Elixir Riyadh',
      position: 'Vice President'
    }
  },
  {
    id: 'hamzeh-al-rjoub',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Advises public-sector clients in managing the delivery of strategies aligned with Vision 2030',
      fullDesc: <>
        <p>Hamzeh has 15 years of experience in general management, transformation planning and delivery management, transformation management office setup, and activation based on the Build-Operate-Transfer concept (BOT), including EPMO. He advises public-sector clients in managing the delivery of strategies aligned with Vision 2030. He has also served the government across different levels and sectors, supporting entities, VRPs/VROs, ministries, and commissions and authorities.</p>
        <p>Examples of Hamzeh’s work include the following:</p>
        <ul>
          <li>leading transformation strategy implementation for the public sector in industries such as finance, sports, health, and technology</li>
          <li>leading BOT for delivery unit, transformation management office, KSA Vision Realization Office, and Vision Realization Program offices (VRPOs)</li>
          <li>leading initiatives delivery acceleration for national and entity transformations</li>
        </ul>
        <p>Hamzeh has multiple professional certificates, including PMP, Microsoft operation framework, ITIL, ISO20K, and ISO 27001.</p>
      </>,
      name: 'Hamzeh Al-Rjoub',
      pic: PicHamzeh,
      place: 'Elixir Jeddah',
      position: 'Senior Vice President'
    }
  },
  {
    id: 'mosab-sayyed',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Delivers impact through transformation programs and strategy implementation',
      fullDesc: <>
        <p>Mosab has more than 25 years of experience in management consulting in areas covering transformation programs, business models, operating models, strategy implementation, and technology implementation across a wide range of organizations—from e-commerce start-ups to government entities and large international companies.</p>
        <p>Among other projects, Mosab has led the setup and operation of PMOs and VROs in nine ministries or authorities in Saudi Arabia, including leading the delivery acceleration and development of initiatives and the various requirements from internal and external entities. He led the PMO for a large national program to perform the census in Saudi Arabia, and he has developed department policies and procedures and built team capabilities from recruitment to training.</p>
        <p>He also led the largest technology transformation program in the Kingdom’s national labor market, getting more than 48 programs ready to launch on the portal and overseeing several streams covering consortium management, change management, marketing communications, and training.</p>
      </>,
      name: 'Mosab Sayyed',
      pic: PicMosab,
      place: 'Elixir Riyadh',
      position: 'Senior Vice President'
    }
  },
  {
    id: 'tatal-al-barrak',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Drives financial and transformation projects to ensure profitability',
      fullDesc: <>
        <p>An accomplished and entrepreneurially minded professional with more than 12 years of experience with financial organizations, Talal possesses a valuable combination of financial acumen integrated with highly developed interpersonal skills and diversified experience in accounting, budgeting, and strategic planning. He has strong project and program management skills, with the ability to balance multiple priorities and generate innovative solutions to meet and exceed performance goals.</p>
        <p>Talal is an expert in financial planning and forecasting who effectively implemented an ERP system ascertaining systematic financial consolidation and reconciliation as well as accurate monthly reporting to a company’s headquarters. The system facilitated granular analytic capabilities by providing the same structure in the financial planning system and the accounting system, which resulted in more accurate assessment of company performance. </p>
        <p>He also has an in-depth knowledge of process management and continuous process and quality improvement projects. A dynamic leader who structures organizations to align with strategic goals and focuses on continuous improvement, he delivers increased efficiency and productivity while controlling costs. </p>
      </>,
      name: 'Talal Al Barrak',
      pic: PicTalal,
      place: 'Elixir Riyadh',
      position: 'Vice President'
    }
  },
  {
    id: 'ayman-tahboub',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Leads consulting projects in operational excellence and procurement',
      fullDesc: <>
        <p>A leading consultant in the field of operational excellence and procurement, Ayman works with clients in both the public and private sectors.</p>
        <p>In one recent engagement, he served as senior adviser on a public-sector spending improvement initiative. He led projects involving culture transfer and capacity building toward spending efficiency in government agencies, developed analytical tools to simplify measuring spending efficiency indicators, and developed a strategic and operational model for the central procurement unit in the public sector.</p>
        <p>He also served as a consultant on a spending optimization project that involved developing a strategic and operating model for a central public-sector procurement unit and creating a national initiative tracking system for cost-saving initiatives (from exploration to financial realization).</p>
      </>,
      name: 'Ayman Tahboub',
      pic: PicLeadership3,
      place: 'Elixir Riyadh',
      position: 'Vice President'
    }
  },
  {
    id: 'ahmad-aboudi',
    title: 'Our Leadership',
    intro: <>
      <p>We have deep experience in operations, implementation, and transformation.</p>
    </>,
    leader: {
      description: 'Delivers complex transformational programs in the public, private, and nongovernmental sectors',
      fullDesc: <>
        <p>Ahmad has more than 20 years of experience in project management, information technology, and digital transformation serving clients in Saudi Arabia and other countries in the Middle East.</p>
        <p>He has worked for major clients in the market, including ministries, municipalities, and banks. He has also served as a director in management consulting and technology with wide expertise in project management consulting services, PMO establishments, strategy execution, and technology and digital transformation.</p>
        <p>Among his recent projects, he established project management offices for a national ministry, several municipalities, and a university. An agile certified practitioner (ACP-PMI), he has both a PMP-PMI certificate and a PMOC certificate.</p>
      </>,
      name: 'Ahmad Aboudi',
      pic: PicAhmad,
      place: 'Elixir Riyadh',
      position: 'Vice President'
    }
  }
];

// Partners
export const partners = {
  title: 'Partners',
  intro: <>
    <p>In alignment with the values and mission of McKinsey, our values inform both our long-term strategies and the way we serve our clients.</p>
  </>,
  items: [
    {
      link: 'https://profiles.intranet.mckinsey.com/person/OTUzNjk=',
      title: 'Hinna Khan',
      info: 'Director, Strategy & Operations, Elixir, Dubai',
      position: {
        top: '6.25%',
        right: '25.38%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MTgxNjMw',
      title: 'Mosab Sayyed',
      info: 'Senior Vice President, Elixir Riyadh',
      position: {
        top: '30%',
        left: '-7.31%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MTU0NTEy',
      title: 'Abdulla Ajam',
      info: 'Executive Vice President, Elixir Jeddah',
      position: {
        top: '27.5%',
        left: '14.38%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MTU0OTA1',
      title: 'Hamzeh Al-Rjoub',
      info: 'Senior Vice President, Elixir Riyadh',
      position: {
        top: '35%',
        left: '42%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MTU0NTA0',
      title: 'Salman Younas',
      info: 'Senior Vice President, Elixir Riyadh',
      position: {
        top: '23.75%',
        right: '-7.54%'
      }
    },
    {
      link: '#',
      title: 'Ayman Tahboub',
      info: 'Vice President, Elixir Riyadh',
      position: {
        top: '51.88%',
        left: '-1.15%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MzEyNDY4',
      title: 'Ahmad Aboudi',
      info: 'Vice President, Elixir Riyadh',
      position: {
        top: '55%',
        left: '21.54%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/ODQ4NzU=',
      title: 'Dr. Imraan Munshi',
      info: 'Senior Partner, Dubai',
      position: {
        top: '67.5%',
        right: '35.77%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/NzEyMzY=',
      title: 'Waqar Mian Mahmood',
      info: 'Expert Associate Partner, Dubai',
      position: {
        top: '75%',
        right: '5%'
      }
    },
    {
      link: '#',
      title: 'Talal Al Barrak',
      info: 'Vice President, Elixir Riyadh',
      position: {
        top: '53.75%',
        right: '-7.85%'
      }
    },
    {
      link: 'https://profiles.intranet.mckinsey.com/person/MTc4ODk=',
      title: 'Robert E. Lewis',
      info: 'Senior Partner, Southern California',
      position: {
        top: '75%',
        left: '6.92%'
      }
    }
  ]
};