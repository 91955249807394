import { useState, useEffect } from 'react';

import { Button, Heading, PicBlock, Text, Icon } from '../';

import styles from './index.module.scss';

const Modal = ({ data: { title, fullDesc }, pic, open, setModalOpen, backUrl = "#" }) => {
  const [modalWindowOpen, setModalWindowOpen] = useState(false);
  const [modalClosing, setModalClosing] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModalClosing(true);
    setTimeout(() => {
      setModalWindowOpen(false);
      setModalOpen(false);
    }, 700);
  };

  useEffect(() => {
    setModalWindowOpen(true);
  }, []);

  return (
    <div className={`${styles.modal__wrapper} ${modalWindowOpen ? styles.open : ''} ${modalClosing ? styles.closing : ''}`}>
      <div className={styles.modal__block}>
        <div className={styles.modal__pic}>
          <PicBlock 
            customClass={styles['modal__pic-img']}
            image={pic}
            decoration="purple"
            gradient="purple"
          />
        </div>
        <div className={styles.modal__content}>
          <Heading
            decoration={{
              color: 'purple-100',
              width: '25px'
            }}
          >
            {title}
          </Heading>
          <Text size="22-25" customClass="mb-4-25">
            {fullDesc}
          </Text>
          <Button
            btnType="link"
            color="purple"
            href={backUrl}
            onClick={(e) => closeModal(e)}
            size="lg"
          >
            Back to our people
          </Button>
          <Button
            href={backUrl}
            btnType="icon-circle"
            icon={<Icon icon="close" />}
            color="purple"
            customClass={styles.modal__close}
            onClick={(e) => closeModal(e)}
          />
        </div>
      </div>
      <div className={styles.modal__backdrop}></div>
    </div>
  )
}

export default Modal