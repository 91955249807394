import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading, IconBlock, Text } from '../';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

const CarouselDefiningPillars = ({ slides }) => {
  return (
    <div className={styles['defining-pillars__carousel']}>
      <Swiper
        modules={[Navigation]}
        navigation
      >
        {slides.map(({icon, iconSize, title, content}, i) => (
          <SwiperSlide className={styles['defining-pillars__slide']} key={i}>
            <IconBlock
              circleSize="md"
              icon={icon}
              iconSize={iconSize}
            >
              <Heading
                Tag="h3"
                color="white"
              >
                {title}
              </Heading>
            </IconBlock>
            <div className={styles['defining-pillars__text']}>
              {content && (
                <Text maxWidth="85">{content}</Text>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CarouselDefiningPillars