import styles from './index.module.scss';

const ContactList = ({ items }) => {
  return (
    <div className={styles.contact__list}>
      {items && items.map(({type, label, value}, index) => (
        <div key={index} className={styles.contact__item}>
          <div className={styles.contact__label}>{label}</div>
          <div className={styles.contact__value}>
            {(type === 'tel' || type === 'mailto') && (
              <a href={`${type}:${value}`}>
                {value}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ContactList