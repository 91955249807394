import styles from './index.module.scss';

const Text = ({ align, color = 'white', size = '20-25', maxWidth, customClass, transform, letterSpacing, highlightColor, children }) => {
  return (
    <div 
      className={`text-component ${styles['text-component']} ${color} ${highlightColor ? 'highlight-'+highlightColor : ''} text-${size} ${maxWidth ? 'max-w-'+maxWidth : ''} ${align ? styles['align-'+align] : ''} ${transform ? styles['transform-'+transform] : ''} ${customClass ? customClass : ''}`}
      style={{
        letterSpacing
      }}
    >{children}</div>
  )
}

export default Text