import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Heading, Text } from '../../components';
import { Black, Hero } from '../../sections';
import { heroData, digitalDelivery } from '../../utils/digitalExcellence';

gsap.registerPlugin(ScrollTrigger);

const DigitalExcellence = () => {
  const digitalExcellenceRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, digitalExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Digital delivery section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.digital-delivery__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.digital-delivery__section .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.digital-delivery__section .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4);
      })
    }, digitalExcellenceRef);
    return () => ctx.revert();
  }, []);

  return (
    <>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
        >
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text 
              maxWidth="70"
              size="25-35"
            >
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      {digitalDelivery && (
        <div className="digital-delivery__section">
          <Black>
            {digitalDelivery?.title && (
              <Heading
                align="center"
                decoration
              >
                {digitalDelivery.title}
              </Heading>
            )}
            {digitalDelivery?.text && (
              <Text 
                align="center"
                maxWidth="50"
                size="22-25"
              >
                {digitalDelivery.text}
              </Text>
            )}
          </Black>
        </div>
      )}
    </>
  )
}

export default DigitalExcellence