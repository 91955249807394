import { useState, useRef, useEffect } from 'react';

import { Icon } from '../';
import { useStateContext } from '../../context/StateContextProvider';
import { languages } from '../../utils/data';

import styles from './index.module.scss';

const LanguagePicker = () => {
  const {currentLanguage, setCurrentLanguage} = useStateContext();
  const languagePicker = useRef(null);
  const [open, setOpen] = useState(false);

  const closeOnOutside = (e) => {
    if(languagePicker.current && open && !languagePicker.current.contains(e.target)){
      setOpen(false);
    }
  };

  const setLanguage = (lng) => {
    setCurrentLanguage(lng);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOnOutside);
  
    return () => {
      document.removeEventListener("mousedown", closeOnOutside);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagePicker, open]);

  return (
    <div className={styles['language-picker__block']} ref={languagePicker}>
      <div className={styles['language-picker__current']} onClick={() => setOpen(!open)}>
        <div className={styles['language-picker__icon']}>
          <Icon icon="web" />
        </div>
        <div className={styles['language-picker__title']}>
          {currentLanguage === '' ? 'Select Language' : languages[currentLanguage]?.nativeName}
        </div>
      </div>
      <div className={`${styles['language-picker__dropdown']} ${open ? styles.opened : ''}`}>
        {languages && Object.keys(languages).map((lng) => (
          <div 
            key={lng}
            className={styles['language-picker__item']} 
            onClick={() => setLanguage(lng)}
          >{languages[lng].nativeName}</div>
        ))}
      </div>
    </div>
  )
}

export default LanguagePicker