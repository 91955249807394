import { IconBlock } from '../';

import styles from './index.module.scss';

const ImpactItem = ({ icon, children }) => {
  return (
    <div className={styles.impact__item}>
      <div className={styles['impact__icon-wrap']}>
        <IconBlock 
          circleSize="xxl"
          circleColor="orange"
          icon={icon}
          iconColor="black"
          iconSize="md2"
          gap="0"
          customClass={styles.impact__icon}
        />
      </div>
      <div className={styles.impact__content}>
        {children}
      </div>
    </div>
  )
}

export default ImpactItem