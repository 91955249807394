import styles from './index.module.scss';

const PicAndContent = ({ pic, picRight, type, children }) => {
  return (
    <>
      <div 
        className={`${styles['pic-and-content__block']} ${picRight ? styles['pic--right'] : ''} ${type ? styles[type] : ''}`}
      >
        <div className={styles['pic-and-content__pic']}>
          <img src={pic} alt="" />
        </div>
        <div className={styles['pic-and-content__content']}>
          {children}
        </div>
      </div>
      {type === 'with-decoration' && (
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="romb-pic-and-content-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.001972, 0.001658)">
              <path d="M-0.139648 602.779L0.0211174 0.442546L205.056 0.441406C210.361 0.441376 215.448 2.54852 219.198 6.29927L500.367 287.468C508.178 295.278 508.178 307.942 500.367 315.752L219.198 596.921C215.448 600.672 210.361 602.779 205.056 602.779L0.0542135 602.779C0.0542135 602.779 -0.139648 592.787 -0.139648 602.779Z" fill="#FFC074"/>
            </clipPath>
            <clipPath id="romb-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.000975, 0.002439)">
              <path d="M6.46431 191.171L191.125 6.51041C194.875 2.75968 199.962 0.652547 205.267 0.652545L821.193 0.652344C826.498 0.652341 831.585 2.75947 835.335 6.5102L1020 191.171C1027.81 198.981 1027.81 211.644 1020 219.455L835.335 404.115C831.585 407.866 826.498 409.973 821.193 409.973L205.267 409.973C199.962 409.973 194.875 407.866 191.125 404.115L6.46431 219.455C-1.34617 211.644 -1.34618 198.981 6.46431 191.171Z" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  )
}

export default PicAndContent