import HeroBg from '../assets/img/bg-organizational-excellence.png';
import PicEstablishing from '../assets/img/pic-establishing-2.png';

// DATA: ORGANIZATIONAL EXCELLENCE

// Hero
export const heroData = {
  bg: HeroBg,
  title: 'Organizational Excellence',
  text: <>
    <p>Organizational excellence is crucial for achieving efficient and effective service  delivery. To reach excellence, organizations need to invest in employee training, develop streamlined processes, and implement effective operating models. Promoting a culture of continuous improvement, encouraging employee engagement, and fostering accountability and transparency can also contribute to building organizational capabilities and excellence.</p>
    <p>At Elixir, we take great pride in working alongside client team members and building sustainable capabilities within your organization so that you have the necessary skills and tools to conduct operations.</p>
  </>
};

// Organizational Excellence
export const orgExcellence = {
  title: 'Organizational Excellence',
  cols: [
    {
      title: 'Building Organizations',
      content: <>
        <p>We help you design purposeful operating models, build the right capabilities within your organization, and instill the right mindsets via change management activities. Our proven “build-operate-transfer” approach combines on-the- job-training with a vast global library of trainings to ensure the skills necessary for the operating model are well developed and practised in the organization.</p>
      </>,
    },
    {
      title: 'Transformation Delivery Acceleration',
      content: <>
        <p>In our years of serving clients in KSA, we’ve seen that effective executive offices and rapid response units can have a significant impact on an organization’s performance and health despite their small size. We specialize in setting up these units in entities suited for their unique needs, leveraging best practices and embedding McKinsey’s relevant expertise. We have helped several executives multiply their productivity and effectiveness via such units.</p>
      </>,
    }
  ]
};

// Services
export const services = {
  title: 'Recent Lighthouse Cases',
  cols: [
    {
      title: 'Detailing an operating model',
      content: <>
        <p>We redefined how a client organization operates through agile ways of working, with a focus on several elements: mandate, structure, interaction, reporting, people, and culture.</p>
      </>,
      link: '#',
      button: 'Learn more'
    },
    {
      title: 'Establishing satellite office teams',
      content: <>
        <p>We set up satellite offices to enable the government to adopt spending efficiency behaviors and realize savings through application of change management practices (influence model).</p>
      </>,
      link: '#',
      button: 'Learn more'
    },
    {
      title: 'Setting up an executive office',
      content: <>
        <p>We designed the governance and operating model of an executive office by first assessing the current state via observation and interviews, and then creating the desired structure.</p>
      </>,
      link: '#',
      button: 'Learn more'
    }
  ]
};

// Establishing VRO
export const establishingVRO = {
  pic: PicEstablishing,
  title: 'Establishing a VRO for a KSA ministry',
  intro: <>
    <p>We helped a Saudi ministry reestablish a VRO to align and follow up on the planning and implementation of its vision initiatives.</p>
  </>,
  cols: [
    {
      icon: 'handshake',
      title: 'Approach',
      list: [
        'Reestablish the VRO to operate at full capacity by adopting a “build-operate-transfer” (BOT) approach',
        'Manage the BOT system of the establishment PMO and redesign it to include the delivery team and governance model',
        'Build and develop the office governance, the operating model, and the projects and initiatives management manual',
        'Develop governance reports in areas including risk management, change management, project quality management, planning, and accreditation',
        'Supervise the selection of the appropriate technical system for managing initiatives, tracking their performance, supervising their preparation and testing, and supervising the design of reports and dashboards',
        'Manage critical files and provide operational support to all functions',
        'Build and develop assessment and testing models for applicants, conduct interviews, and submit reports',
        'Conduct on-the-job training and knowledge transfer, and develop training and introductory materials',
        'Support management of the VRO\'s projects and initiatives',
        'Build recruitment and qualification system, in addition to the incentives system',
      ]
    },
    {
      icon: 'web-spikelets',
      title: 'Impact',
      list: [
        'Fully functioning VRO that has attracted and upskilled 60 workers',
        'Seven core functions activated: Strategy and Performance Management, Program Governance, Project Delivery, Management of an Automated Performance Management System, Centralized Change and Communication Project Management System, Business Development, and Knowledge Management',
        'Automated performance management system, central project management system, and dashboards to track the progress of strategic objectives, which resulted in the ministry meeting the majority of its key performance targets',
        '20 strategic projects implemented by the end of 2017',
      ]
    }
  ]
};

// Operating ministrys
export const operatingMinistrys = {
  title: 'Establishing and operating a ministry’s Delivery and Rapid Intervention Center',
  intro: <>
    <p>We helped a critical KSA ministry establish a Delivery and Rapid Intervention Center to develop and implement the strategies and initiatives of a major vision program.</p>
  </>,
  cols: [
    {
      icon: 'target',
      title: 'Approach',
      list: [
        'Establish and operate a Delivery and Rapid Intervention Center that is responsible for one of the major VRPs within Vision 2030',
        'Design, launch, and operate detailed workshops to enable more than 30 government entities to develop comprehensive, consistent, and aligned transformation plans over a period of eight weeks',
        'Align plans for 500+ initiatives, estimate five-year budgets, and create platforms for launching several different centers of excellence',
        'Develop a mechanism to prioritize the budget allocated to all entities',
        'Reshape the government ecosystem by designing and establishing Vision Realization Offices across CEDA entities, ensuring initiatives are delivered with the highest quality',
        'Managed workshops to ensure progress, including budget workshops and private-sector labs.',
      ]
    },
    {
      icon: 'chart-monitor',
      title: 'Impact',
      list: [
        'Building and operating the Delivery and Rapid Intervention Center, the main entity of one of the major VRPs within Vision 2030',
        'Contributing to the design and establishment of VROs in all CEDA entities to ensure high standards of implementation',
        'Enabling more than 30 government entities to develop comprehensive, consistent, and aligned transformation plans',
      ]
    }
  ]
};

// Operating government
export const operatingGovernment = {
  title: 'Establishing and operating a government center for spending efficiency',
  intro: <>
    <p>We established a central management and control room for Phase II initiatives in the Private Sector Stimulus Plan Office in order to accelerate implementation by initiative owners. We also provided recommendations on accelerating the implementation and launch processes for the private</p>
  </>
};

// Approach
export const approach = {
  title: 'Approach',
  icon: 'chess',
  list: [
    'Build the operational structure of a government center of spending efficiency and communication plans, and support budget reviews',
    {
      item: 'Develop the operational structure of the Center of Spending Efficiency to include three main units:',
      list: [
        'Institutional Excellence Unit', 'Strategic Procurement Unit', 'Capital Expenditure Unit'
      ]
    },
    'Develop the governance of VRO and the map of relevant stakeholders from various government entities',
    'Support the development of a new communication plan for the center and launched the new logo',
    'Build and develop a database for the center to use in benchmarking capital and operational spending',
  ]
};

// Impact
export const impact = {
  title: 'Impact',
  icon: 'chart-monitor',
  intro: <>
    <p>Funding for priority initiatives with a total value of almost SAR 700 million</p>
  </>,
  list: [
    'Establishing rapid intervention teams in 15 government entities to pilot and implement initiatives to improve the efficiency of operational spending, prioritize necessary projects, apply valuable engineering solutions, and involve the private sector in capital spending',
    'Developing the governance of VRO and the map of relevant stakeholders from various government entities'
  ]
};