import { Heading, IconBlock } from '../';

import styles from './index.module.scss';

const HeaderDecorated = ({ color, title, icon, circleColor = "blue-700" }) => {
  return (
    <div className={styles['header-decorated__block']}>
      <Heading
        color={color}
        Tag="h2"
        align="center"
        view="h3"
      >
        {title}
      </Heading>
      <IconBlock 
        circleSize="xl"
        circleColor={circleColor}
        icon={icon}
        iconSize="md"
        customClass={styles['header-decorated__icon']}
      />
    </div>
  )
}

export default HeaderDecorated