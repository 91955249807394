import { NavLink } from 'react-router-dom';

import styles from './index.module.scss';

const Navigation = ({ data, type }) => {
  return (
    <nav className={styles[type+'__nav']}>
      {data && data.map(({ href, title }, i) => (
        <NavLink className={({isActive}) => isActive ? styles.active : ''} key={i} to={href}>{title}</NavLink>
      ))}
    </nav>
  )
}

export default Navigation