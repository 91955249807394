import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Accordion, CircleDecoration, Grid, Heading, List, SuccessStories, Text } from '../../components';
import { Black, Hero } from '../../sections';
import { heroData, ourWorks, ourSuccessStories, sectors } from '../../utils/ourWork';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const OurWork = () => {
  const ourWorkRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] [class*="hero__logo"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.5);

        document.querySelectorAll('[class*="hero__section"] [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0.5 + index / 2);
        });
      })
    }, ourWorkRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Accordion section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="accordion__block"]`,
            start: 'center bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll('[class*="accordion__block"] > div').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            y: '-5vh',
            duration: 1
          }, 0 + index / 3);
        });
      })
    }, ourWorkRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Our success stories section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.our-works__success-stories__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.our-works__success-stories__section', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.our-works__success-stories__section > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('.our-works__success-stories__section > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from('.our-works__success-stories__section [class*="success-stories__block"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.5);
      })
    }, ourWorkRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.our-work-black__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, ourWorkRef);
    return () => ctx.revert();
  }, []);



  useLayoutEffect(() => {
    // Sectors section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.sectors__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.sectors__section', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.sectors__section > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('.sectors__section > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1);

        document.querySelectorAll('.sectors__section .list-component').forEach((item, index) => {
          stl.from(item, {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 1.2 + index / 2);
        });
      })
    }, ourWorkRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={ourWorkRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
        >
          {heroData?.logo && (
            <img 
              className="hero__logo"
              src={heroData.logo} 
              alt="Elixir" 
            />
          )}
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text size="25-35">
              {heroData.text}
            </Text>
          )}

          <CircleDecoration 
            height="55vh"
            position={{
              top: '-100px',
              left: '10%'
            }}
          />
          <CircleDecoration 
            height="55vh"
            position={{
              top: '100px',
              right: '15%'
            }}
          />
          <CircleDecoration 
            height="55vh"
            position={{
              top: '70px',
              right: '0%'
            }}
          />
        </Hero>
      )}

      {(ourWorks || ourSuccessStories || sectors) && (
        <div className="our-work-black__section">
          <Black overflow="hidden">
            {ourWorks && (
              <div className={styles['our-works__block']}>
                <Accordion items={ourWorks} />
              </div>
            )}

            {ourSuccessStories && (
              <div className={`our-works__success-stories__section ${styles['our-works__block']}`}>
                {ourSuccessStories?.title && (
                  <Heading
                    align="center"
                    decoration
                  >
                    {ourSuccessStories.title}
                  </Heading>
                )}
                {ourSuccessStories?.intro && (
                  <Text
                    align="center"
                    color="white"
                    maxWidth="50"
                  >
                    {ourSuccessStories.intro}
                  </Text>
                )}
                {ourSuccessStories?.labels && ourSuccessStories?.scheme && ourSuccessStories?.tabs && (
                  <SuccessStories 
                    labels={ourSuccessStories.labels}
                    scheme={ourSuccessStories.scheme}
                    tabs={ourSuccessStories.tabs}
                  />
                )}
              </div>
            )}

            {sectors && (
              <div className={`sectors__section ${styles['our-works__block']}`}>
                {sectors?.title && (
                  <Heading 
                    decoration
                    maxWidth="85"
                  >
                    {sectors.title}
                  </Heading>
                )}
                {sectors?.intro && (
                  <Text 
                    color="white"
                    size="22-25"
                    maxWidth="50"
                  >
                    {sectors.intro}
                  </Text>
                )}
                {sectors?.cols && sectors.cols.length > 0 && (
                  <Grid 
                    cols="2"
                    gap="lg"
                    margin="top-md"
                  >
                    {sectors.cols.map((item, index) => (
                      <React.Fragment key={index}>
                        {item?.list && (
                          <List 
                            items={item.list}
                            fontSize="20-25"
                            padding="pl-55"
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
              </div>
            )}

            <CircleDecoration 
              height="70vh"
              position={{
                top: '-22vh',
                left: '-7%'
              }}
              rotate="-21"
              width="22vw"
            />
            <CircleDecoration 
              height="38vh"
              position={{
                top: '44vh',
                right: '19vw'
              }}
              width="21vw"
            />
            <CircleDecoration 
              height="93vh"
              position={{
                top: '11vh',
                right: '-11vw'
              }}
              rotate="13"
              width="26vw"
            />
          </Black>
        </div>
      )}
    </div>
  )
}

export default OurWork