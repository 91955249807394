import styles from './index.module.scss';

const CircleDecoration = ({ position, blur = '175px', color = 'rgba(96, 119, 218, 0.15)', height = '59%', width = '27%', rotate }) => {
  return (
    <i 
      className={styles['circle-decoration']}
      style={{
        background: color,
        filter: `blur(${blur})`,
        height,
        top: position?.top,
        right: position?.right,
        bottom: position?.bottom,
        left: position?.left,
        transform: `rotate(${rotate}deg)`,
        width
      }}
    ></i>
  )
}

export default CircleDecoration