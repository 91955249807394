import { IconBlock, Heading, Text } from '../../components';

import styles from './index.module.scss';

const InterviewProcessItem = ({ data: { icon, title, content } }) => {
  return (
    <div className={styles['interview-process__item']}>
      <div className={styles['interview-process__inner']}>
        <IconBlock
          circleColor="blue-700"
          circleSize="xxl"
          icon={icon}
          iconSize="lg"
          customClass={styles['interview-process__icon']}
        />
        <Heading
          Tag="h3"
          align="center"
          decoration
          view="h5"
        >
          {title}
        </Heading>
        <Text 
          align="center"
          size="18-24"
        >
          {content}
        </Text>
      </div>
    </div>
  )
}

export default InterviewProcessItem