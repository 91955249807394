import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, CarouselDefiningPillars, CarouselHero, CircleDecoration, Heading, Icon, PicAndContent, Text } from '../../components';
import { Black, White } from '../../sections';
import { heroCarousel, whatWeDo, howWeWork, ourDefiningPillars } from '../../utils/homepage';

gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const homePage = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__carousel"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__carousel"] [class*="hero__logo"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__carousel"] .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from('[class*="hero__carousel"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8)
        .from('[class*="hero__carousel"] [class*="hero__nav-item"][class*="--prev"]', {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8)
        .from('[class*="hero__carousel"] [class*="hero__nav-item"]:not([class*="--prev"])', {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8)
        .from('[class*="hero__carousel"] [class*="swiper-pagination"]', {
          y: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, homePage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // What we do section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="what-we-do__section"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="what-we-do__section"] .layout > .heading-component', {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        document.querySelectorAll('[class*="pic-and-content__block"]').forEach((item) => {
          let picTl = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top+=25% bottom',
              end: 'bottom top',
            }
          });
    
          if(item.getAttribute('class').indexOf('pic--right') !== -1){
            picTl.from(item.querySelector('[class*="pic-and-content__pic"]'), {
              x: '10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 0)
            .from(item.querySelector('[class*="pic-and-content__content"]'), {
              x: '10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, .3);
          } else {
            picTl.from(item.querySelector('[class*="pic-and-content__pic"]'), {
              x: '-10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, 0)
            .from(item.querySelector('[class*="pic-and-content__content"]'), {
              x: '-10vh',
              opacity: 0,
              ease: 'power2',
              duration: 1
            }, .3);
          }
        })
      })
    }, homePage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Our defining pillars
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="our-defining-pillars__section"]',
            start: 'center-=10% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="our-defining-pillars__section"] .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="our-defining-pillars__section"] .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.25)
        .from('[class*="our-defining-pillars__section"] .our-defining-pillars__block', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 2.5
        }, 0.45);

        document.querySelectorAll('[class*="our-defining-pillars__section"] [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0 + index);
        })
      })
    }, homePage);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={homePage}>
      {heroCarousel && (
        <CarouselHero />
      )}

      {(whatWeDo || howWeWork) && (
        <White 
          customClass="what-we-do__section"
          overflow="hidden"
        >
          {whatWeDo?.title && (
            <Heading
              color="black"
              decoration={{
                color: 'purple-100',
                width: '50px'
              }}
            >
              {whatWeDo.title}
            </Heading>
          )}
          {whatWeDo?.pic && whatWeDo?.content && (
            <PicAndContent
              pic={whatWeDo.pic}
            >
              {whatWeDo.contentTitle && (
                <Heading
                  Tag="h3"
                  color="black"
                  decoration={{
                    color: 'purple-100',
                    width: '35px'
                  }}
                >
                  {whatWeDo.contentTitle}
                </Heading>
              )}
              <Text 
                color="black"
                maxWidth="85"
              >
                {whatWeDo.content}
              </Text>
              {whatWeDo.link && (
                <div className="button-wrapper align-end">
                  <Button
                    btnType="link-icon"
                    color="black-purple"
                    icon={<Icon icon='angle--right'/>}
                    href={whatWeDo.link.href}
                    type="link"
                  >
                    {whatWeDo.link.text}
                  </Button>
                </div>
              )}
            </PicAndContent>
          )}

          {howWeWork?.pic && howWeWork?.content && (
            <PicAndContent
              picRight={true}
              pic={howWeWork.pic}
            >
              {howWeWork?.title && (
                <Heading
                  color="black"
                  decoration={{
                    color: 'purple-100',
                    width: '35px'
                  }}
                >
                  {howWeWork.title}
                </Heading>
              )}
              <Text color="black">
                {howWeWork.content}
              </Text>
              {howWeWork.link && (
                <div className="button-wrapper">
                  <Button
                    btnType="border"
                    color="orange"
                    href={howWeWork.link.href}
                    type="link"
                  >
                    {howWeWork.link.text}
                  </Button>
                </div>
              )}
            </PicAndContent>
          )}
        </White>
      )}

      {ourDefiningPillars && (
        <Black
          customClass="our-defining-pillars__section"
          overflow="hidden"
        >
          {ourDefiningPillars?.title && (
            <Heading
              align="center"
              decoration={true}
            >
              {ourDefiningPillars.title}
            </Heading>
          )}
          {ourDefiningPillars?.intro && (
            <Text
              align="center"
              maxWidth="50"
              size="25-35"
            >
              {ourDefiningPillars.intro}
            </Text>
          )}
          {(ourDefiningPillars?.pic || ourDefiningPillars?.carousel) && (
            <div className="our-defining-pillars__block">
              {ourDefiningPillars?.pic && (
                <div className="our-defining-pillars__pic">
                  <img src={ourDefiningPillars.pic} alt="" />
                </div>
              )}
              {ourDefiningPillars?.carousel && ourDefiningPillars.carousel.length > 0 && (
                <div className="our-defining-pillars__carousel">
                  <CarouselDefiningPillars slides={ourDefiningPillars.carousel} />
                </div>
              )}
            </div>
          )}
          <CircleDecoration 
            height="90%"
            position={{
              top: '-9%',
              left: '-2%'
            }}
            rotate="21"
            width="26%"
          />
          <CircleDecoration 
            height="90%"
            position={{
              top: '3%',
              left: '-5.7%'
            }}
            rotate="21"
            width="26%"
          />
          <CircleDecoration 
            color="rgba(96, 119, 218, 0.3)"
            height="108%"
            position={{
              right: '-4.6%',
              bottom: '-48%'
            }}
            rotate="51"
            width="31%"
          />
        </Black>
      )}
    </div>
  )
}

export default Homepage