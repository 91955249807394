import HeroBg from '../assets/img/bg-why-work-at-elixir.png';
import LogoText from '../assets/svg/logo-text.svg';
import JoinUsBg from '../assets/img/bg-join-us.png';
import Person from '../assets/img/pic-person.png';
import PicMeetAhmed from '../assets/img/pic-meet-ahmed.png';
import PicMeetDahlia from '../assets/img/pic-meet-dahlia.png';
import PicMeetFaisal from '../assets/img/pic-meet-faisal.png';
import PicMeetAhmed2 from '../assets/img/pic-meet-ahmed-2.png';

// DATA: CAREERS

// Hero
export const heroData = {
  bg: HeroBg,
  logo: LogoText,
  title: 'Why work at Elixir?',
  text: <>
    <p>At Elixir, we invest in your growth and development. You’ll create scalable impact, work with local and global experts, and join an inclusive workplace where everyone belongs.</p>
  </>
};

// Types of project
export const typesOfProject = [
  {
    id: '01',
    title: 'What types of projects will I work on?',
    intro: <>
      <p>You’ll work on a diverse array of projects that have regional and national impact. Our clients range from private organizations to high-profile public entities</p>
    </>,
    button: {
      text: 'Learn more',
      link: '#'
    }
  },
  {
    id: '02',
    title: 'Who will I work with?',
    intro: <>
      <p>Our team members are global experts in implementation, delivery assurance, and strategic transformation. In our collaborative culture, you’ll learn from some of today’s top thought leaders.</p>
    </>,
    button: {
      text: 'Learn more',
      link: '#'
    }
  },
  {
    id: '03',
    title: 'How will Elixir support my professional development?',
    intro: <>
      <p>We prioritize your learning with tailored career-development pathways supported by solid onboarding, mentoring, and on-the-job coaching. From professional certifications to McKinsey training, we provide the tools you need to advance.</p>
    </>,
    button: {
      text: 'Learn more',
      link: '#'
    }
  }
];

// What are we looking for
export const whatWeLookingFor = {
  title: 'What are we looking for?',
  intro: <>
    <p>In evaluating job applications, we’re looking to hire candidates with strong skills in three key areas</p>
  </>,
  cols: [
    {
      title: 'Problem solving',
      content: <>
        <p>We help clients solve tough problems in their businesses and implement solutions to make true impact. This requires not only strong intellectual ability and rigor but also a good practical sense of what works and what doesn’t in complex organizations and government bodies.</p>
      </>
    },
    {
      title: 'Personal impact',
      content: <>
        <p>We work with a wide range of individuals in our client engagements, and we need their support and collaboration to develop and implement the best recommendations. Strong interpersonal skills are critical to driving client impact.</p>
      </>
    },
    {
      title: 'Leadership',
      content: <>
        <p>The ability to lead and foster effective teamwork is key to success in driving positive change in complex organizations and government bodies. Elixir consultants are well-rounded individuals who have a good mix of these skills and are fun to work with. We look for the same mix of skills when we interview you!</p>
      </>
    }
  ]
};

export const joinUs = [
  {
    pic: JoinUsBg,
    title: 'Join us',
    intro: <>
      <p>We invest a lot of time and effort to find the candidate who best matches our purpose and culture. We are all focused on pursuing a common vision and mission. We all believe in and live by our common values. Therefore, our assessment process is designed for us to get to know you professionally and personally. It is also designed for you to get to know us.</p>
    </>,
    job: {
      icon: 'course',
      title: 'Associate partner or partner',
      text: <>
        <p>You will lead multiple teams of consultants and clients and will have ownership of the end-to-end delivery of projects, ensuring they meet business objectives.</p>
      </>,
      button: {
        title: 'Learn more',
        link: '/careers/associate-partner-or-partner'
      }
    }
  },
  {
    pic: JoinUsBg,
    title: 'Join us',
    intro: <>
      <p>We invest a lot of time and effort to find the candidate who best matches our purpose and culture. We are all focused on pursuing a common vision and mission. We all believe in and live by our common values. Therefore, our assessment process is designed for us to get to know you professionally and personally. It is also designed for you to get to know us.</p>
    </>,
    job: {
      icon: 'course',
      title: 'Senior manager',
      text: <>
        <p>You will be the on-ground leader for individual client projects</p>
      </>,
      button: {
        title: 'Learn more',
        link: '/careers/senior-manager'
      }
    }
  },
  {
    pic: JoinUsBg,
    title: 'Join us',
    intro: <>
      <p>We invest a lot of time and effort to find the candidate who best matches our purpose and culture. We are all focused on pursuing a common vision and mission. We all believe in and live by our common values. Therefore, our assessment process is designed for us to get to know you professionally and personally. It is also designed for you to get to know us.</p>
    </>,
    job: {
      icon: 'course',
      title: 'Manager',
      text: <>
        <p>You will be responsible for the successful delivery of individual project workstreams by working closely with clients and small teams.</p>
      </>,
      button: {
        title: 'Learn more',
        link: '/careers/manager'
      }
    }
  },
  {
    pic: JoinUsBg,
    title: 'Join us',
    intro: <>
      <p>We invest a lot of time and effort to find the candidate who best matches our purpose and culture. We are all focused on pursuing a common vision and mission. We all believe in and live by our common values. Therefore, our assessment process is designed for us to get to know you professionally and personally. It is also designed for you to get to know us.</p>
    </>,
    job: {
      icon: 'course',
      title: 'Associate consultant',
      text: <>
        <p>You will work in small teams with colleagues and clients to build the strategies, capabilities, systems, and processes needed to deliver bottom-line results and ensure those results will be sustained.</p>
      </>,
      button: {
        title: 'Learn more',
        link: '/careers/associate-consultant'
      }
    }
  },
  {
    pic: JoinUsBg,
    title: 'Join us',
    intro: <>
      <p>We invest a lot of time and effort to find the candidate who best matches our purpose and culture. We are all focused on pursuing a common vision and mission. We all believe in and live by our common values. Therefore, our assessment process is designed for us to get to know you professionally and personally. It is also designed for you to get to know us.</p>
    </>,
    job: {
      icon: 'course',
      title: 'Business analyst',
      text: <>
        <p>You will work in teams to help clients build capabilities in delivery and execution, both during active projects and continuing after our consultants have shared their recommendations.</p>
      </>,
      button: {
        title: 'Learn more',
        link: '/careers/analyst'
      }
    }
  }
];

export const teamBlock = {
  title: 'Lorem Ipsum is simply dummy text',
  intro: <>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, w</p>
  </>,
  members: [
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    },
    {
      pic: Person,
      title: 'Mark Smith',
      text: 'Lorem Ipsum is simply dummy'
    }
  ]
};

export const interviewProcess = {
  title: 'What does the interview process look like?',
  steps: [
    {
      icon: 'path',
      title: 'Coaching',
      content: <>
        <p>We want you to be successful. That’s why we support your interview preparation through coaching buddies, virtual group coaching sessions, and even campus workshops.</p>
      </>
    },
    {
      icon: 'care',
      title: 'Interview',
      content: <>
        <p>Our typical interview consists of two parts: a problem-solving exercise and a “fit” interview. Both parts hold equal weight in decision making. (The process can vary slightly depending on the pathway and position.)</p>
      </>
    },
    {
      icon: 'burger',
      title: 'Offer',
      content: <>
        <p>Congratulations! Once we have extended an offer, we guide you toward your next step as an Elixirian. We look forward to welcoming you to our Elixir family!</p>
      </>
    }
  ]
};

export const meetPeople = {
  title: 'Meet our people',
  intro: <>
    <p>Get to know some of the team members who make Elixir an outstanding place to work, learn, and grow</p>
  </>,
  people: [
    {
      pic: PicMeetAhmed,
      quote: 'At Elixir, our goal is to help both clients and coworkers meet their needs. This is embedded in our DNA.',
      modalId: 'meet-ahmed',
    },
    {
      pic: PicMeetDahlia,
      quote: 'Elixir has provided me the opportunity to grow both personally and professionally.',
      modalId: 'meet-dalia',
    },
    {
      pic: PicMeetFaisal,
      quote: 'I was able to learn new best practices and techniques from experts in Elixir eager to help me advance in my career.',
      modalId: 'meet-faisal',
    },
    {
      pic: PicMeetAhmed2,
      quote: 'I truly appreciate the continuous learning and development at Elixir, and this is something I try to provide my team members by giving them regular feedback and coaching.',
      modalId: 'meet-ahmed-2',
    }
  ]
};

export const contactUs = {
  title: 'Contact us',
  intro: <>
    <p>Thank you for your interest in Elixir. To learn more about our work, please reach out to us by phone or email, or stop by one of our offices. </p>
  </>,
  contacts: {
    mailAddress: 'Mailing address: P.O. Box 11263',
    address: 'Jeddah 21453 Saudi Arabia',
    contactList: [
      {
        type: 'tel',
        label: 'Phone',
        value: '+96 612 263 17 20'
      },
      {
        type: 'tel',
        label: 'FAX',
        value: '+96 612 263 17 30'
      },
      {
        type: 'mailto',
        label: 'Email',
        value: 'info@elixir.sa'
      }
    ]
  }
};