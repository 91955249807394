import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, CircleDecoration, ContentBlock, Grid, Heading, IconBlock, List, PicBlock, Text } from '../../components';
import { Black, Hero, White } from '../../sections';
import { heroData, transformativeResults, exploreStories, creatingTransparency, uniquePerformance, approach, approachSteps } from '../../utils/processOptimization';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const ProcessOptimization = () => {
  const processOptimizationRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Hero section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Transformative results section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="transformative-results__section"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="transformative-results__section"] .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="transformative-results__section"] .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from('[class*="transformative-results__section"] [class*="process-optimization__icons"]', {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 0.7);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Explore stories section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['process-optimization__explore-stories']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['explore-stories__pic']} `, {
          x: '20vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['process-optimization__explore-stories']} .explore-stories__heading`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['explore-stories__icons']}`, {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 0.7);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.explore-transparency-unique__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Creating transparency section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['process-optimization__creating-transparency']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['process-optimization__creating-transparency']}`, {
          opacity: 0,
          ease: 'power2',
          duration: 2
        }, 0)
        .from(`.${styles['process-optimization__creating-transparency']} [class*='process-optimization__border']`, {
          opacity: 0,
          width: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['process-optimization__creating-transparency']} .process-optimization__heading`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles['process-optimization__creating-transparency']} .process-optimization__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.7)
        .from(`.${styles['process-optimization__creating-transparency']} .${styles['creating-transparency__list']}`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from(`.${styles['process-optimization__creating-transparency']} .${styles['process-optimization__counters']}`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.2);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Unique performance section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.process-optimization__unique-performance`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.process-optimization__unique-performance`, {
          opacity: 0,
          ease: 'power2',
          duration: 2
        }, 0)
        .from(`.process-optimization__unique-performance [class*='process-optimization__border']`, {
          opacity: 0,
          width: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.process-optimization__unique-performance .process-optimization__heading`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.process-optimization__unique-performance .process-optimization__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.7)
        .from(`.process-optimization__unique-performance .${styles['process-optimization__counters']}`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Approach section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.approach__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.approach__section .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.approach__section .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3);
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTimeout(() => {
        // Approach section
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.approach-steps__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll(`.approach-steps__section .${styles['approach-block__item']}`).forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            y: '-5vh',
            duration: 2
          }, 0 + index / 3);
        });

        document.querySelectorAll('.approach-steps__section [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, processOptimizationRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={processOptimizationRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
        >
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text size="25-35">
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      <div className="overflow-hidden">
        {transformativeResults && (
          <White customClass="transformative-results__section">
            {transformativeResults?.title && (
              <Heading
                color="black"
                decoration={{
                  color: "purple-100"
                }}
              >
                {transformativeResults.title}
              </Heading>
            )}
            {transformativeResults?.intro && (
              <Text 
                color="black"
                size="25-30"
                maxWidth="75"
              >
                {transformativeResults.intro}
              </Text>
            )}
            {transformativeResults?.items && (
              <div className={styles['process-optimization__icons']}>
                {transformativeResults.items.map(({ icon, title, text }, index) => (
                  <IconBlock
                    iconSize="md2"
                    icon={icon}
                    gap="60"
                    key={index}
                  >
                    {title && (
                      <Heading
                        Tag="h3"
                        color="purple-100"
                        spacing="10"
                      >
                        {title}
                      </Heading>
                    )}
                    {text && (
                      <Text color="black">
                        {text}
                      </Text>
                    )}
                  </IconBlock>
                ))}
              </div>
            )}
          </White>
        )}

        {(exploreStories || creatingTransparency || uniquePerformance) && (
          <div className="explore-transparency-unique__section">
            <Black noLayout>
              {exploreStories && (
                <div className={`${styles['process-optimization__explore-stories']} ${styles['process-optimization__black']}`}>
                  {exploreStories?.pic && (
                    <div className={styles['explore-stories__pic']}>
                      <PicBlock
                        image={exploreStories.pic}
                        gradient="purple"
                        type="romb-right-lg"
                      />
                    </div>
                  )}
                  <div className="layout">
                    <div className={styles['explore-stories__content']}>
                      {exploreStories?.title && (
                        <div className="explore-stories__heading">
                          <Heading 
                            color="orange"
                            transform="uppercase"
                            letterSpacing="md"
                            spacing="0"
                            view="h3"
                          >
                            {exploreStories.title}
                          </Heading>
                        </div>
                      )}
                      {exploreStories?.items && (
                        <div className={styles['explore-stories__icons']}>
                          {exploreStories.items.map(({ icon, title, text, link, button }, index) => (
                            <IconBlock
                              circleColor="grey-650"
                              icon={icon}
                              key={index}
                              gap="60"
                              iconSize="md"
                            >
                              <div className={styles['explore-stories__icon-block']}>
                                {(title || text) && (
                                  <div className={styles['icon-block__left']}>
                                    {title && (
                                      <Heading
                                        Tag="h3"
                                        color="orange"
                                        view="h5"
                                      >
                                        {title}
                                      </Heading>
                                    )}
                                    {text && (
                                      <Text>
                                        {text}
                                      </Text>
                                    )}
                                  </div>
                                )}
                                {(button || link) && (
                                  <div className={styles['icon-block__right']}>
                                    <Button
                                      type="link"
                                      href={link}
                                      btnType="link"
                                      color="orange"
                                      size="m2"
                                    >
                                      {button}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </IconBlock>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {creatingTransparency && (
                <div className={`${styles['process-optimization__creating-transparency']} ${styles['process-optimization__black']}`}>
                  {creatingTransparency?.bg && (
                    <div className={styles['process-optimization__bg']}>
                      <img src={creatingTransparency.bg} alt="" />
                    </div>
                  )}
                  <div className="layout">
                    <i className={styles['process-optimization__border']}></i>
                    {creatingTransparency?.title && (
                      <div className="process-optimization__heading">
                        <Heading decoration>
                          {creatingTransparency.title}
                        </Heading>
                      </div>
                    )}
                    {creatingTransparency?.text && (
                      <div className="process-optimization__text">
                        <Text
                          size="22-25"
                          maxWidth="65"
                        >
                          {creatingTransparency.text}
                        </Text>
                      </div>
                    )}
                    {creatingTransparency?.list && (
                      <div className={styles['creating-transparency__list']}>
                        <List 
                          items={creatingTransparency.list}
                        />
                      </div>
                    )}
                    {creatingTransparency?.counters && (
                      <div className={styles['process-optimization__counters']}>
                        {creatingTransparency.counters.map(({ counter, title, text }, index) => (
                          <ContentBlock 
                            key={index}
                            padding="m"
                            customClass={styles['process-optimization__counter']}
                          >
                            {counter && (
                              <Text
                                size="90-90"
                              >
                                {counter}
                              </Text>
                            )}
                            {title && (
                              <Heading
                                Tag="h3"
                                color="orange"
                                view="h4"
                                spacing="0"
                              >
                                {title}
                              </Heading>
                            )}
                            {text && (
                              <Text>
                                {text}
                              </Text>
                            )}
                          </ContentBlock>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {uniquePerformance && (
                <div className={`process-optimization__unique-performance ${styles['process-optimization__black']}`}>
                  {uniquePerformance?.bg && (
                    <div className={styles['process-optimization__bg']}>
                      <img src={uniquePerformance.bg} alt="" />
                    </div>
                  )}
                  <div className="layout">
                    <i className={styles['process-optimization__border']}></i>
                    {uniquePerformance?.title && (
                      <div className="process-optimization__heading">
                        <Heading decoration>
                          {uniquePerformance.title}
                        </Heading>
                      </div>
                    )}
                    {uniquePerformance?.intro && (
                      <div className="process-optimization__text">
                        <Text
                          size="22-25"
                          maxWidth="65"
                        >
                          {uniquePerformance.intro}
                        </Text>
                      </div>
                    )}
                    {uniquePerformance?.counters && (
                      <div className={styles['process-optimization__counters']}>
                        {uniquePerformance.counters.map(({ counter, title, text }, index) => (
                          <ContentBlock 
                            key={index}
                            padding="m"
                            customClass={styles['process-optimization__counter']}
                          >
                            {counter && (
                              <Text
                                size="90-90"
                              >
                                {counter}
                              </Text>
                            )}
                            {title && (
                              <Heading
                                Tag="h3"
                                color="orange"
                                view="h4"
                                spacing="0"
                              >
                                {title}
                              </Heading>
                            )}
                            {text && (
                              <Text>
                                {text}
                              </Text>
                            )}
                          </ContentBlock>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <CircleDecoration 
                height="90vh"
                position={{
                  top: '-39vh',
                  left: '-10vw'
                }}
                rotate="-26"
                width="27vw"
              />
              <CircleDecoration 
                height="90vh"
                position={{
                  top: '-36vh',
                  left: '12vw'
                }}
                rotate="24"
                width="27vw"
              />
            </Black>
          </div>
        )}
      </div>

      {approach && (
        <div className="approach__section">
          <White padding="xs">
            {approach?.title && (
              <Heading
                color="black"
                decoration
              >
                {approach.title}
              </Heading>
            )}
            {approach?.intro && (
              <Text
                color="black"
                size="22-25"
                maxWidth="65"
              >
                {approach.intro}
              </Text>
            )}
          </White>
        </div>
      )}

      {approachSteps && (
        <div className="approach-steps__section">
          <Black
            noLayout
            overflow="hidden"
            padding="md2"
          >
            <div className="layout">
              <Grid cols="4" gap="sm" alignItems="top" customClass={styles['process-optimization__approach-block']}>
                {approachSteps.map(({ title, list }, index) => (
                  <ContentBlock 
                    borderRadius="sm" 
                    key={index}
                    customClass={styles['approach-block__item']}
                  >
                    {title && (
                      <Heading
                        Tag="h3"
                        decoration
                        customClass={styles['approach-block__heading']}
                        view="h6"
                      >
                        {title}
                      </Heading>
                    )}
                    {list && (
                      <List 
                        items={list} 
                        type="line-sm"
                        fontSize="20-25"
                      />
                    )}
                  </ContentBlock>
                ))}
              </Grid>
            </div>
            <CircleDecoration 
              height="90vh"
              position={{
                bottom: '-39vh',
                left: '9vw'
              }}
              rotate="-18"
              width="27vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                bottom: '-52vh',
                left: '15vw'
              }}
              rotate="-18"
              width="27vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                top: '-49vh',
                rigth: '20vw'
              }}
              rotate="-18"
              width="27vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                top: '-26vh',
                right: '4vw'
              }}
              rotate="-18"
              width="27vw"
            />
          </Black>
        </div>
      )}
    </div>
  )
}

export default ProcessOptimization