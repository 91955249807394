import styles from './index.module.scss';

const Grid = ({ cols = '3', gap = 'md', alignItems, margin, maxWidth, customClass, children }) => {
  return (
    <div 
      className={`${styles.grid} ${alignItems ? styles['align-items-'+alignItems] : ''} ${styles['grid-cols-'+cols]} ${styles['grid-gap-'+gap]} ${margin ? styles['grid-margin-'+margin] : ''} ${styles['max-width']} ${customClass ? customClass : ''}`}
      style={{
        maxWidth: `${maxWidth}px`
      }}
    >
        {children}
    </div>
  )
}

export default Grid