import HeroBg from '../assets/img/bg-process-optimization.png';
import PicExploreStories from '../assets/img/pic-explore-success-stories.png';
import PicCreatingTransparency from '../assets/img/pic-creating-transparency.png';
import PicDelivering from '../assets/img/bg-delivering.png';

// DATA: ORGANIZATIONAL EXCELLENCE

// Hero
export const heroData = {
  bg: HeroBg,
  title: 'Process Optimization',
  text: <>
    <p>Operational excellence: Process optimization</p>
  </>
};

// Transformative results
export const transformativeResults = {
  title: 'Ensuring transformative results',
  intro: <>
    <p>Organizations need process optimization when facing large-scale shifts in the ecosystem such as industry-wide transformation, rising costs, or performance or operations inefficiency.</p>
    <p>Our unique value proposition ensures transformative results and critical timely impact.</p>
  </>,
  items: [
    {
      icon: 'gear',
      title: 'Optimal operations and governance',
      text: 'Establish and balance value-driven process maps across the organization'
    },
    {
      icon: 'structure',
      title: 'Revised cost structure',
      text: 'Review of capital and operating expenditures to identify  opportunities to optimize and maximize benefits'
    },
    {
      icon: 'fingerprint',
      title: 'Efficient standard operating procedures',
      text: 'Efficiency-driven organization with robust and valid performance transparency'
    }
  ]
};

// Explore stories
export const exploreStories = {
  pic: PicExploreStories,
  title: 'Explore our success stories',
  items: [
    {
      icon: 'db',
      title: 'Creating transparency',
      text: 'We helped a prefab-steel manufacturer achieve his vision by installing IoT kits.',
      link: '#',
      button: 'learn more'
    },
    {
      icon: 'leaf',
      title: 'Optimizing performance',
      text: 'We developed a public-health program to improve hospitals’ operations and performance.',
      link: '#',
      button: 'learn more'
    }
  ]
};

// Creating Transparency
export const creatingTransparency = {
  bg: PicCreatingTransparency,
  title: 'Creating transparency for a prefab steel manufacturer',
  text: 'The client was a manufacturer of pre-engineered steel components for building structures with monthly production of 2,100 tons and about 400 full-time-equivalent workers. The goal was to leverage Industry 4.0 to provide operational transparency and maximize productivity.',
  list: [
    'We identified key value levers and sized the overall value at stake to help the client achieve his vision.',
    'We identified and prioritized key Industry 4.0 initiatives across business, technology, and  organization.',
    'We developed a road map for Industry 4.0 initiatives for the next two to three years.',
    'We installed an IoT kit to track real-time machine productivity for an auto-welding machine (bottleneck machine).',
  ],
  counters: [
    {
      counter: '+30%',
      title: 'Impact',
      text: 'Increase in auto-welding machine productivity'
    },
    {
      counter: '~SAR 10M',
      title: 'Impact',
      text: 'additional profit per year identified from capacity unlock'
    }
  ]
};

// Unique performance
export const uniquePerformance = {
  bg: PicDelivering,
  title: 'Delivering unique performance optimization across multiple health entities',
  intro: <>
    <p>We enhanced the operational performance of a public-health program by applying efficiency and productivity levers, optimizing the process and patient experience, and building and improving capabilities. The project spanned six clusters, 38 hospitals, and more than 700 primary-care organization across seven general domains, five mental health domains, and specialized domains and PHCs. To reach the goal, we measured approximately 130 KPIs.</p>
    <p>The challenge was to improve KPIs by focusing on performance and process enhancement, while maintaining current resource levels with minimal budget increase.</p>
  </>,
  counters: [
    {
      counter: '95%+',
      title: 'Impact',
      text: 'on-time submission of data to provide visibility'
    },
    {
      counter: '200+',
      title: 'Impact',
      text: 'focused improvements (including 64 targeted improvements at PHCs)'
    },
    {
      counter: '~10%',
      title: 'Impact',
      text: 'average productivity uplift against the baseline performance'
    }
  ]
};

// Approach
export const approach = {
  title: 'Approach',
  intro: <>
    <p>We focused on improving hospitals’ operations and performance by collecting and diagnosing high-quality data, implementing impactful initiatives, and enhancing the skills of hospital team members</p>
  </>
};

// Approach steps
export const approachSteps = [
  {
    title: 'Diagnose',
    list: [
      'Collect data to track KPIs',
      'Apply data validation measures (electronically and on the ground) to ensure data accuracy and integrity',
      'Conduct on-the-ground visits, interviews, and GEMBA walks for further diagnosis',
      'Data collection sheets',
      'Electronic data validation tools',
      'Discussion guides',
      'GEMBA walks',
    ]
  },
  {
    title: 'Design',
    list: [
      'Apply root-cause analysis and gap diagnosis',
      'Identify quick wins and long-term solutions',
      'Develop project charters and action plan',
      'Conduct alignment meetings with hospital team and leadership to ensure buy-in',
      'Analysis models and diagnostic tools',
      'Alignment meetings',
    ]
  },
  {
    title: 'Implement',
    list: [
      'Assign the project team',
      'Identify potential risks and mitigation plans',
      'Monitor KPIs',
      'Hold regular meetings with the hospital team and leadership to take corrective steps if needed',
      'Progress trackers and reports',
      'Progress meetings',
    ]
  },
  {
    title: 'Sustain',
    list: [
      {
        item: 'Capability building:',
        list: [
          'Design tailored journeys for each cohort',
          'Deliver training programs',
          'Codify material and approach',
        ]
      },
      {
        item: 'On-the-job training:',
        list: [
          'Transfer knowledge and responsibilities'
        ]
      },
      'In-class and virtual training programs/bootcamps',
    ]
  }
];