import { Heading, Text } from '../';

import styles from './index.module.scss';

const ContactAddress = ({ data: { label, value } }) => {
  return (
    <div className={styles['contact-address__block']}>
      {label && (
        <Heading
          Tag="h2"
          color="orange"
          view="h3"
          spacing="0"
        >
          {label}
        </Heading>
      )}
      {label && (
        <Text size="25-35">
          {value}
        </Text>
      )}
    </div>
  )
}

export default ContactAddress