import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [mobileNav, setMobileNav] = useState(false);

  return (
    <StateContext.Provider value={{
      currentLanguage, setCurrentLanguage,
      mobileNav, setMobileNav,
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);