import HeroBg from '../assets/img/bg-delivery-excellence.png';
import PicEstabilishing from '../assets/img/pic-estabilishing-1.png';
import PicExecuting1 from '../assets/img/pic-executing-1.png';
import PicExecuting2 from '../assets/img/pic-executing-2.png';
import PicExecuting3 from '../assets/img/pic-executing-3.png';

// DATA: DELIVERY EXCELLENCE

// Hero
export const heroData = {
  bg: HeroBg,
  title: 'Delivery Excellence',
  text: <>
    <p>Execution is more important than ever, especially in the context of Vision 2030. Achieving our great ambitions and aspirations requires smart and effective execution oversight and delivery assurance to mitigate risks and increase the chances of success.</p>
    <p>At Elixir, we believe that traditional project management is no longer enough to perform in an agile and disruptive environment. Our two key offerings in Delivery Excellence provide our clients with the methodologies, tools, and skill sets they need to succeed.</p>
  </>
};

// Transformation Delivery Excellence
export const transformationDelivery = {
  title: 'Transformation Delivery Excellence',
  cols: [
    {
      title: 'BOT for Solucion-Driven XMO',
      content: <>
        <p>X stands for the many flavors of delivery management engines: 
transformation, strategy, performance, initiative, portfolio, program, and project. The setup and operation of such an office helps clients achieve the desired impact from their strategic portfolio of projects through a host of services including planning, monitoring, digital reports, and risk resolution.</p>
        <p>Our proven methodology leverages the build-operate-transfer (BOT) model, deep knowledge, and globally trained local experts to establish world-class offices with sustainable momentum.</p>
      </>,
    },
    {
      title: 'Transformation Delivery Acceleration',
      content: <>
        <p>True impact is realized on the frontlines of delivery and on the ground with teams. Our teams help you deliver against the most complex and challenging programs.</p>
        <p>We lead the delivery of critical, at-scale transformational initiatives using various implementation methodologies and techniques (such as control tower), while integrating with SMEs and applying tailored best-in-class project management practices.</p>
      </>,
    }
  ]
};

// Services
export const servicesCols = [
  {
    title: 'Realizing a vision',
    content: <>
      <p>We helped a key ministry reestablish its vision realization office (VRO) in line with the ministry’s initiatives.</p>
    </>,
    link: '#',
    button: 'Learn more'
  },
  {
    title: 'Supporting Vision 2030',
    content: <>
      <p>We supported the delivery sector of Vision 2030 by building a delivery directorate within a center of government entity.</p>
    </>,
    link: '#',
    button: 'Learn more'
  },
  {
    title: 'Executing priority initiatives',
    content: <>
      <p>For a key sector in the Kingdom, we developed a comprehensive strategy that included 35 initiatives.</p>
    </>,
    link: '#',
    button: 'Learn more'
  }
];

// Estabilishing the vivion
export const estabilishingVivion = {
  pic: PicEstabilishing,
  subTitle: 'Smart PMO/TMO - MoF',
  title: 'Estabilishing the vivion realization office of a key ministry',
  intro: <>
    <p>We worked with a key ministry to reestablish its vision realization office (VRO) to align with the ministry’s vision initiative and support its planning and execution.</p>
  </>,
  cols: [
    {
      icon: 'files',
      title: 'Work accomplished',
      list: [
        'Reestablished the VRO to operate at full capacity by adopting the  build-operate-transfer (BOT) approach.',
        'Managed and redesigned the PMO’s BOT system to include the delivery team and governance model.',
        'Developed office governance, operating model, and project and initiative management guide.',
        'Worked on governance reports including risk management, change management, project quality management, planning, and dependencies department.',
        'Supervised the selection of the appropriate technical system for initiatives management, tracking performance, overseeing preparation and testing, and overseeing design of reports and leadership panels.',
        'Managed certain critical files and provided operational support for all tasks.',
        'Built assessment forms and tests for applicants, conducted interviews, and submitted reports accordingly.',
        'Provided on-the-job training and knowledge transfer, and prepared training and introductory bags.',
        'Supported the project management office and initiatives.',
        'Built a system of recruitment and qualification of talents, in addition to the system of incentives.'
      ]
    },
    {
      icon: 'web-spikelets',
      title: 'Impact',
      list: [
        'A fully functioning VRO to achieve the vision, with 60 talents recruited and their skills developed',
        'Activation of seven key areas: strategy and performance management, program governance, project execution, automatic performance management system and a central system for change and communication project management, business development, and knowledge management',
        'Automatic performance management system and a central project management system, in addition to dashboards to track the progress of the strategic objectives.',
        'Realization of most of the client’s key performance targets for 2017',
        'Client ranking among the highest-performing entities, according to Ada`as Q4 2017 Report',
        'Execution of 20 strategic projects by the end of 2017'
      ]
    }
  ]
};

// Building a delivery
export const buildingOrgs = {
  subTitle: 'Building Orgs & Capabilities – SMO',
  title: 'Building a delivery directorate in a center of government entity',
  intro: <>
    <p>Elixir supported the delivery sector of Vision 2030 through build-operate-transfer (BOT) of a delivery directorate (DD) within a center of government entity.</p>
  </>,
  cols: [
    {
      icon: 'target',
      title: 'Achievements',
      list: [
        <>
          <strong>Build -</strong> Defined the DD’s mandate and value chain (Diagnose, Prescribe, Heal, Monitor, and Sustain).
        </>,
        {
          item: <>
            <strong>Operate -</strong> Optimized spending efficiency on all vision realization programs (VRPs) by analyzing more than 1,300 vision initiatives to identify overlaps, unify efforts across different projects and entities, and optimize spending.
          </>,
          list: [
            'Optimized spending efficiency on all vision realization programs (VRPs) by analyzing more than 1,300 vision initiatives to identify overlaps, unify efforts across different projects and entities, and optimize spending.',
            'Accelerated financing (direct funding) of selected identified vision initiatives to accelerate implementation by conducting strategic and readiness assessments and facilitating a workshop with SMC to obtain on-spot approval.',
            'Provided on-the-ground support to 11 VRPs by offering multiple different services and value propositions.',
            'Developed readiness and performance reports.', 'Supported in multiple critical files based on SMC requests and mandates (ad hocs).'
          ]
        },
        {
          item: <>
            <strong>Transfer -</strong> Built capabilities of current employees and new joiners through on-the-job training, one-on-one sessions, coaching, and other techniques.
          </>,
          list: ['Conducted multiple handover sessions to ensure effective knowledge transfer and sustainability.']
        }
      ]
    },
    {
      icon: 'web-spikelets',
      title: 'Impact',
      list: [
        'Core functions built and activated within the DD, with strategic objectives identified and mapped across the functions',
        'Internal procedures fully designed to institutionalize operations internally',
        'Decision making supported through new reports', 'Talents attracted and upskilled in the DD, and introductory/handover sessions conducted for new joiners in the DD',
        '400+ initiatives with approved funding', 'Funding accelerated for 170+ initiatives', '140+ initiative cards approved by Ada`a',
        '150+ change requests processed', '80+ challenges identified, analyzed, and recommended'
      ]
    }
  ]
};

// Overview
export const overview = {
  subTitle: 'Overview',
  title: 'Managing and executing priority initiatives',
  intro: <>
    <p>We developed a comprehensive strategy for a key sector in the Kingdom. The strategy included 35 initiatives, 12 of which are of the highest priority to achieve the strategy objectives.</p>
  </>
};

// Approach
export const approach = {
  title: 'Approach',
  icon: 'chess',
  intro: <>
    <p>We developed an operating model and work tools to help the client attract funding and make the transition from planning to actual implementation:</p>
  </>,
  list: [
    'Participated in reexamining the office’s list of 45 initiatives, developed a new proposal of 34 initiatives, discussed the proposal, and submitted requests for change to the new update.',
    'Designed and activated workshops with owners of priority initiatives with the participation of stakeholders and experts in the mining field to understand the scope of initiatives and contribute to their readiness.',
    'Developed and allocated a technical tool to enable the team to manage and monitor the performance of initiatives and issue the necessary reports.',
    'Studied the new initiative list to identify priority initiatives, align them with government support centers, and develop a methodology to assess the readiness of initiatives to obtain the required funding, improve the initiatives’ scope of work, and develop detailed plans.',
    'Developed a strategic map of the initiatives to clarify the objectives, relative indicators, and the mechanisms for calculating these indicators, and supported initiatives in the execution process by establishing and activating rooms to monitor the performance of initiatives.',
    'Empowered the project office team to follow up on the performance of the initiatives and complete the necessary planning through training sessions in project management and on-the-job follow-up to increase the quality of performance and correct the path, where necessary.',
  ]
};

// Impact
export const impact = {
  title: 'Impact',
  icon: 'chart-monitor',
  intro: <>
    <p>Funding for priority initiatives with a total value of almost SAR 700 million</p>
  </>,
  list: [
    'A unified methodology in managing initiatives in line with the VRO and the entities associated with Vision 2030',
    'Unified sources of information, data, and reports through a central technical tool that contributes to decision making and helps in monitoring and documenting all initiative information',
    'An impact map of priority initiatives outlining milestones and their links to one another',
    'A PMO capable of following up on initiative planning and achieving the necessary funding',
    'A special executive guide for each initiative, including detailed execution plans covering the key activities, milestones, budgets, and resources required',
  ]
};

// Census program
export const censusProgram = {
  subTitle: 'Overview',
  title: 'Executing a national census program',
  intro: <>
    <p>We worked with a government authority for statistics to execute a national-level census program.</p>
  </>,
  items: [
    {
      pic: PicExecuting1,
      title: 'Challenge and context',
      text: <>
        <p>The client set out to conduct a national “de jure” census based on best available techniques and approaches using the latest technologies across multiple dimensions:</p>
      </>,
      list: [
        'Using geospatial data to quantify and locate all addresses in the country',
        'Replacing paper enumeration forms with digitized fields and self-enumeration',
        'Introducing statistical data registers that collect data from administrative records',
        'Implementing a control tower to oversee performance and progress',
        'Setting up an automated quality assurance system and a modern data dissemination platform',
      ]
    },
    {
      pic: PicExecuting2,
      title: 'Multiple challenges stood in the way of the client’s ambitious aspirations:',
      list: [
        'Lack of capabilities in business understanding, implementation, and technology',
        'Lack of clarity on how to adopt “de jure” census',
        'Resistance to change from old census implementation approaches',
        'Communication gaps across stakeholders',
      ]
    },
    {
      pic: PicExecuting3,
      title: 'Approach',
      text: <>
        <p>We built a team of experts from a variety of areas to assure delivery of a high-standard census and cover key client-capability gaps:</p>
      </>,
      list: [
        'Strategy design and content deep dives',
        'Data science and deep analysis',
        'Implementation and delivery assurance'
      ]
    }
  ]
};

// Census impact
export const censusImpact = {
  title: 'Impact',
  intro: <>
    <p>We developed a comprehensive strategy for a key sector in the Kingdom. The strategy included 35 initiatives, 12 of which are of the highest priority to achieve the strategy objectives.</p>
  </>,
  items: [
    {
      icon: 'course',
      title: '43,000+',
      text: <>
        <p>field enumerators were deployed</p>
      </>
    },
    {
      icon: 'handshake',
      title: '~20% of total were self-enumeration surveys,',
      text: <>
        <p>which reduced field enumerators’ efforts</p>
      </>
    }
  ]
};