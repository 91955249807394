import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Heading, Text, ContactAddress, ContactIcons, ContactForm, AdditionalAddress, CircleDecoration } from '../../components';

import { contactUs } from '../../utils/contactUs';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const contactRef = useRef();

  useLayoutEffect(() => {
    // Contact section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles.contact__section}`,
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles.contact__section}`, {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles.contact__section} [class*="contact__left"] > .heading-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles.contact__section} [class*="contact__left"] > .text-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8)
        .from(`.${styles.contact__section} [class*="contact__contacts"]`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 1.1)
        .from(`[class*="contact__right"]`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1.5
        }, 1.3);

        document.querySelectorAll('[class*="contact__section"] [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0.5 + index / 2);
        });
      })
    }, contactRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles.contact__section} ref={contactRef}>
      <div className="layout">
        <div className={styles.contact__block}>
          <div className={styles.contact__left}>
            {contactUs?.title && (
              <Heading 
                Tag="h1"
                decoration
                view="h2"
              >
                {contactUs.title}
              </Heading>
            )}
            {contactUs?.intro && (
              <Text size="22-25">
                {contactUs.intro}
              </Text>
            )}
            {contactUs?.contacts && (
              <div className={styles.contact__contacts}>
                {contactUs.contacts?.mailAddress && (
                  <ContactAddress data={contactUs.contacts.mailAddress} />
                )}
                {contactUs.contacts?.iconList && (
                  <ContactIcons items={contactUs.contacts.iconList} />
                )}
                {contactUs.contacts?.additionalAddresses && (
                  <AdditionalAddress items={contactUs.contacts.additionalAddresses} />
                )}
              </div>
            )}
          </div>
          <div className={styles.contact__right}>
            {contactUs?.form && (
              <ContactForm 
                title={contactUs.form?.title}
                fields={contactUs.form?.fields}
              />
            )}
          </div>
        </div>
      </div>

      <CircleDecoration 
        height="60vh"
        position={{
          bottom: '60px',
          left: '-9vw'
        }}
        width="26vw"
      />
      <CircleDecoration 
        height="60vh"
        position={{
          bottom: '-14vh',
          right: '-3vh'
        }}
        width="26vw"
      />
      <CircleDecoration 
        height="55vh"
        position={{
          top: '-15vh',
          right: '-5vw'
        }}
        width="27vw"
      />
    </div>
  )
}

export default Contact