import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import LogoImg from '../../assets/svg/logo.svg';

const Logo = () => {
  return (
    <Link to="/" className={styles.logo}>
      <img src={LogoImg} alt="Elixir" />
    </Link>
  )
}

export default Logo