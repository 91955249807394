import styles from './index.module.scss';

const Black = ({ padding = 'md2', overflow, customClass, noLayout, children }) => {
  return (
    <section 
      className={`${styles.black__section} ${padding ? styles['p-'+padding] : ''} ${styles[customClass] ? styles[customClass] : ''}`}
      style={{
        overflow: overflow
      }}
    >
      {!noLayout ? (
        <div className='layout'>
          {children}
        </div>
      ) : (
        <>
          {children}
        </>
      )}
    </section>
  )
}

export default Black