import { useRef } from 'react';

import { Button, Grid, Icon, IconBlock, Heading, Text } from '../';

import styles from './index.module.scss';

const AccordionItem = ({ data: { title, link, icon, cols, button }, index, open, toggleItem }) => {
  const contentEl = useRef(null);

  return (
    <div className={styles.accordion__item}>
      <div 
        className={`${styles.accordion__header} ${open ? styles.active : ''}`}
        onClick={(e) => toggleItem(e, index)}
      >
        <IconBlock
          circleColor="grey-600-05"
          circleSize="lg"
          icon={icon}
          iconSize="md"
          gap="75"
        >
          <Heading view="h3-2">
            {title}
          </Heading>
        </IconBlock>
        <Button
          type="button"
          btnType="icon-circle"
          color={open ? "orange-border" : "orange"}
          size="s"
          icon={<Icon icon="angle--down" />}
          customClass={styles['accordion__circle-btn']}
        />
      </div>
      <div 
        className={`${styles.accordion__content} ${open ? styles.opened : ''}`}
        ref={contentEl}
        style={
          open
          ? { height: contentEl.current.scrollHeight }
          : { height: '0px' }
        }
      >
        <div className={styles['accordion__content-inner']}>
          {cols && (
            <Grid
              cols="2"
              gap="sm"
              customClass="mb-2"
            >
              {cols.map((item, index) => (
                <div className={styles.accordion__col} key={index}>
                  {item?.content && (
                    <Text
                      color="white"
                      size="22-25"
                      customClass="mb-2"
                    >
                      {item.content}
                    </Text>
                  )}
                  {item?.button && (
                    <Button
                      type="link"
                      color="blue-grey"
                      href={item.button?.link}
                      size="m2"
                      full
                    >
                      {item.button?.text}
                    </Button>
                  )}
                </div>
              ))}
            </Grid>
          )}
          <div className={styles.accordion__button}>
            <Button
              btnType="link-icon"
              color="white"
              href={link}
              icon={<Icon icon="angle--right" />}
              size="m2"
              full
            >
              {button}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionItem