import { useRef } from 'react';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading, Text, CircleDecoration } from '../';

import { useStateContext } from '../../context/StateContextProvider';
import { heroCarousel } from '../../utils/homepage';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './index.module.scss';

import LogoText from '../../assets/svg/logo-text.svg';

import heroVideo from '../../assets/video/hero-video.mp4';

const CarouselHero = () => {
  const swiperNavPrev = useRef(null);
  const swiperNavNext = useRef(null);
  const {currentLanguage} = useStateContext();

  return (
    <div className={styles.hero__carousel}>
      <div className={styles.hero__swiper}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: swiperNavPrev.current,
            nextEl: swiperNavNext.current,
          }}
          pagination={{
            bulletClass: 'hero__pagination-item',
            bulletActiveClass: 'hero__pagination--active',
            clickable: true
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = swiperNavPrev.current;
              swiper.params.navigation.nextEl = swiperNavNext.current;
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {heroCarousel && heroCarousel[currentLanguage] &&  heroCarousel[currentLanguage].map(({logo, title, text}, i) => (
            <SwiperSlide className={styles.hero__slide} key={i}>
              <div className={`layout ${styles['layout']}`}>
                {logo && (
                  <div className={styles.hero__logo}>
                    <img src={LogoText} alt="Elixir" />
                  </div>
                )}
                <Heading
                  Tag="h1"
                  align="center"
                  decoration={{
                    width: '50px'
                  }}
                >
                  {title}
                </Heading>
                {text && (
                  <Text
                    align="center"
                    maxWidth="60"
                    size="25-35"
                  >{text}</Text>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.hero__nav}>
          <button className={`${styles['hero__nav-item']} ${styles['--prev']}`} ref={swiperNavPrev}><i></i></button>
          <button className={`${styles['hero__nav-item']} ${styles['--next'] ? styles['--next'] : ''}`} ref={swiperNavNext}><i></i></button>
        </div>
      </div>
      <div className={styles.hero__decorations}>
        <CircleDecoration 
          position={{
            top: '9.3%',
            right: '2.24%'
          }}
        />
        <CircleDecoration 
          position={{
            top: '-55%',
            right: '23%'
          }}
        />
        <div className={styles.hero__video}>
          <video autoPlay muted playsInline loop src={heroVideo} type="video/mp4" />
        </div>
      </div>
    </div>
  )
}

export default CarouselHero