import { Icon } from '../';

import styles from './index.module.scss';

const PicBlock = ({ image, link, decoration, gradient, icon, customClass, type = '1' }) => {
  const Tag = link ? 'a' : 'div';

  return (
    <Tag 
      className={`${styles.pic__block} ${decoration ? styles['decoration-'+decoration] : ''} ${gradient ? styles['gradient-'+gradient] : ''} ${styles['type-'+type] ? styles['type-'+type] : ''} ${customClass ? customClass : ''}`}
    >
      <div className={styles.pic__inner}>
        <img src={image} alt="" />
      </div>
      {icon && (
        <div className={styles.pic__icon}>
          <Icon icon={icon} />
        </div>
      )}
      <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="romb-diagonal-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.002967, 0.003105)">
            <path d="M170.725 321.997H15.6426C7.35831 321.997 0.642578 315.281 0.642578 306.997V176.671L172.545 4.76938C175.358 1.95633 179.173 0.375977 183.151 0.375977H322.748C331.033 0.375977 337.748 7.09171 337.748 15.376V154.973C337.748 158.952 336.168 162.767 333.355 165.58L181.331 317.603C178.518 320.416 174.703 321.997 170.725 321.997Z" fill="white"/>
          </clipPath>
          {type === 'romb' && (
            <clipPath id="romb-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.000975, 0.002439)">
              <path d="M6.46431 191.171L191.125 6.51041C194.875 2.75968 199.962 0.652547 205.267 0.652545L821.193 0.652344C826.498 0.652341 831.585 2.75947 835.335 6.5102L1020 191.171C1027.81 198.981 1027.81 211.644 1020 219.455L835.335 404.115C831.585 407.866 826.498 409.973 821.193 409.973L205.267 409.973C199.962 409.973 194.875 407.866 191.125 404.115L6.46431 219.455C-1.34617 211.644 -1.34618 198.981 6.46431 191.171Z" fill="white"/>
            </clipPath>
          )}
          {type === 'romb-right' && (
            <clipPath id="romb-right-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.001196, 0.002439)">
              <path d="M6.28462 190.874L190.945 6.21353C194.696 2.46281 199.783 0.355672 205.087 0.35567L835.969 0.355469L835.973 409.676L205.087 409.676C199.783 409.676 194.696 407.569 190.945 403.818L6.28462 219.158C-1.52586 211.348 -1.52587 198.684 6.28462 190.874Z" fill="white"/>
            </clipPath>
          )}
          {type === 'romb-right-lg' && (
            <clipPath id="romb-right-lg-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.001443, 0.001634)">
              <path d="M6.36372 291.908L291.88 6.39156C295.631 2.64083 300.718 0.533695 306.022 0.533691H692.235L692.18 611.566L305.655 611.566C300.351 611.566 295.631 609.459 291.88 605.709L6.36373 320.192C-1.44676 312.382 -1.44677 299.718 6.36372 291.908Z" fill="white"/> 
            </clipPath>
          )}
        </defs>
      </svg>
    </Tag>
  )
}

export default PicBlock