import HeroBg from '../assets/img/bg-digital-excellence.png';

// DATA: DIGITAL EXCELLENCE

// Hero
export const heroData = {
  bg: HeroBg,
  title: 'Digital excellence',
  text: <>
    <p>We provide our clients with distinctive, competitive digital delivery from concept to full realization. Our work enables and accelerates partnerships with large-scale impact.</p>
    <p>Launched in 2022, our Digital Excellence service line draws on our team’s deep expertise to build your digital capacity and deliver transformative results.</p>
  </>
};

// Digital delivery
export const digitalDelivery = {
  title: 'Digital delivery',
  text: <>
    <p>To enable and accelerate large-scale digital delivery programs by providing value assurance, technical expertise and transformation office support</p>
  </>
};