import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Logo, Navigation, LanguagePicker, Button, Icon } from '../';
import { headerLinks } from '../../utils/data';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const {currentLanguage, mobileNav, setMobileNav} = useStateContext();
  const headerRef = useRef();

  useLayoutEffect(() => {
    // Hero Bottom Section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let tl = gsap.timeline();

        tl.from(`.${styles.header}`, {
          opacity: 0,
          duration: 1
        })
        .from(`.${styles.header__left} [class*="logo"]`, {
          opacity: 0,
          x: '-20px',
          duration: 1
        }, 0.2)
        .from(`.${styles.header__center} [class*="header__nav"]`, {
          opacity: 0,
          duration: 1
        }, 0.2)
        .from(`.${styles.header__right} [class*="language-picker__block"]`, {
          opacity: 0,
          x: '20px',
          duration: 1
        }, 0.2);
      })
    }, headerRef);
    return () => ctx.revert();
  }, []);

  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <div className={styles.header__left}>
          <Logo />
        </div>
        <div className={styles.header__center}>
          <Navigation data={headerLinks[currentLanguage]} type="header" />
        </div>
        <div className={styles.header__right}>
          <LanguagePicker />
          <button className={styles.header__burger} onClick={() => setMobileNav(true)}>
            <i></i>
          </button>
        </div>
      </header>
      <div className={`${styles['mobile__nav-block']} ${mobileNav ? styles['opened'] : ''}`}>
        <div className={styles['mobile__logo']}>
          <Logo />
        </div>
        <div className={styles['mobile__nav']}>
          <Navigation data={headerLinks[currentLanguage]} type="header" />
          <LanguagePicker />
        </div>
        <Button
          size="s"
          customClass={styles['mobile__close']}
          onClick={() => setMobileNav(false)}
        >
          <Icon icon="close-black" />
        </Button>
      </div>
    </>
  )
}

export default Header