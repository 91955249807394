// DATA: TEAM MODAL

export const teamModal = {
  'meet-ahmed': {
    title: 'Meet Ahmed',
    fullDesc: <>
      <p>Ahmed joined Elixir in June 2021 after three years as a project manager for a major IT and travel company.</p>
      <p>Since joining Elixir, Ahmed has grown professionally and built strong communication and problem-solving skills through wide-ranging client work and training programs. She has benefited from ongoing mentorship and sponsorship opportunities that helped her understand the expectations of her role in the firm. </p>
      <p>Ahmed has also grown personally in the firm’s family-like culture, creating friendships that will last a lifetime. Ahmed holds a master of science in project management from the University of Southampton.</p>
    </>
  },
  'meet-dalia': {
    title: 'Meet Dahlia',
    fullDesc: <>
      <p>Dalia joined Elixir in June 2021 after three years as a project manager for a major IT and travel company.</p>
      <p>Since joining Elixir, Dalia has grown professionally and built strong communication and problem-solving skills through wide-ranging client work and training programs. She has benefited from ongoing mentorship and sponsorship opportunities that helped her understand the expectations of her role in the firm. </p>
      <p>Dalia has also grown personally in the firm’s family-like culture, creating friendships that will last a lifetime. Dalia holds a master of science in project management from the University of Southampton.</p>
    </>
  },
  'meet-faisal': {
    title: 'Meet Faisal',
    fullDesc: <>
      <p>Faisal joined Elixir in June 2021 after three years as a project manager for a major IT and travel company.</p>
      <p>Since joining Elixir, Faisal has grown professionally and built strong communication and problem-solving skills through wide-ranging client work and training programs. She has benefited from ongoing mentorship and sponsorship opportunities that helped her understand the expectations of her role in the firm. </p>
      <p>Faisal has also grown personally in the firm’s family-like culture, creating friendships that will last a lifetime. Faisal holds a master of science in project management from the University of Southampton.</p>
    </>
  },
  'meet-ahmed-2': {
    title: 'Meet Ahmed',
    fullDesc: <>
      <p>Ahmed joined Elixir in June 2021 after three years as a project manager for a major IT and travel company.</p>
      <p>Since joining Elixir, Ahmed has grown professionally and built strong communication and problem-solving skills through wide-ranging client work and training programs. She has benefited from ongoing mentorship and sponsorship opportunities that helped her understand the expectations of her role in the firm. </p>
      <p>Ahmed has also grown personally in the firm’s family-like culture, creating friendships that will last a lifetime. Ahmed holds a master of science in project management from the University of Southampton.</p>
    </>
  }
}