import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Heading, Text } from '../';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const Timeline = ({ history }) => {
  const timelineRef = useRef();

  useLayoutEffect(() => {
    // Our history section
    let mm = gsap.matchMedia(),
        breakPoint = 769;

    mm.add({
      isDesktop: `(min-width: ${breakPoint}px)`,
      isMobile: `(max-width: ${breakPoint - 1}px)`,
    }, (context) => {
      let { isDesktop } = context.conditions;

      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: timelineRef.current,
          start: isDesktop ? 'center bottom' : 'top bottom',
          //start: 'center bottom',
          end: 'bottom top',
        }
      });

      if(isDesktop){
        stl.from(`.${styles.history__list}`, {
          opacity: 0,
          width: 0,
          ease: 'power2',
          duration: 2
        }, 0);
      } else {
        stl.from(`.${styles.history__list}`, {
          opacity: 0,
          ease: 'power2',
          duration: 2
        }, 0);
      }

      document.querySelectorAll(`.${styles.history__item}`).forEach((item, index) => {
        stl.from(item, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 0.5
        }, 0 + index / 2);
      });

      document.querySelectorAll(`.${styles.history__divisions} > i`).forEach((item, index) => {
        stl.from(item, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 0.3
        }, 0 + index / 5);
      });
    })
  }, []);

  // useLayoutEffect(() => {
  //   // Hero section
  //   const ctx = gsap.context(() => {
  //     setTimeout(() => {
  //       let stl = gsap.timeline({
  //         scrollTrigger: {
  //           trigger: timelineRef.current,
  //           start: 'center bottom',
  //           end: 'bottom top',
  //         }
  //       });
  
  //       stl.from(`.${styles.history__list}`, {
  //         opacity: 0,
  //         width: 0,
  //         ease: 'power2',
  //         duration: 2
  //       }, 0);

  //       document.querySelectorAll(`.${styles.history__item}`).forEach((item, index) => {
  //         stl.from(item, {
  //           x: '-5vh',
  //           opacity: 0,
  //           ease: 'power2',
  //           duration: 0.5
  //         }, 0 + index / 2);
  //       });

  //       document.querySelectorAll(`.${styles.history__divisions} > i`).forEach((item, index) => {
  //         stl.from(item, {
  //           x: '-5vh',
  //           opacity: 0,
  //           ease: 'power2',
  //           duration: 0.3
  //         }, 0 + index / 5);
  //       });
  //     })
  //   }, timelineRef);
  //   return () => ctx.revert();
  // }, []);

  return (
    <div className={styles.history__block} ref={timelineRef}>
      <div className={styles.history__list}>
        {history && history.map(({ year, title, text }, index) => (
          <div key={index} className={styles.history__item}>
            <div className={styles.year}>{year}</div>
            <div className={styles.history__cnt}>
              <div className={styles.title}>
                <Heading
                  Tag="h3"
                  color="orange"
                  spacing="20"
                  view="h4"
                >
                  {title}
                </Heading>
              </div>
              <div className={styles.text}>
                <Text>
                  {text}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.history__divisions}>
        {history && history.map((_, index) => (
          <React.Fragment key={index}>
            {index === 0 && <i></i>}
            <i></i>
            <i></i>
            <i></i>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Timeline