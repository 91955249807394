import { useState } from 'react'

import { Text, List } from '../';

import styles from './index.module.scss';

const SuccessStories = ({ 
  labels: { leftText, rightText, colors: labelsColors }, 
  scheme: { left: leftScheme, middle: middleScheme, right: rightScheme }, 
  tabs: { buttons: tabsButtons, items: tabsItems } 
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (e, index) => {
    e.preventDefault();
    setActiveTab(index);
  };

  return (
    <div className={styles['success-stories__block']}>
      <div className={styles['success-stories__labels']}>
        <Text>
          {leftText}
        </Text>
        <div className={styles['success-stories__labels-colors']}>
          {labelsColors && labelsColors.map((item, index) => (
            <i 
              className={`${styles['success-stories__labels-color']} bg-${item}`}
              key={index}
            ></i>
          ))}
        </div>
        <Text>
          {rightText}
        </Text>
      </div>
      <div className={styles['success-stories__scheme']}>
        <div className={styles['scheme__left']}>
          <div className={styles['scheme__header']}>
            <Text size="22-25">
              {leftScheme?.title}
            </Text>
          </div>
          <div className={styles['scheme__content']}>
            <List 
              type="text"
              gap="0"
              fontSize="18-24"
              items={leftScheme?.list}
            />
          </div>
        </div>
        <div className={styles['scheme__middle']}>
          <div className={styles['scheme__header']}>
            <Text 
              align="center"
              size="22-25"
            >
              {middleScheme?.title}
            </Text>
          </div>
          <div className={styles['scheme__content']}>
            <List 
              align="center"
              type="text"
              gap="0"
              fontSize="18-24"
              items={middleScheme?.list}
            />
          </div>
        </div>
        <div className={styles['scheme__right']}>
          <div className={styles['scheme__header']}>
            <Text size="22-25">
              {rightScheme?.title}
            </Text>
          </div>
          <div className={styles['scheme__content']}>
            <List 
              type="text"
              gap="0"
              fontSize="18-24"
              items={rightScheme?.list}
            />
          </div>
        </div>
      </div>
      <div className={styles['success-stories__tabs']}>
        <div className={styles['tabs__nav']}>
          {tabsButtons && tabsButtons.map((item, index) => (
            <div
              className={`${styles['tabs__nav-item']} ${activeTab === index ? styles['active'] : ''}`}
              key={index}
              onClick={(e) => changeTab(e, index)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className={styles['tabs__items']}>
          {tabsItems && tabsItems.map((item, index) => (
            <div 
              className={styles['tabs__item']}
              key={index}
              style={
                activeTab === index
                ? {display: 'flex'}
                : {display: 'none'}
              }
            >
              <div className={styles['tabs__icon-list']}>
                {item && item.map(({ color, title }, itemIndex) => (
                  <div 
                    className={styles['tabs__icon-item']}
                    key={itemIndex}
                  >
                    <i className={`${styles['tabs__icon-icon']} bg-${color}`}></i>
                    <span>{title}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SuccessStories