// DATA: CONTACT US

export const contactUs = {
  title: 'Contact Us',
  intro: <>
    <p>Thank you for your interest in Elixir. To learn more about our work, please reach out to us by phone or email, or stop by one of our offices.</p>
  </>,
  contacts: {
    mailAddress: {
      label: 'Mailing Address',
      value: 'P.O. Box 11263 Jeddah 21453 Saudi Arabia'
    },
    iconList: [
      {
        type: 'tel',
        icon: 'phone',
        label: 'Phone.',
        value: '+96 6122631720',
      },
      {
        type: 'tel',
        icon: 'fax',
        label: 'FAX.',
        value: '+96 6122631730',
      },
      {
        type: 'mailto',
        icon: 'letter',
        label: 'E-mail.',
        value: 'info@elixir.sa',
      }
    ],
    additionalAddresses: [
      {
        place: 'Jeddah',
        address: 'AlRawdah Street, Elegant Home, Left Entrance, 2nd Floor, Office #6'
      },
      {
        place: 'Riyadh',
        address: 'Al Olaya Street, Al Aqariya Plaza, Entrance D, 4th Floor, Office #403'
      }
    ]
  },
  form: {
    title: <>
      Do you have questions? <strong>Write to us!</strong>
    </>,
    fields: [
      {
        name: 'name',
        type: 'text',
        placeholder: 'Name'
      },
      {
        name: 'phone',
        type: 'text',
        placeholder: 'Phone'
      },
      {
        name: 'email',
        type: 'email',
        placeholder: 'E-mail'
      },
      {
        name: 'message',
        type: 'multiline-text',
        placeholder: 'Message'
      },
      {
        name: 'help',
        type: 'checkbox',
        label: 'We help local companies, govermments, organizations, and individuals succeed globally via Strategic Creative Solutions'
      }
    ]
  }
};