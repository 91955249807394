import styles from './index.module.scss';

const ContentBlock = ({ border = "normal", borderRadius = "normal", padding = "lg", customClass, children }) => {
  return (
    <div 
      className={`${styles['content__block']} ${styles['--border-'+border]} ${styles['--borderRadius-'+borderRadius]} ${styles['--padding-'+padding]} ${customClass ? customClass : ''}`}
    >
      {children}
    </div>
  )
}

export default ContentBlock