import { Icon } from '../';

import styles from './index.module.scss';

const IconBlock = ({ circleColor = 'grey-850', circleSize, icon, iconColor = 'orange', iconSize, gap = "30", margin, customClass, children }) => {
  return (
    <div className={`icon-block-component ${styles.icon__block} gap-${gap} ${margin ? styles['margin-'+margin] : ''} ${customClass ? customClass : ''}`}>
      <div className={`${styles['icon-block__icon']} bg-${circleColor} ${iconColor} ${circleSize ? styles['size-'+circleSize] : ''} ${iconSize ? styles['icon-size-'+iconSize] : ''}`}>
        <Icon icon={icon} />
      </div>
      <div className={`${styles['icon-block__text']}`}>{children}</div>
    </div>
  )
}

export default IconBlock