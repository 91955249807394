// Links for Header
export const headerLinks = {
  'en': [
    {
      href: '/',
      title: 'Welcome'
    },
    {
      href: '/about-us',
      title: 'About Us'
    },
    {
      href: '/our-work',
      title: 'Our Work'
    },
    {
      href: '/careers',
      title: 'Careers'
    },
    {
      href: '/contact-us',
      title: 'Contact Us'
    }
  ], 
  'ru': [
    {
      href: '/',
      title: 'Главная'
    },
    {
      href: '/about-us',
      title: 'О нас'
    },
    {
      href: '/our-work',
      title: 'Наши работы'
    },
    {
      href: '/careers',
      title: 'Карьера'
    },
    {
      href: '/contact-us',
      title: 'Связаться с нами'
    }
  ]
};

// Languages
export const languages = {
  en: {
    nativeName: 'English',
  },
  ru: {
    nativeName: 'Russian',
  }
};

// Links for Footer
export const footerLinks = [
  {
    href: '/privacy-policy',
    title: 'Privacy policy'
  },
  {
    href: '/cookie-policy',
    title: 'Cookie Policy'
  },
  {
    href: '/terms-of-use',
    title: 'Terms of use'
  }
]