import React, { useState } from 'react';

import { AccordionItem } from '../';

import styles from './index.module.scss';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (e, index) => {
    e.preventDefault();
    if(activeIndex !== index) setActiveIndex(index);
  };

  return (
    <div className={styles.accordion__block}>
      {items && items.map((item, index) => (
        <React.Fragment key={index}>
          <AccordionItem 
            data={item}
            index={index}
            open={index === activeIndex}
            toggleItem={toggleItem}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default Accordion