import Decoration from '../../assets/svg/pic-infinity-decoration-2.svg';

import styles from './index.module.scss';

const PartnersBlock = ({ items }) => {
  return (
    <div className={styles.partners__block}>
      {items && items.map(({ link, title, info, position }, index) => (
        <div 
          className={styles.partners__item}
          key={index}
          style={{
            top: position?.top,
            right: position?.right,
            bottom: position?.bottom,
            left: position?.left,
          }}
        >
          <a 
            href={link}
            target="_blank"
            rel="noreferrer"
            className={styles.partners__link}
          >
            {title}
          </a>
          <div className={styles.partners__desc}>
            {info}
          </div>
        </div>
      ))}
      <div className={styles.partners__img}>
        <img src={Decoration} alt="" />
      </div>
    </div>
  )
}

export default PartnersBlock