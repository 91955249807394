import { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import { Header, Footer } from './components';
import { AboutUs, Careers, ContactUs, CustomerExperience, DeliveryExcellence, DigitalExcellence, Homepage, OrganizationalExcellence, OurWork, ProcessOptimization, LeadershipDetail, CareerDetail } from './pages';

import { useStateContext } from './context/StateContextProvider';

import './App.scss';

function App() {
  const {setMobileNav} = useStateContext();
  let location = useLocation();
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    setShowFooter(location.pathname.indexOf('leadership') === -1);
    window.scrollTo(0, 0);
    setMobileNav(false);
  }, [location]);

  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route index path="/" element={<Homepage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="leadership/:leadershipId" element={<LeadershipDetail />} />
          <Route path="careers" element={<Careers />} />
          <Route path="careers/:careerId" element={<CareerDetail />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="customer-experience" element={<CustomerExperience />} />
          <Route path="delivery-excellence" element={<DeliveryExcellence />} />
          <Route path="digital-excellence" element={<DigitalExcellence />} />
          <Route path="organizational-excellence" element={<OrganizationalExcellence />} />
          <Route path="our-work" element={<OurWork />} />
          <Route path="process-optimization" element={<ProcessOptimization />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </main>
      {showFooter && <Footer />}
    </div>
  );
}

export default App;
