import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import McKinseyLogo from '../../assets/svg/logo-mckinsey.svg';
import Logo from '../../assets/svg/logo-black.svg';

const LogoFooter = () => {
  return (
    <div className={styles['logo-footer__block']}>
      <div className={`${styles['logo-footer__logo']} ${styles['--mckinsey']}`}>
        <img src={McKinseyLogo} alt="McKinsey" />
      </div>
      <div className={styles['logo-footer__logo']}>
        <Link to="/">
          <img src={Logo} alt="Elixir" />
        </Link>
      </div>
    </div>
  )
}

export default LogoFooter