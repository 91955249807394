import { Icon, PicBlock, Text } from '../';

import styles from './index.module.scss';

const MeetOurPeopleItem = ({ data: { pic, quote, modalId }, setModalOpen, setActiveModal }) => {
  return (
    <>
      <a 
        href={`#${modalId}`} 
        className={styles['meet-our-people__item']}
        onClick={(e) => {
          e.preventDefault();
          setActiveModal(modalId);
          setModalOpen(true);
        }}
      >
        <div className={styles['meet-our-people__pic']}>
          <PicBlock 
            image={pic}
            decoration="purple"
            gradient="purple"
          />
        </div>
        <div className={styles['meet-our-people__quote']}>
          <i className={styles['quote-decoration-top']}></i>
          <i className={styles['quote-decoration-bottom']}></i>
          <div className={styles['quote-icon']}>
            <Icon icon="angle--right" />
          </div>
          <Text 
            size="25-25"
          >
            {quote}
          </Text>
        </div>
      </a>
    </>
  )
}

export default MeetOurPeopleItem