import React from 'react';
import { Autoplay, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Text } from '../';

import 'swiper/css';
import 'swiper/css/autoplay';
import styles from './index.module.scss';

const CarouselFullWidth = ({ items }) => {
  return (
    <div className={styles['full-width__carousel']}>
      <Swiper
        modules={[Autoplay, Mousewheel, FreeMode]}
        autoplay={{
          delay: 0.5,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop={true}
        slidesPerView="auto"
        freeMode={true}
        spaceBetween={45}
        speed={11000}
        mousewheel
      >
        {items.map((item, i) => (
          <SwiperSlide className={styles['full-width__slide']} key={i}>
            {item && (
              <div className={styles['full-width__text']}>
                <Text size="18-24">
                  {item}
                </Text>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CarouselFullWidth