import PicWhatWeDo from '../assets/img/pic-what-we-do.png';
import PicHowWeWork from '../assets/img/pic-how-we-work.png';
import PicOurDefininfPillars from '../assets/img/pic-defining-pillars-1.png';

// DATA : HOMEPAGE

// Hero Carousel
export const heroCarousel = {
  'en': [
    {
      logo: true,
      title: <>
        Combining local <strong>insights with a global</strong> perspective
      </>,
      text: <>
        <p>Our mission at Elixir is to help Saudi Arabian organizations implement transformations and build organizational and employee capability.</p>
      </>
    },
    {
      logo: true,
      title: <>
        Unlocking dynamic markets
      </>,
      text: <>
        <p>We’re passionate about delivering lasting value and impact for clients who drive change in the Kingdom of Saudi Arabia.</p>
      </>
    },
    {
      logo: true,
      title: <>
        Delivering cornerstone strategies
      </>,
      text: <>
        <p>We invest in our people and believe in collaborating for change across the Kingdom of Saudi Arabia. </p>
      </>
    }
  ],
  'ru': [
    {
      logo: true,
      title: <>
        Сочетание местных <strong>пониманий с глобальной</strong> точкой зрения
      </>,
      text: <>
        <p>Наша миссия в Elixir — помочь организациям Саудовской Аравии осуществить преобразования и нарастить организационные и кадровые возможности.</p>
      </>
    },
    {
      logo: true,
      title: <>
        Открытие динамичных рынков
      </>,
      text: <>
        <p>Мы стремимся приносить долгосрочную пользу и оказывать влияние на клиентов, которые способствуют изменениям в Королевстве Саудовская Аравия.</p>
      </>
    },
    {
      logo: true,
      title: <>
        Реализация краеугольных стратегий
      </>,
      text: <>
        <p>Мы инвестируем в наших людей и верим в сотрудничество во имя перемен во всем Королевстве Саудовская Аравия.</p>
      </>
    }
  ]
};

// What we do
export const whatWeDo = {
  title: 'What we do?',
  pic: PicWhatWeDo,
  contentTitle: 'Elixir is a Saudi-born implementation consultancy with a passion for delivering lasting value and impact for clients.',
  content: <>
    <p>Founded in 2005, we work at the intersection of strategic consultancy and budding enterprise to provide real-time solutions for organizations operating across the public and private sectors.</p>
    <p>In 2017, we joined McKinsey to support the firm’s implementation function. Today, the Elixir family continues to grow and thrive.</p>
  </>,
  link: {
    text: 'Learn more about Elixir',
    href: '#'
  }
}

// How we work
export const howWeWork = {
  title: 'How we work',
  pic: PicHowWeWork,
  content: <>
    <p>We deliver cornerstone strategies for our clients by investing in our people and believing in their capabilities. An integral part of the value chain from start to finish, we drive change through collaboration.</p>
  </>,
  link: {
    text: 'See our work',
    href: '#'
  }
}

// Our defining pillars
export const ourDefiningPillars = {
  title: <>Our <strong>defining</strong> pillars</>,
  intro: <>
    <p>We combine top talent and expertise in key functions and sectors with strategy frameworks, deep knowledge and insights, and a collaborative approach.</p>
  </>,
  pic: PicOurDefininfPillars,
  carousel: [
    {
      icon: 'gear',
      title: 'Practical, locally driven solutions',
      content: 'We have a strong understanding of KSA’s government, business, and social ecosystems, based on more than 188 engagements since 2006.'
    },
    {
      icon: 'lamp',
      iconSize: 'md',
      title: 'Best practices and world-class expertise',
      content: 'Our collaboration with McKinsey, a global firm of more than 12,000 functional and industry experts, helps us shape the best possible solutions.'
    },
    {
      icon: 'chart',
      iconSize: 'sm',
      title: 'Real impact, delivered with passion',
      content: 'We own the client agenda from idea to impact, taking on full accountability for success. We are proud to have built, operated, and transformed numerous major entities that will play a leading role in the realization of the Kingdom’s Vision 2030 strategic plan.'
    },
  ]
}