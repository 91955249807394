import styles from './index.module.scss';

const Hero = ({ padding, bg, shadow, overflow, children }) => {
  return (
    <section 
      className={`hero__section ${styles.hero__section} ${padding ? styles['p-'+padding] : ''} ${shadow ? styles['shadow'] : ''}`}
      style={{
        backgroundImage: bg ? `url(${bg})` : 'none',
        overflow: overflow ? overflow : ''
      }}
    >
      <div className='layout'>
        {children}
      </div>
    </section>
  )
}

export default Hero