import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Heading, Text, Grid, GridItem, TeamItem, InterviewProcessItem, ContactUsBlock, CircleDecoration, CarouselMeetPeople } from '../../components';
import { Black, JoinUs, Hero, TypesOfProject } from '../../sections';
import { heroData, typesOfProject, whatWeLookingFor, joinUs, teamBlock, interviewProcess, meetPeople, contactUs } from '../../utils/careers';

import styles from './index.module.scss';

import PicContact from '../../assets/img/pic-contact-us.png';
import Decoration from '../../assets/svg/pic-decoration-8.svg';

gsap.registerPlugin(ScrollTrigger);

const Careers = () => {
  const careersRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] [class*="hero__logo"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.5);
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // What we looking for section
    let mm = gsap.matchMedia(),
        breakPoint = 769;

    mm.add({
      isDesktop: `(min-width: ${breakPoint}px)`,
      isMobile: `(max-width: ${breakPoint - 1}px)`,
    }, (context) => {
      let { isDesktop } = context.conditions;

      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.what-we-looking-for__section',
            start: isDesktop ? 'center bottom' : 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.what-we-looking-for__section', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.what-we-looking-for__section .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from('.what-we-looking-for__section .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.7);

        document.querySelectorAll('.what-we-looking-for__section [class*="grid__item"]').forEach((item, index) => {
          stl.from(item, {
            x: '-5vw',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 1 + index / 3);
        });

        document.querySelectorAll('.what-we-looking-for__section [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 1.5 + index / 2);
        });
      }, 100)
    });
  }, []);

  useLayoutEffect(() => {
    // Team interview circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.team-interview-meet-contact__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Team section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['black__team-block']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['black__team-block']} .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['black__team-block']} .layout > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from(`[class*="black__team-block-decoration"]`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);

        document.querySelectorAll(`.${styles['black__team-block']} [class*="team__item-wrap"]`).forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            y: '-3vh',
            ease: 'power2',
            duration: 1
          }, 1 + index / 5);
        });
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Interview section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['black__interview-process']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['black__interview-process']} .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        document.querySelectorAll(`.${styles['black__interview-process']} [class*="interview-process__item"]`).forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            y: '-3vh',
            ease: 'power2',
            duration: 1.5
          }, 0.4 + index / 3);
        });
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Interview section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['black__meet-people']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['black__meet-people']} .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['black__meet-people']} .layout > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['black__meet-people']} [class*="meet-our-people__item"]`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6)
        .from(`.${styles['black__meet-people']} [class*="swiper-button"]`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.9);
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Interview section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['black__contact-us']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['black__contact-us']} .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['black__contact-us']} .layout > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.3)
        .from(`.${styles['contact__left']}`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.6)
        .from(`.${styles['contact__right']}`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.9);
      })
    }, careersRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={careersRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          padding="bottom-lg"
          shadow
        >
          {heroData?.logo && (
            <img 
              className="hero__logo"
              src={heroData.logo} 
              alt="Elixir" 
            />
          )}
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text 
              size="25-35"
              maxWidth="60"
            >
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      {typesOfProject && (
        <TypesOfProject data={typesOfProject} />
      )}

      {whatWeLookingFor && (
        <div className="what-we-looking-for__section">
          <Black
            overflow="hidden"
          >
            {whatWeLookingFor?.title && (
              <Heading
                align="center"
                spacing="40"
                view="h1"
                decoration={{
                  width: '50px'
                }}
              >
                {whatWeLookingFor.title}
              </Heading>
            )}
            {whatWeLookingFor?.intro && (
              <Text 
                align="center"
                size="25-35"
                maxWidth="60"
              >
                {whatWeLookingFor.intro}
              </Text>
            )}
            {whatWeLookingFor?.cols && (
              <Grid margin="top-md2" customClass={styles['what-we-looking-for__block']}>
                {whatWeLookingFor.cols.map((item, index) => (
                  <GridItem 
                    data={item}
                    key={index}
                  />
                ))}
              </Grid>
            )}

            <CircleDecoration 
              height="82vh"
              position={{
                top: '-160px',
                right: '25%'
              }}
              rotate="-69"
              width="26vw"
            />
            <CircleDecoration 
              height="82vh"
              position={{
                top: '-60px',
                right: '-15%'
              }}
              rotate="-69"
              width="26vw"
            />
            <CircleDecoration 
              height="100vh"
              position={{
                top: '60px',
                left: '-20%'
              }}
              rotate="21"
              width="30%"
            />
          </Black>
        </div>
      )}

      {joinUs && (
        <JoinUs
          data={joinUs}
        />
      )}

      {(teamBlock || interviewProcess || meetPeople || contactUs) && (
        <div className="team-interview-meet-contact__section">
          <Black
            noLayout={true}
            overflow="hidden"
            padding="lg"
          >
            {teamBlock && (
              <div className={styles['black__team-block']}>
                <div className="layout">
                  {teamBlock?.title && (
                    <Heading
                      spacing="40"
                      decoration={{
                        width: '50px'
                      }}
                    >
                      {teamBlock.title}
                    </Heading>
                  )}
                  {teamBlock?.intro && (
                    <Text 
                      size="25-35"
                      maxWidth="60"
                    >
                      {teamBlock.intro}
                    </Text>
                  )}
                </div>
                {teamBlock?.members && (
                  <>
                    <div className={styles['black__team-block-decoration']}>
                      <img src={Decoration} alt="" />
                    </div>
                    <div className="layout">
                      <Grid gap="sm2" margin="bottom-lg">
                        {teamBlock.members.map((item, index) => (
                          <TeamItem 
                            key={index} 
                            data={item}
                            customStyle={index === 1 && { gridColumn: '2 span' }}
                            type="inline"
                          />
                        ))}
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            )}

            {interviewProcess && (
              <div className={styles['black__interview-process']}>
                <div className="layout">
                  {interviewProcess?.title && (
                    <Heading
                      align="center"
                      decoration
                    >
                      {interviewProcess.title}
                    </Heading>
                  )}
                  {interviewProcess?.steps && (
                    <Grid 
                      alignItems="top"
                      flex
                      margin="top-md2"
                      maxWidth="1120"
                      customClass={styles['interview-process__grid']}
                    >
                      {interviewProcess.steps.map((item, index) => (
                        <InterviewProcessItem 
                          data={item}
                          key={index}
                        />
                      ))}
                    </Grid>
                  )}
                </div>
              </div>
            )}

            {meetPeople && (
              <div className={styles['black__meet-people']}>
                <div className="layout">
                  {meetPeople?.title && (
                    <Heading
                      decoration
                    >
                      {meetPeople.title}
                    </Heading>
                  )}
                  {meetPeople?.intro && (
                    <Text 
                      size="22-25"
                      maxWidth="60"
                    >
                      {meetPeople.intro}
                    </Text>
                  )}
                  {meetPeople?.people && (
                    <CarouselMeetPeople 
                      slides={meetPeople.people}
                    />
                  )}
                </div>
              </div>
            )}

            {contactUs && (
              <div className={styles['black__contact-us']}>
                <div className="layout">
                  {contactUs?.title && (
                    <Heading
                      decoration
                    >
                      {contactUs.title}
                    </Heading>
                  )}
                  {contactUs?.intro && (
                    <Text 
                      size="22-25"
                      maxWidth="60"
                    >
                      {contactUs.intro}
                    </Text>
                  )}
                  {contactUs?.contacts && (
                    <div className={styles['contact__block']}>
                      <div className={styles['contact__left']}>
                        <ContactUsBlock data={contactUs.contacts} />
                      </div>
                      <div className={styles['contact__right']}>
                        <img src={PicContact} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <CircleDecoration 
              height="90vh"
              position={{
                top: '100vh',
                right: '-12vw'
              }}
              rotate="-75"
              width="34vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                top: '80vh',
                right: '16vw'
              }}
              rotate="-75"
              width="34vw"
            />
            <CircleDecoration 
              height="111vh"
              position={{
                top: '114vh',
                left: '-24vw'
              }}
              rotate="-75"
              width="40vw"
            />
          </Black>
        </div>
      )}
    </div>
  )
}

export default Careers