import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, Heading, IconBlock, Text } from '../';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const CarouselJoinUs = ({ slides }) => {
  const swiperNavPrev = useRef(null);
  const swiperNavNext = useRef(null);
  const swiperPagination = useRef(null);
  const carouselJoinUsRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['join-us__carousel']}`,
            start: 'center bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['join-us__carousel']}`, {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['join-us__carousel']} [class*="join-us__bg"]`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles['join-us__carousel']} .layout > .heading-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from(`.${styles['join-us__carousel']} .layout > .text-component`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.3)
        .from(`.${styles['join-us__carousel']} [class*="join-us__job"]`, {
          x: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.6)
        .from(`.${styles['join-us__nav']}`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.8)
        .from(`.${styles['join-us__pagination-wrap']}`, {
          y: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.8);
      })
    }, carouselJoinUsRef);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles['join-us__carousel']} ref={carouselJoinUsRef}>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          prevEl: swiperNavPrev.current,
          nextEl: swiperNavNext.current,
        }}
        pagination={{
          el: swiperPagination.current,
          clickable: true,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = swiperNavPrev.current;
            swiper.params.navigation.nextEl = swiperNavNext.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();

            swiper.params.pagination.el = swiperPagination.current;
            swiper.pagination.destroy();
            swiper.pagination.init();
            swiper.pagination.render();
            swiper.pagination.update();
          });
        }}
      >
        {slides.map(({pic, title, intro, job}, i) => (
          <SwiperSlide className={styles['join-us__slide']} key={i}>
            {pic && (
              <div 
                className={styles['join-us__bg']} 
                style={{
                  backgroundImage: `url(${pic})`
                }} 
              />
            )}
            <div className="layout">
              {title && (
                <Heading
                  color="black"
                  decoration={{
                    color: 'purple-100'
                  }}
                >
                  {title}
                </Heading>
              )}
              {intro && (
                <Text
                  color="black"
                  maxWidth="80"
                >
                  {intro}
                </Text>
              )}
              {job && (
                <div className={styles['join-us__job']}>
                  {job?.title && (
                    <IconBlock 
                      circleColor="grey-50"
                      circleSize="xs"
                      icon={job?.icon}
                      iconColor="black"
                      iconSize="sm"
                      margin="mb-20"
                    >
                      <Heading
                        Tag="h3"
                        color="black"
                        view="h4"
                      >
                        {job.title}
                      </Heading>
                    </IconBlock>
                  )}
                  {job?.text && (
                    <Text color="black">
                      {job.text}
                    </Text>
                  )}
                  {job?.button && (
                    <Button
                      href={job.button?.link}
                      color="orange"
                      size="m2"
                    >
                      {job.button?.title}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles['join-us__nav']}>
        <button 
          className={`${styles['join-us__nav-item']} ${styles['--prev']}`} 
          ref={swiperNavPrev} 
        />
        <button 
          className={`${styles['join-us__nav-item']} ${styles['--next'] ? styles['--next'] : ''}`} 
          ref={swiperNavNext} 
        />
      </div>
      <div className={styles['join-us__pagination-wrap']}>
        <div 
          className={styles['join-us__pagination']} 
          ref={swiperPagination}
        ></div>
      </div>
    </div>
  )
}

export default CarouselJoinUs