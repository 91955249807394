import { useState } from 'react';

import styles from './index.module.scss';

const Checkbox = ({ name, label }) => {
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    setChecked(e.target.checked);
  }

  return (
    <div className={styles['checkbox__component']}>
      <label>
        <input 
          name={name}
          type="checkbox" 
          value={checked} 
          checked={checked} 
          onChange={onChange} 
        />
        <i></i>
        <span>{label}</span>
      </label>
    </div>
  )
}

export default Checkbox