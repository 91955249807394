import styles from './index.module.scss';

const List = ({ align, type = 'default', padding, items, fontSize = '22-25', gap = 'md', color = 'white' }) => {
  return (
    <ul className={`list-component ${styles.list} ${align ? styles['align-'+align] : ''} ${padding ? styles['padding-'+padding] : ''} ${color} ${styles['type-'+type] ? styles['type-'+type] : ''} text-${fontSize} ${styles['gap-'+gap] ? styles['gap-'+gap] : ''}`}>
      {items && items.map((item, index) => (
        <li key={index}>
          {item?.item ? (
            <>
              {item?.item && (<div>{item.item}</div>)}
              {item?.list && (<List type="default" gap="xs" fontSize={fontSize} color={color} items={item.list} />)}
            </>
          ) : (
            <div>{item}</div>
          )}
        </li>
      ))}
    </ul>
  )
}

export default List