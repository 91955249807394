import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { LogoFooter, Navigation } from '../';
import { footerLinks } from '../../utils/data';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const footerRef = useRef();

  useLayoutEffect(() => {
    // Hero Bottom Section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: footerRef.current,
            start: 'center-=10% bottom',
            end: 'bottom top',
          }
        });

        stl.from(`.${styles.footer__top}`, {
          opacity: 0,
          y: '-20px',
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles.footer__bottom}`, {
          opacity: 0,
          y: '-20px',
          ease: 'power2',
          duration: 1
        }, 0.2);
      })
    }, footerRef);
    return () => ctx.revert();
  }, []);

  return (
    <footer className={styles.footer} ref={footerRef}>
      <div className='layout'>
        <div className={styles.footer__top}>
          <LogoFooter />
        </div>
        <div className={styles.footer__bottom}>
          <Navigation data={footerLinks} type="footer" />
        </div>
      </div>
    </footer>
  )
}

export default Footer