import { Heading, TextField, Checkbox, Button } from '../';

import styles from './index.module.scss';

const ContactForm = ({ title, fields }) => {
  return (
    <div className={styles['contact-form__block']}>
      {title && (
        <Heading
          Tag="h2"
          align="center"
          view="h3"
        >
          {title}
        </Heading>
      )}
      {fields && (
        <form method="POST" className={styles['contact-form__form']}>
          {fields.map(({ name, type, placeholder, label }, index) => (
            <div className={styles['contact-form__fieldset']} key={index}>
              {type === 'checkbox' ? (
                <Checkbox 
                  name={name}
                  label={label}
                />
              ) : (
                <TextField 
                  name={name}
                  placeholder={placeholder}
                  type={type}
                />
              )}
            </div>
          ))}
          <div className={`${styles['contact-form__fieldset'] ? styles['contact-form__fieldset'] : ''} ${styles['contact-form__fieldset-btn']}`}>
            <Button
              color="orange"
              size="lg"
              type="button"
            >
              Send Message
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}

export default ContactForm