import { Heading, Text } from '../';

import styles from './index.module.scss';

const GridItem = ({ data: { title, content } }) => {
  return (
    <div className={styles.grid__item}>
      {title && (
        <Heading Tag="h3">
          {title}
        </Heading>
      )}
      {content && (
        <Text>
          {content}
        </Text>
      )}
    </div>
  )
}

export default GridItem