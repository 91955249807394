import HeroBg from '../assets/img/bg-our-work.png';
import LogoText from '../assets/svg/logo-text.svg';

// DATA: OUR WORK

// Hero
export const heroData = {
  bg: HeroBg,
  logo: LogoText,
  title: 'Our Work',
  text: <>
    <p>We deliver lasting value and impact for our clients through five high-powered service lines.</p>
  </>
};

// Our Works
export const ourWorks = [
  {
    title: 'Delivery Excellence',
    link: '/delivery-excellence',
    icon: 'quadrocopter',
    cols: [
      {
        content: <>
          <p>We help you achieve impact from your strategy portfolio of projects through services including planning, monitoring, digital reports, and risk resolution.</p>
        </>,
        button: {
          link: '#',
          text: 'Smart project management offices and transformation offices (PMO/TMO)',
        }
      },
      {
        content: <>
          <p>We lead delivery of critical, complex initiatives using implementation tools, integrating with subject matter experts (SMEs), and applying best practices.</p>
        </>,
        button: {
          link: '#',
          text: 'Initiative acceleration',
        }
      }
    ],
    button: 'More about delivery excellence'
  },
  {
    title: 'Organizational Excellence',
    link: '/organizational-excellence',
    icon: 'scheme',
    cols: [
      {
        content: <>
          <p>We help you achieve impact from your strategy portfolio of projects through services including planning, monitoring, digital reports, and risk resolution.</p>
        </>,
        button: {
          link: '#',
          text: 'Smart project management offices and transformation offices (PMO/TMO)',
        }
      },
      {
        content: <>
          <p>We lead delivery of critical, complex initiatives using implementation tools, integrating with subject matter experts (SMEs), and applying best practices.</p>
        </>,
        button: {
          link: '#',
          text: 'Initiative acceleration',
        }
      }
    ],
    button: 'More about organizational excellence'
  },
  {
    title: 'Operational Excellence',
    link: '/process-optimization',
    icon: 'pie-chart',
    cols: [
      {
        content: <>
          <p>We help you achieve impact from your strategy portfolio of projects through services including planning, monitoring, digital reports, and risk resolution.</p>
        </>,
        button: {
          link: '#',
          text: 'Smart project management offices and transformation offices (PMO/TMO)',
        }
      },
      {
        content: <>
          <p>We lead delivery of critical, complex initiatives using implementation tools, integrating with subject matter experts (SMEs), and applying best practices.</p>
        </>,
        button: {
          link: '#',
          text: 'Initiative acceleration',
        }
      }
    ],
    button: 'More about operational excellence'
  },
  {
    title: 'Digital Excellence',
    link: '/digital-excellence',
    icon: 'web-path',
    cols: [
      {
        content: <>
          <p>We help you achieve impact from your strategy portfolio of projects through services including planning, monitoring, digital reports, and risk resolution.</p>
        </>,
        button: {
          link: '#',
          text: 'Smart project management offices and transformation offices (PMO/TMO)',
        }
      },
      {
        content: <>
          <p>We lead delivery of critical, complex initiatives using implementation tools, integrating with subject matter experts (SMEs), and applying best practices.</p>
        </>,
        button: {
          link: '#',
          text: 'Initiative acceleration',
        }
      }
    ],
    button: 'More about digital excellence'
  },
  {
    title: 'Visitor Experience',
    link: '/customer-experience',
    icon: 'box',
    cols: [
      {
        content: <>
          <p>We help you achieve impact from your strategy portfolio of projects through services including planning, monitoring, digital reports, and risk resolution.</p>
        </>,
        button: {
          link: '#',
          text: 'Smart project management offices and transformation offices (PMO/TMO)',
        }
      },
      {
        content: <>
          <p>We lead delivery of critical, complex initiatives using implementation tools, integrating with subject matter experts (SMEs), and applying best practices.</p>
        </>,
        button: {
          link: '#',
          text: 'Initiative acceleration',
        }
      }
    ],
    button: 'More about visitor experience'
  }
];

// Our success stories
export const ourSuccessStories = {
  title: <>
    Our <strong>success</strong> stories
  </>,
  intro: <>
    <p>Elixir is the largest Saudi consulting company trusted by high-profile public-sector entities across the government ecosystem.</p>
  </>,
  labels: {
    leftText: 'One project',
    rightText: 'Multiple projects',
    colors: ['white', 'purple-100', 'orange']
  },
  scheme: {
    left: {
      title: 'Ambitious Nation',
      list: ['Enable society responsibility', 'Enhance government effectiveness']
    },
    middle: {
      title: 'Thriving Economy',
      list: ['Incerase employment', 'Grow and diversify the economy']
    },
    right: {
      title: 'Vibrant  Society',
      list: ['Offer a fulfilling and healthy life', 'Strengthn islamic and national identity']
    }
  },
  tabs: {
    buttons: [
      'L1: Center of Government',
      'L2: Vision Realiziation Programs',
      'L3: Ministers, Authorities and Supporting Entities',
    ],
    items: [
      [
        {
          color: 'orange',
          title: 'Supporting Entities'
        },
        {
          color: 'orange',
          title: 'Government Delivery'
        },
        {
          color: 'white',
          title: 'Strategic Planning'
        }
      ],
      [
        {
          color: 'white',
          title: 'Privatization'
        },
        {
          color: 'white',
          title: 'Improving the Financial Sector'
        },
        {
          color: 'orange',
          title: 'Improve National Industries and logistic Services'
        },
        {
          color: 'white',
          title: 'Quality of Life'
        },
        {
          color: 'orange',
          title: 'Public Investments'
        },
        {
          color: 'orange',
          title: 'Capability Building'
        },
        {
          color: 'purple-100',
          title: 'Housing'
        },
        {
          color: 'purple-100',
          title: 'Financial Sustainability'
        },
        {
          color: 'purple-100',
          title: 'Hajj & Umarh Visitors Service'
        },
        {
          color: 'orange',
          title: 'National Transfrormation'
        },
        {
          color: 'purple-100',
          title: 'Health Transformation'
        }
      ],
      [
        {
          color: 'white',
          title: '...'
        },
        {
          color: 'orange',
          title: 'Water'
        },
        {
          color: 'white',
          title: 'Entertaiment'
        },
        {
          color: 'orange',
          title: 'Energy'
        },
        {
          color: 'white',
          title: 'Real Estate'
        },
        {
          color: 'orange',
          title: 'Mining'
        },
        {
          color: 'orange',
          title: 'Financal'
        },
        {
          color: 'orange',
          title: 'Agriculture'
        },
        {
          color: 'orange',
          title: 'Municipal services'
        },
        {
          color: 'purple-100',
          title: 'Health'
        },
        {
          color: 'orange',
          title: 'Commerce'
        },
        {
          color: 'purple-100',
          title: 'Transport'
        },
        {
          color: 'purple-100',
          title: 'Education'
        },
        {
          color: 'purple-100',
          title: 'Province improvement'
        },
        {
          color: 'orange',
          title: 'Defense'
        },
        {
          color: 'orange',
          title: 'Digital'
        }
      ],
    ],
  }
};

// Sectors
export const sectors = {
  title: <>
    In the past three years, we have served clients in various sectors in more than <strong>56 projects</strong>:
  </>,
  intro: <>
    <p>Since our founding in 2005, we have dedicated ourselves to transforming organizations across Saudi Arabia through real-time solutions.</p>
  </>,
  cols: [
    {
      list: [
        'Established and activated many supporting bodies', 'Supported planning, accelerating, and monitoring the performance of intiatives',
        'Supported the development of work mechanisms and the release of guidelines and enablers of achievement',
        'Launched seven project management offices', 'Established 196 offices in the financial sector'
      ]
    },
    {
      list: [
        'Activated rapid intervention centers to solve challenges',
        'Designed and supported the achivement of more than 1,000 initiatives and projects',
        'Launched more than  30 digital platforms', 'Built capabilities of more than 500 employees'
      ]
    }
  ]
};