import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Carousel, CarouselLeadership, CarouselGlobalInsights, CircleDecoration, Grid, Heading, PartnersBlock, TeamItem, Text, Timeline } from '../../components';
import { Black, Hero, White } from '../../sections';
import { heroData, leverageGlobalInsights, ourHistory, ourTeam, ourValues, ourLeadership, partners } from '../../utils/aboutUs';

import styles from './index.module.scss';

import historyDecoration from '../../assets/svg/pic-decoration-1.svg';

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
  const aboutUsPage = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] [class*="hero__logo"]', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 1.5);
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Global insights section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="leverage-global-insights__section"]',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="leverage-global-insights__section"] .layout > .heading-component', {
          y: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: '.global-insights__carousel',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        stl2.from('.global-insights__carousel [class*="global-insights__carousel-main"]', {
          x: '-10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('.global-insights__carousel [class*="global-insights__carousel-thumbs"]:not([class*="global-insights__carousel-thumbs-item"])', {
          x: '10vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5);
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Our history section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.our-history__section',
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.our-history__section .layout > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('.our-history__section .layout > .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `.our-history__section .${styles['history-decoration']}`,
            start: 'center bottom',
            end: 'bottom top',
          }
        });
  
        stl2.from(`.our-history__section .${styles['history-decoration']}`, {
          opacity: 0,
          ease: 'power2',
          duration: 5
        }, 0);
        
        document.querySelectorAll('.history-team-values-insights__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Team section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['our-team__section']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['our-team__section']} .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['our-team__section']} .layout > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4);
        
        document.querySelectorAll(`.${styles['our-team__section']} [class*="team__item-wrap"]`).forEach((item, index) => {
          let stlTeamItem = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlTeamItem.from(item, {
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 0 + index / 6);
        });

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['button-wrapper']}`,
            start: 'center bottom',
            end: 'bottom top',
          }
        });

        stl2.from(`.${styles['button-wrapper']}`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Our values section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.our-values__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`[class*="our-values__block"] > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`[class*="our-values__block"] > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from(`[class*="our-values__block"] > [class*="our-values__carousel"]`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Partners section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="partners__section"]`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`[class*="partners__section"] .layout > .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`[class*="partners__section"] .layout > .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.4)
        .from(`[class*="partners__section"] [class*="partners__block"]`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);

        document.querySelectorAll(`[class*="partners__section"] [class*="partners__item"]`).forEach((item, index) => {
          let stlPartnersItem = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlPartnersItem.from(item, {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 0 + index / 3);
        });

        document.querySelectorAll('[class*="partners__section"] [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'center bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, aboutUsPage);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={aboutUsPage}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
        >
          {heroData?.logo && (
            <img 
              className="hero__logo"
              src={heroData.logo} 
              alt="Elixir" 
            />
          )}
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text size="25-35">
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      <div className="history-team-values-insights__section overflow-hidden">
        {leverageGlobalInsights && (
          <White
            customClass="leverage-global-insights__section"
            padding="lg"
          >
            {leverageGlobalInsights?.title && (
              <Heading
                align="center"
                color="black"
                decoration={{
                  color: 'purple-100'
                }}
              >
                {leverageGlobalInsights.title}
              </Heading>
            )}
            {leverageGlobalInsights?.slides && leverageGlobalInsights?.pics && (
              <CarouselGlobalInsights slides={leverageGlobalInsights.slides} pics={leverageGlobalInsights.pics} />
            )}
          </White>
        )}
        
        {(ourHistory || ourTeam || ourValues) && (
          <Black
            customClass="history-team-values__section"
            overflow="hidden"
            padding="xl"
            noLayout
          >
            {ourHistory && (
              <div className="our-history__section">
                <div className="layout">
                  {ourHistory?.title && (
                    <Heading 
                      align="center"
                      decoration
                    >
                      {ourHistory.title}
                    </Heading>
                  )}
                  {ourHistory?.intro && (
                    <Text
                      align="center"
                      size="22-25"
                    >
                      {ourHistory.intro}
                    </Text>
                  )}
                  {ourHistory?.history && (
                    <Timeline history={ourHistory.history} />
                  )}
                </div>
                <div className={styles['history-decoration']}>
                  <img 
                    src={historyDecoration} 
                    alt="" 
                  />
                </div>
              </div>
            )}

            {ourTeam && (
              <div className={styles['our-team__section']}>
                <div className="layout z-index-2">
                  {ourTeam?.title && (
                    <Heading
                      align="center"
                      decoration
                    >
                      {ourTeam.title}
                    </Heading>
                  )}
                  {ourTeam?.intro && (
                    <Text
                      align="center"
                      maxWidth="70"
                      size="22-25"
                    >
                      {ourTeam.intro}
                    </Text>
                  )}
                  {ourTeam?.members && (
                    <>
                      <Grid cols="3" gap="xl" margin="lg">
                        {ourTeam.members.map((item, index) => (
                          <TeamItem data={item} key={index} />
                        ))}
                      </Grid>
                      <div className={`${styles['button-wrapper']} ${styles['--line']}`}>
                        <Button
                          color="orange"
                          href="#"
                          type="link"
                        >
                          See more (23)
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            {ourValues && (
              <div className="our-values__section">
                <div className="layout">
                  <div className={styles['our-values__block']}>
                    {ourValues?.title && (
                      <Heading decoration>
                        {ourValues.title}
                      </Heading>
                    )}
                    {ourValues?.intro && (
                      <Text
                        maxWidth="40"
                        size="22-25"
                      >
                        {ourValues.intro}
                      </Text>
                    )}
                    {ourValues?.slides && (
                      <div className={styles['our-values__carousel']}>
                        <Carousel slides={ourValues.slides} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <CircleDecoration 
              color="rgba(96, 119, 218, 0.3)"
              height="82vh"
              position={{
                bottom: '33vh',
                left: '-19%'
              }}
              rotate="21"
              width="26%"
            />
            <CircleDecoration 
              height="82vh"
              position={{
                top: '70vh',
                right: '13%'
              }}
              rotate="21"
              width="26%"
            />
            <CircleDecoration 
              height="82vh"
              position={{
                top: '115vh',
                right: '26%'
              }}
              rotate="98"
              width="26%"
            />
          </Black>
        )}
      </div>

      {ourLeadership && (
        <White
          customClass="our-leadership__section"
          padding="none"
        >
          <CarouselLeadership slides={ourLeadership} />
        </White>
      )}
      
      {partners && (
        <Black
          customClass="partners__section"
          overflow="hidden"
        >
          {partners?.title && (
            <Heading
              align="center"
              decoration
            >
              {partners.title}
            </Heading>
          )}
          {partners?.intro && (
            <Text
              align="center"
              maxWidth="40"
              size="22-25"
            >
              {partners.intro}
            </Text>
          )}
          {partners?.items && (
            <PartnersBlock items={partners.items} />
          )}
          <CircleDecoration 
            height="82vh"
            position={{
              top: '-19%',
              right: '2%'
            }}
            rotate="21"
            width="36%"
          />
          <CircleDecoration 
            height="82vh"
            position={{
              bottom: '-58vh',
              left: '-2%'
            }}
            rotate="21"
            width="36%"
          />
        </Black>
      )}
    </div>
  )
}

export default AboutUs