import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, CircleDecoration, ContentBlock, Grid, HeaderDecorated, Heading, IconBlock, List, PicBlock, Text } from '../../components';
import { Black, Hero, White } from '../../sections';
import { heroData, orgExcellence, services, establishingVRO, operatingMinistrys, operatingGovernment, approach, impact } from '../../utils/organizationalExcellence';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const OrganizationalExcellence = () => {
  const orgExcellenceRef = useRef();

  useLayoutEffect(() => {
    // Hero section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '[class*="hero__section"]',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('[class*="hero__section"]', {
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from('[class*="hero__section"] .heading-component', {
          y: '-3vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] .text-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Transformation delivery section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: '.org-excellence__section',
            start: 'top bottom',
            end: 'bottom top',
          }
        });
  
        stl.from('.org-excellence__section [class*="org-excellence__inner"] > .heading-component', {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0);

        document.querySelectorAll('.org-excellence__section [class*="content__block"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0.5 + index / 2);
        });

        document.querySelectorAll('.org-excellence__section [class*="circle-decoration"]').forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0.5 + index / 2);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Services section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.services__wrapper`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        stl.from(`.services__wrapper .layout > .heading-component`, {
          opacity: 0,
          ease: 'power2',
          y: '-5vh',
          duration: 1
        }, 0);

        document.querySelectorAll(`.${styles.services__col}`).forEach((item, index) => {
          stl.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1
          }, 0.3 + index / 2);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Circles section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('.establishing-operating__section [class*="circle-decoration"]').forEach((item, index) => {
          let stlCircle = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top bottom',
              end: 'bottom top',
            }
          });

          stlCircle.from(item, {
            opacity: 0,
            ease: 'power2',
            scale: 0,
            duration: 3
          }, 0);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Establishing section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.${styles['establishing-vro__section']}`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.${styles['establishing-vro__section']} [class*="establishing-vro__pic"]`, {
          x: '5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.${styles['establishing-vro__section']} .establishing__title .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5)
        .from(`.${styles['establishing-vro__section']} .establishing__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.8);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="establishing-vro__grid"]`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll('[class*="establishing-vro__grid"] > div').forEach((item, index) => {
          stl2.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0 + index / 2);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Operating ministrys section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.operating-government__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.operating-government__section .operating-ministrys__title`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.operating-government__section .operating-ministrys__text`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5);

        let stl2 = gsap.timeline({
          scrollTrigger: {
            trigger: `[class*="operating-ministrys__grid"]`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });

        document.querySelectorAll('[class*="operating-ministrys__grid"] > div').forEach((item, index) => {
          stl2.from(item, {
            opacity: 0,
            ease: 'power2',
            x: '-5vh',
            duration: 1.5
          }, 0 + index / 2);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Operating ministrys section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: `.operating-government__section`,
            start: 'top+=15% bottom',
            end: 'bottom top',
          }
        });
  
        stl.from(`.operating-government__section .heading-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0)
        .from(`.operating-government__section .text-component`, {
          y: '-5vh',
          opacity: 0,
          ease: 'power2',
          duration: 1
        }, 0.5);
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Approach and impact section
    const ctx = gsap.context(() => {
      setTimeout(() => {
        document.querySelectorAll('[class*="list-decor__section"]').forEach((item, index) => {
          let stlDecor = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: 'top+=15% bottom',
              end: 'bottom top',
            }
          });

          stlDecor.from(item.querySelector('[class*="header-decorated__block"]'), {
            y: '-10vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, 0)
          .from(item.querySelector('.text-component'), {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 1
          }, .4)
          .from(item.querySelector('.list-component'), {
            y: '-5vh',
            opacity: 0,
            ease: 'power2',
            duration: 2
          }, .8);
        });
      })
    }, orgExcellenceRef);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={orgExcellenceRef}>
      {heroData && (
        <Hero
          bg={heroData?.bg}
          shadow
          padding="bottom-xs"
        >
          {heroData?.title && (
            <Heading
              Tag="h1"
              spacing="40"
              decoration={{
                width: '50px'
              }}
            >
              {heroData.title}
            </Heading>
          )}
          {heroData?.text && (
            <Text size="25-35">
              {heroData.text}
            </Text>
          )}
        </Hero>
      )}

      {orgExcellence && (
        <div className="org-excellence__section">
          <Black
            noLayout
            padding="lg"
            overflow="hidden"
          >
            <div className={styles['org-excellence__block']}>
              <div className="layout">
                <div className={styles['org-excellence__inner']}>
                  {orgExcellence?.title && (
                    <Heading
                      Tag="h2"
                      view="h3-2"
                      customClass={styles['org-excellence__title']}
                    >
                      {orgExcellence.title}
                    </Heading>
                  )}
                  {orgExcellence?.cols && (
                    <Grid cols="2" gap="sm">
                      {orgExcellence.cols.map(({ title, content }, index) => (
                        <ContentBlock key={index}>
                          {title && (
                            <Heading
                              Tag="h3"
                              view="h4"
                            >
                              {title}
                            </Heading>
                          )}
                          {content && (
                            <Text>
                              {content}
                            </Text>
                          )}
                        </ContentBlock>
                      ))}
                    </Grid>
                  )}
                </div>
              </div>
            </div>

            <CircleDecoration 
              height="55vh"
              position={{
                top: '0vh',
                right: '17vw'
              }}
              width="27vw"
            />
            <CircleDecoration 
              height="55vh"
              position={{
                top: '21vh',
                right: '0px'
              }}
              width="27vw"
            />
            <CircleDecoration 
              height="90vh"
              position={{
                bottom: '-14vh',
                right: '0px'
              }}
              width="26vw"
            />
          </Black>
        </div>
      )}

      <div className="overflow-hidden">
        {services && (
          <div className="services__wrapper">
            <White>
              {services?.title && (
                <Heading
                  color="black"
                  decoration
                >
                  {services.title}
                </Heading>
              )}
              <div className={styles.services__section}>
                {services?.cols && (
                  <Grid gap="md2">
                    {services.cols.map(({ title, content, link, button }, index) => (
                      <div className={styles.services__col} key={index}>
                        {title && (
                          <Heading
                            Tag="h2"
                            color="black"
                            view="h3-2"
                            spacing="30"
                          >
                            {title}
                          </Heading>
                        )}
                        {content && (
                          <Text color="black">
                            {content}
                          </Text>
                        )}
                        {link && button && (
                          <Button 
                            href={link}
                            color="purple"
                            size="m2"
                          >
                            {button}
                          </Button>
                        )}
                      </div>
                    ))}
                  </Grid>
                )}
              </div>
            </White>
          </div>
        )}
        
        {(establishingVRO || operatingMinistrys) && (
          <div className="establishing-operating__section">
            <Black noLayout>
              {establishingVRO && (
                <div className={styles['establishing-vro__section']}>
                  {establishingVRO?.pic && (
                    <div className={styles['establishing-vro__pic']}>
                      <PicBlock
                        image={establishingVRO.pic}
                        gradient="purple"
                        type="romb-right"
                      />
                    </div>
                  )}
                  <div className="layout">
                    <div className={styles['establishing-vro__content']}>
                      {establishingVRO?.title && (
                        <div className="establishing__title">
                          <Heading decoration>
                            {establishingVRO.title}
                          </Heading>
                        </div>
                      )}
                      {establishingVRO?.intro && (
                        <div className="establishing__text">
                          <Text
                            maxWidth="60"
                            size="25-35"
                          >
                            {establishingVRO.intro}
                          </Text>
                        </div>
                      )}
                      {establishingVRO?.cols && (
                        <Grid cols="2" gap="md3" customClass={styles['establishing-vro__grid']}>
                          {establishingVRO.cols.map(({ icon, title, list }, index) => (
                            <div key={index} className={styles['establishing-vro__col']}>
                              <IconBlock
                                circleColor="grey-500-02"
                                circleSize="xl"
                                icon={icon}
                                iconSize="md2"
                                margin="mb-50"
                              >
                                <Heading
                                  Tag="h3"
                                  color="orange"
                                  spacing="0"
                                  view="h4"
                                >
                                  {title}
                                </Heading>
                              </IconBlock>
                              <List 
                                items={list}
                                gap="md2"
                              />
                            </div>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </div>
                  <CircleDecoration 
                    color="rgba(96, 119, 218, 0.3)"
                    height="82vh"
                    position={{
                      top: '12vh',
                      left: '-19vw'
                    }}
                    rotate="21"
                    width="26vw"
                  />
                  <CircleDecoration 
                    color="rgba(96, 119, 218, 0.3)"
                    height="90vh"
                    position={{
                      top: '52vh',
                      right: '40px'
                    }}
                    width="26vw"
                  />
                </div>
              )}

              {operatingMinistrys && (
                <div className={styles['operating-government__section']}>
                  <div className="layout">
                    <div className={styles['operating-ministrys__inner']}>
                      {operatingMinistrys?.title && (
                        <div className="operation-ministrys__title">
                          <Heading decoration>
                            {operatingMinistrys.title}
                          </Heading>
                        </div>
                      )}
                      {operatingMinistrys?.intro && (
                        <div className="operation-ministrys__text">
                          <Text
                            maxWidth="50"
                            size="25-35"
                          >
                            {operatingMinistrys.intro}
                          </Text>
                        </div>
                      )}
                      {operatingMinistrys?.cols && (
                        <Grid cols="2" gap="md3" customClass={styles['operating-ministrys__grid']}>
                          {operatingMinistrys.cols.map(({ icon, title, list }, index) => (
                            <div key={index} className={styles['operating-ministrys__col']}>
                              <IconBlock
                                circleColor="grey-500-02"
                                circleSize="xl"
                                icon={icon}
                                iconSize="md2"
                                margin="mb-50"
                              >
                                <Heading
                                  Tag="h3"
                                  color="orange"
                                  spacing="0"
                                  view="h4"
                                >
                                  {title}
                                </Heading>
                              </IconBlock>
                              <List 
                                items={list}
                                gap="md2"
                              />
                            </div>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </div>
                  <CircleDecoration 
                    height="67vh"
                    position={{
                      bottom: '10vh',
                      left: '5vw'
                    }}
                    rotate="50"
                    width="31vw"
                  />
                  <CircleDecoration 
                    height="67vh"
                    position={{
                      bottom: '1.5vh',
                      right: '14vw'
                    }}
                    rotate="50"
                    width="31vw"
                  />
                </div>
              )}
            </Black>
          </div>
        )}
      </div>

      {operatingGovernment && (
        <div className="operating-government__section">
          <Black>
            {operatingGovernment?.title && (
              <Heading 
                align="center"
                decoration
                maxWidth="70"
              >
                {operatingGovernment.title}
              </Heading>
            )}
            {operatingGovernment?.intro && (
              <Text
                align="center"
                size="25-30"
                maxWidth="70"
              >
                {operatingGovernment.intro}
              </Text>
            )}
          </Black>
        </div>
      )}

      {(approach || impact) && (
        <White
          overflow="hidden"
          noLayout
          customClass="border-bottom"
        >
          {approach && ( 
            <div className={styles['list-decor__section']}>
              <div className="layout">
                {approach?.title && (
                  <HeaderDecorated 
                    color="black"
                    title={approach.title}
                    icon={approach.icon}
                    circleColor="white"
                  />
                )}
                {approach?.list && (
                  <List 
                    gap="lg"
                    color="black"
                    items={approach.list}
                    type="line"
                    fontSize="20-25"
                  />
                )}
              </div>
            </div>
          )}
          
          {impact && (
            <div className={styles['list-decor__section']}>
              <div className="layout">
                {impact?.title && (
                  <HeaderDecorated 
                    color="black"
                    title={impact.title}
                    icon={impact.icon}
                    circleColor="white"
                  />
                )}
                {impact?.list && (
                  <List 
                    items={impact.list}
                    color="black"
                    gap="lg"
                    type="line"
                    fontSize="20-25"
                  />
                )}
              </div>
            </div>
          )}
        </White>
      )}
    </div>
  )
}

export default OrganizationalExcellence