import React, { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading, Text } from '..';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

const CarouselGlobalInsights = ({ slides, pics }) => {
  const [sortedPics, setSortedPics] = useState(pics);

  return (
    <div className={`global-insights__carousel ${styles['global-insights__carousel']}`}>
      <div className={styles['global-insights__carousel-main']}>
        <Swiper
          modules={[Navigation]}
          navigation
          loop
          onSlideChange={(swiper) => {
            if(swiper.previousIndex !== 0 && swiper.previousIndex <= sortedPics.length){
              const newSortedPics = sortedPics;
              if(swiper.activeIndex > swiper.previousIndex){
                newSortedPics.push(newSortedPics.shift());
              } else {
                newSortedPics.unshift(newSortedPics.pop());
              }
              setSortedPics([...newSortedPics]);
            }
          }}
        >
          {slides.map(({title, text}, i) => (
            <SwiperSlide className={styles['global-insights__slide']} key={i}>
              <Heading
                Tag="h3"
                view="h4"
                color="black"
                decoration={{
                  color: 'purple-100'
                }}
              >
                {title}
              </Heading>
              {text && (
                <div className={styles['global-insights__text']}>
                  <Text 
                    color="black"
                    size="22-25"
                  >
                    {text}
                  </Text>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles['global-insights__carousel-thumbs']}>
        {sortedPics && sortedPics.map((pic, i) => (
          <div 
            key={i}
            className={`${styles['global-insights__carousel-thumbs-item']} ${i === 0 && styles.active}`}
            style={{
              backgroundImage: `url(${pic})`
            }}
          />
        ))}
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="angle-right-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.002268, 0.002198)">
              <path d="M435.184 212.795L242.432 6.76341C238.65 2.72116 233.363 0.427002 227.827 0.427002H33.1273C15.3311 0.427002 6.39786 21.9247 18.9532 34.5371L196.391 212.78C204.344 220.769 204.126 233.748 195.91 241.467L6.36238 419.526C-6.84505 431.933 1.93498 454.103 20.0559 454.103H199.538C204.626 454.103 209.523 452.164 213.231 448.68L434.272 241.036C442.311 233.484 442.719 220.85 435.184 212.795Z" fill="black"/>
            </clipPath>
            <clipPath id="romb-clip-path" clipPathUnits="objectBoundingBox" transform="scale(0.001285, 0.002193)">
              <path d="M6.54927 213.873L213.637 6.78461C217.388 3.03389 222.475 0.92675 227.779 0.926746L550.182 0.926514C555.486 0.92651 560.573 3.03363 564.324 6.78436L771.412 213.872C779.223 221.683 779.223 234.346 771.412 242.157L564.324 449.245C560.573 452.996 555.486 455.103 550.182 455.103L227.779 455.103C222.475 455.103 217.388 452.996 213.637 449.245L6.54927 242.157C-1.26121 234.346 -1.26122 221.683 6.54927 213.873Z" fill="#FFC074"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}

export default CarouselGlobalInsights