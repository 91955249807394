import styles from './index.module.scss';

const CustomerExperienceContent = ({ left, right, children }) => {
  return (
    <div className={styles['customer-experience-content__block']}>
      <div className={styles['customer-experience-content__left']}>
        <i></i>
        {left}
      </div>
      <div className={styles['customer-experience-content__middle']}>
        {children}
      </div>
      <div className={styles['customer-experience-content__right']}>
        <i></i>
        {right}
      </div>
    </div>
  )
}

export default CustomerExperienceContent