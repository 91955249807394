import HeroBg from '../assets/img/bg-associate-partner.png';
import LogoText from '../assets/svg/logo-text.svg';
import PicWorkWithYou from '../assets/img/pic-who-you-will-work-with.png';

// DATA: CAREERS DETAIL

// All data
export const careers = [
  {
    id: 'associate-partner-or-partner',
    hero: {
      bg: HeroBg,
      logo: LogoText,
      title: 'Associate partner or partner',
    },
    overview: {
      title: 'Job overview',
      text: <>
        <p>You will lead multiple teams of consultants and clients and will have ownership of the end-to-end delivery of projects, ensuring they meet business objectives.</p>
      </>
    },
    quals: {
      title: 'Qualifications:',
      items: [
        'Undergraduate and graduate degrees in business, economics, engineering, or related fields with an outstanding academic record',
        'Ten-plus years of business or public-sector experience at a senior level, with a clear record of achievement and a proven drive for personal growth; a blend of industry and consulting experience is preferred',
        'International experience is a plus',
        'Demonstrated track record in one or more of the following key service offerings: organizational development (including organization design and setup and talent management), transformational delivery (driving large transformational programs), and productivity and performance improvement',
        'Ability to successfully manage large and complex departments or functions with full P&L responsibility',
        'Experience developing business unit strategy and leading large-scale change programs, preferably while establishing strong relationships with senior clients',
        'Superior problem-solving and relationship-building skills, including a demonstrated high level of commitment to client service',
        'Ability to work collaboratively in a team environment and with people across all levels of an organization',
        'Proven ability to lead, coach, and develop teams',
        'Ability to communicate complex ideas effectively in English and Arabic',
        'Willingness to travel, predominantly within the Kingdom'
      ]
    },
    workWith: {
      title: 'Who you’ll work with',
      content: <>
        <p><strong>You’ll be based in one of our two office locations—Riyadh or Jeddah—and will be part of Elixir.</strong></p>
        <p>Founded in 2005, Elixir is a McKinsey-owned consulting firm in Saudi Arabia. Our core mission is to help organizations across the public and private sectors implement transformations and build organizational and employee capabilities in the process. As the Kingdom continues to work toward delivering Vision 2030, we are committed to combining our implementation capabilities with McKinsey’s global expertise to help make this bold ambition a reality.</p>
      </>,
      pic: PicWorkWithYou
    },
    youWillDo: {
      title: 'What you’ll do',
      items: [
        'You will lead teams of consultants and clients and will have ownership of the end-to-end delivery of multiple large projects, ensuring they meet business objectives.',
        'In this role, you’ll manage client relationships over the course of multiple projects to ensure consistently high levels of client satisfaction.',
        'You’ll manage multiple project teams—monitoring and evaluating performance and actively developing team members by providing regular feedback and coaching.',
        'You will strive to build senior client capabilities through continuous, on-the-job coaching to ensure sustained impact beyond the end of the project.',
        'You’ll act as a subject matter expert, combining broad experience with strong problem-solving abilities to derive business insights and create solid recommendations for clients. You’ll strengthen the firm’s knowledge base by codifying learnings from projects and coaching less-tenured colleagues.',
        'You’ll contribute to Elixir’s growth by preparing business proposals for potential clients and by supporting organizational activities such as recruiting, performance evaluations, and training.'
      ],
      button: {
        title: 'Apply now'
      }
    }
  },
  {
    id: 'senior-manager',
    hero: {
      bg: HeroBg,
      logo: LogoText,
      title: 'Senior manager',
    },
    overview: {
      title: 'Job overview',
      text: <>
        <p>You will be responsible for the successful delivery of individual project workstreams by working closely with clients and small teams.</p>
      </>
    },
    quals: {
      title: 'Qualifications:',
      items: [
        'Undergraduate degree in business, economics, engineering, or a related field with an outstanding academic record; MBA or other advanced degree is a plus',
        'Eight to ten years of business or public-sector experience, with a clear record of achievement and a proven drive for personal growth',
        'Demonstrated track record in one or more of the following key service offerings: organizational development (including organization design and setup and talent management), transformational delivery (driving large transformational programs), and productivity and performance improvement',
        'Ability to successfully manage complex programs, multiple projects, or medium-size to large departments or business units with P&L responsibility',
        'Blend of industry and consulting experience is ideal',
        'Superior problem-solving and relationship-building skills, including a demonstrated high level of commitment to client service',
        'Ability to work collaboratively in a team environment and with people across all levels of an organization',
        'Proven ability to lead, coach, and develop teams',
        'Ability to communicate complex ideas effectively in English and Arabic',
        'Willingness to travel, predominantly within the Kingdom',
      ]
    },
    workWith: {
      title: 'Who you’ll work with',
      content: <>
        <p><strong>You’ll be based in one of our two office locations—Riyadh or Jeddah—and will be part of Elixir.</strong></p>
        <p>Founded in 2005, Elixir is a McKinsey-owned consulting firm in Saudi Arabia. Our core mission is to help organizations across the public and private sectors implement transformations and build organizational and employee capabilities in the process. As the Kingdom continues to work toward delivering Vision 2030, we are committed to combining our implementation capabilities with McKinsey’s global expertise to help make this bold ambition a reality.</p>
      </>,
      pic: PicWorkWithYou
    },
    youWillDo: {
      title: 'What you’ll do',
      items: [
        'You’ll take full ownership of one large-scale project or three to four medium-scale projects concurrently, structuring the scope and managing the project plan, resources, and budget.',
        'In this role, you’ll actively manage client relationships to ensure a high level of client satisfaction. You’ll lead the project team on the ground—monitoring and evaluating team performance and actively developing team members by providing regular feedback and coaching.',
        'You will strive to build client capabilities through continuous, on-the-job coaching to ensure sustained impact beyond the end of the project.',
        'You’ll act as a subject matter expert in your chosen service line, combining broad experience with strong problem-solving abilities to derive business insights and create solid recommendations for clients.',
        'You’ll strengthen the firm’s knowledge base by codifying learnings from projects and coaching less-tenured colleagues.',
        'You’ll contribute to Elixir’s growth by preparing business proposals for potential clients and by supporting organizational activities such as recruiting, performance evaluations, and training.',
      ],
      button: {
        title: 'Apply now'
      }
    }
  },
  {
    id: 'manager',
    hero: {
      bg: HeroBg,
      logo: LogoText,
      title: 'Manager',
    },
    overview: {
      title: 'Job overview',
      text: <>
        <p>You will be responsible for the successful delivery of individual project workstreams by working closely with clients and small teams.</p>
      </>
    },
    quals: {
      title: 'Qualifications:',
      items: [
        'Undergraduate degree in business, economics, engineering, or a related field with an outstanding academic record',
        'Four to seven years of business or public-sector experience, with a clear record of achievements and a proven drive for personal growth; international experience is a plus',
        'Demonstrated experience in one or more of the following key service offerings is a plus: organizational development (including organization design and setup and talent management), transformational delivery (implementing large transformational programs), and productivity and performance improvement',
        'Ability to successfully manage and deliver small to medium-size projects and/or manage small units with budget responsibility',
        'Superior problem-solving and relationship-building skills, including a demonstrated high level of commitment to client service',
        'Ability to work collaboratively in a team environment and with people across all levels of an organization',
        'Proven ability to lead, coach, and develop teams',
        'Ability to communicate complex ideas effectively in English and Arabic',
        'Willingness to travel, predominantly within the Kingdom',
      ]
    },
    workWith: {
      title: 'Who you’ll work with',
      content: <>
        <p><strong>You’ll be based in one of our two office locations—Riyadh or Jeddah—and will be part of Elixir.</strong></p>
        <p>Founded in 2005, Elixir is a McKinsey-owned consulting firm in Saudi Arabia. Our core mission is to help organizations across the public and private sectors implement transformations and build organizational and employee capabilities in the process. As the Kingdom continues to work toward delivering Vision 2030, we are committed to combining our implementation capabilities with McKinsey’s global expertise to help make this bold ambition a reality.</p>
      </>,
      pic: PicWorkWithYou
    },
    youWillDo: {
      title: 'What you’ll do',
      items: [
        'You’ll take ownership of a small project or independent workstream within a larger project, developing functionally coordinated plans with specific initiatives and timelines.',
        'In this role you’ll actively manage the client relationship during the project, supervising or working closely with client teams to achieve project goals. You’ll be part of the project team on the ground, monitoring and evaluating performance and efficiency and coaching less-tenured team members.',
        'You will strive to build client capabilities through continuous, on-the-job coaching to ensure sustained impact beyond the end of the project.',
        'You’ll specialize in one or more service lines and will become a subject matter expert in your chosen sector or function.',
        'You’ll strengthen the firm’s knowledge base by codifying learnings from projects and sharing your knowledge with your teams.',
        'You’ll chart your own development journey—attending formal training programs, working with the partners you find most inspiring, and focusing on the business areas you are most passionate about.',
      ],
      button: {
        title: 'Apply now'
      }
    }
  },
  {
    id: 'associate-consultant',
    hero: {
      bg: HeroBg,
      logo: LogoText,
      title: 'Associate consultant',
    },
    overview: {
      title: 'Job overview',
      text: <>
        <p>You will work in small teams with colleagues and clients to build the strategies, capabilities, systems, and processes needed to deliver bottom-line results and ensure those results will be sustained.</p>
      </>
    },
    quals: {
      title: 'Qualifications:',
      items: [
        'Undergraduate degree in business, economics, engineering, or a related field with an outstanding academic record',
        'Three to five years of business or public-sector experience, with a clear record of achievements and a proven drive for personal growth; international experience is a plus',
        'Ability to successfully manage and deliver small to medium-size projects',
        'Superior problem-solving and relationship-building skills, including a demonstrated high level of commitment to client service',
        'Ability to work collaboratively in a team environment and with people across all levels of an organization',
        'Expertise in Microsoft Office, including Excel and PowerPoint',
        'Ability to communicate complex ideas effectively in English and Arabic',
        'Willingness to travel, predominantly within the Kingdom',
      ]
    },
    workWith: {
      title: 'Who you’ll work with',
      content: <>
        <p><strong>You’ll be based in one of our two office locations—Riyadh or Jeddah—and will be part of Elixir.</strong></p>
        <p>Founded in 2005, Elixir is a McKinsey-owned consulting firm in Saudi Arabia. Our core mission is to help organizations across the public and private sectors implement transformations and build organizational and employee capabilities in the process. As the Kingdom continues to work toward delivering Vision 2030, we are committed to combining our implementation capabilities with McKinsey’s global expertise to help make this bold ambition a reality.</p>
      </>,
      pic: PicWorkWithYou
    },
    youWillDo: {
      title: 'What you’ll do',
      items: [
        'You will identify information sources, gather and interpret data, execute analysis, and present findings to colleagues and client stakeholders.',
        'In this role, you will monitor the project status and report on it on a regular basis. You will focus on building strong relationships with clients and colleagues, which will contribute to building your wider people skills.',
        'You’ll develop practical recommendations and contribute to team discussions on the implications of the analysis. Through this, you will identify implementation challenges.',
        'You’ll strengthen the firm’s knowledge base by codifying learnings from projects and sharing knowledge with your teams.',
        'You’ll chart your own development journey—attending formal training programs, working with the partners you find most inspiring, and focusing on the business areas you are most passionate about.',
      ],
      button: {
        title: 'Apply now'
      }
    }
  },
  {
    id: 'analyst',
    hero: {
      bg: HeroBg,
      logo: LogoText,
      title: 'Analyst',
    },
    overview: {
      title: 'Job overview',
      text: <>
        <p>You will work in teams to help clients build capabilities in delivery and execution, both during an active project and continuing after our consultants have shared their recommendations.</p>
      </>
    },
    quals: {
      title: 'Qualifications:',
      items: [
        'Undergraduate degree in business, economics, engineering, or a related field with an outstanding academic record',
        'Two years or less of business or public-sector experience, with a clear record of achievements and a proven drive for personal growth; international experience is a plus',
        'Ability to successfully manage and deliver small to medium-size projects',
        'Superior problem-solving and relationship-building skills, including a demonstrated high level of commitment to client service',
        'Ability to work collaboratively in a team environment and with people across all levels of an organization',
        'Expertise in Microsoft Office, including Excel and PowerPoint',
        'Ability to communicate complex ideas effectively in English and Arabic',
        'Willingness to travel, predominantly within the Kingdom',
      ]
    },
    workWith: {
      title: 'Who you’ll work with',
      content: <>
        <p><strong>You’ll be based in one of our two office locations—Riyadh or Jeddah—and will be part of Elixir.</strong></p>
        <p>Founded in 2005, Elixir is a McKinsey-owned consulting firm in Saudi Arabia. Our core mission is to help organizations across the public and private sectors implement transformations and build organizational and employee capabilities in the process. As the Kingdom continues to work toward delivering Vision 2030, we are committed to combining our implementation capabilities with McKinsey’s global expertise to help make this bold ambition a reality.</p>
      </>,
      pic: PicWorkWithYou
    },
    youWillDo: {
      title: 'What you’ll do',
      items: [
        'You will manage diverse projects, from documentation to design and execution.',
        'In this role, you will track metrics and handle complex analysis. You will create actionable road maps by refining workflows and processes in alignment with global directives.',
        'You’ll strengthen the firm’s knowledge base by codifying learnings from projects and sharing knowledge with your teams. ',
        'And you’ll chart your own development journey—attending formal training programs, working with the partners you find most inspiring, and focusing on the business areas you are most passionate about.',
      ],
      button: {
        title: 'Apply now'
      }
    }
  }
];